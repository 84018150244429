import React from "react";
import {Col, FormGroup, Label} from "reactstrap";
import FilterSelect from "./FilterSelect";
import FilterSwitch from "./FilterSwitch";
import FilterRange from "./FilterRange";
import FilterDateRange from "./FilterDateRange";
import FilterInput from "./FilterInput";
import FilterTeaxtArea from "./FilterTeaxtArea";
import FilterGroup from "./FilterGroup";
import FilterLabel from "./FilterLabel";

const Components = {
    Input: FilterInput,
    Select: FilterSelect,
    Group: FilterGroup,
    Switch: FilterSwitch,
    Range: FilterRange,
    DateRange: FilterDateRange,
    Label: FilterLabel,
    TextArea : FilterTeaxtArea
};


export default block => {
    if(block.hidden) {
        return  <></>
    }
    if (typeof Components[block.component_name] !== "undefined") {
        return <FormGroup row key={"key" + block.id}>
            <Col xs="12">
                <Label size="lg" style={{fontSize:14, fontWeight:500}} htmlFor={block.id}>{block.label}</Label>
            </Col>
            <Col xs="12">
                {React.createElement(Components[block.component_name], block)}
            </Col>
        </FormGroup>
    }
    return React.createElement(
        () => <div>The component {block.component_name} not registred yet.</div>,
        { key: block.id }
    );
};
