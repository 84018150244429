import { useContext } from "react";
import {CategoryContext, ITranscriptInfo, TranscriptSearch } from "../context/AppStore";
import { DictionaryItems, DictionaryItem } from "../types";
import { getCategoryColors } from "../utils/Utility";
const useFilterContext: any = () => {
  const [state, setState] = useContext(CategoryContext);
  const setTopics:any = (topics: DictionaryItems<ITranscriptInfo[]>) => {
    setState((prvState: any) => ({ ...prvState, topics: topics}));
  };
  const setCategories:any = (categories: DictionaryItems<ITranscriptInfo>) => {
    setState((prvState: any) => ({ ...prvState, colors:getCategoryColors(categories),categories: categories}));
  };
  const setColors:any = (colors: DictionaryItem<string>) => {
    setState((prvState: any) => ({ ...prvState, colors: colors}));
  };
  const setByCategories:any = (select: boolean) => {
    setState((prvState: any) => ({ ...prvState, byCategories: select}));
  };
  const setAskOpen:any = (select: boolean) => {
    setState((prvState: any) => ({ ...prvState, askOpen: select}));
  };

  const setDealPageAskOpen:any = (select: boolean) => {
    setState((prvState: any) => ({ ...prvState, dealPageAskOpen: select}));
  };
  const filterByComments:any = (select: boolean) => {
    setState((prvState: any) => ({ ...prvState, byComments: select}));
  };
  const setStartTimeMap:any = (data: DictionaryItems<ITranscriptInfo[]>) => {
    setState((prvState: any) => ({ ...prvState, startTimeMap: data}));
  }
  const setSelectedTopic:any = (selectedTopic: string) => {
    setState((prvState: any) => ({ ...prvState, selectedTopic, selectedSubTopic: '' }));
  }
  const setSelectedSubTopic:any = (selectedSubTopic: string) => {
    setState((prvState: any) => ({ ...prvState, selectedSubTopic , selectedTopic:''}));
  };
  const setTranscriptFilter:any = (transcriptFilter: TranscriptSearch) => {
    setState((prvState: any) => ({ ...prvState, transcriptFilter: transcriptFilter }));
  }
  const setPanelType:any = (select: string) => {
    setState((prvState: any) => ({ ...prvState, panelType: select}));
  };
  return {
    byCategories: state.byCategories,
    askOpen: state.askOpen,
    dealPageAskOpen: state.dealPageAskOpen,
    byComments: state.byComments,
    colors: state.colors,
    categories : state.categories,
    setCategories,
    setColors,
    setTopics,
    topics: state.topics,
    panelType: state.panelType,
    setPanelType,
    setByCategories,
    setAskOpen,
    setDealPageAskOpen,
    filterByComments,
    setStartTimeMap,
    startTimeMap: state.startTimeMap,
    selectedTopic: state.selectedTopic,
    setSelectedTopic,
    selectedSubTopic: state.selectedSubTopic,
    setSelectedSubTopic,
    setTranscriptFilter,
    transcriptFilter : state.transcriptFilter
  };
};

export default useFilterContext;
