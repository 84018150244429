import React, {useState} from "react";
import {Button, Input, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {useAppContext} from "../../hooks";
import {postJsonAxiosService} from "../../services/callAxios";
import {toastService} from "../../services/toast.service";

const OpenAIModal = (props) => {
    const {user} = useAppContext();
    const {provider, toggle} = props;
    const [apikey, setApikey] = useState("");
    const [errorApikey, setErrorApikey] = useState(false);
    const [orgurl, setOrgUrl] = useState("");
    const [errorOrgUrl, setErrorOrgUrl] = useState(false);

    const handleSubmit = async (values) => {
        if (!apikey) {
            setErrorApikey(true);
        }
        let provider = {
            accessToken: apikey,
            provider: "openAI",
            service: "others",
        }
        try {
            let response = await postJsonAxiosService("openAI/webhook/validate", {provider: provider});
            if (response) {

                if (response.data === "Incorrect API key") {
                    toastService.error("Invalid Api Key");
                } else {
                    if (!user.providers || user.providers.length == 0) {
                        user.providers = [];
                    }
                    // user.providers.push(provider);
                    toastService.info("OpenAI Integrated");
                    props.toggle();
                    // window.location.reload();
                }
            }
        } catch (e) {
            toastService.error("Error while updating integration");
        }
    };

    return (
        <div style={{padding: 20}}>
            <ModalHeader toggle={props.toggle}
                         style={{fontSize: 24, fontWeight: 600, border: "none"}}>
                <span>Add OpenAI Integration</span>
            </ModalHeader>
            <ModalBody>
                <div>
                    <div className="snippet-desc" style={{fontSize: 14, color: "#202124"}}>
                        <label>Api Key</label>
                        <Input style={{fontSize: 14, color: "#202124"}}
                               placeholder="Enter Api Key"
                               value={apikey}
                               onChange={(e) => {
                                   setApikey(e.target.value);
                                   setErrorApikey(false);
                               }}
                        />
                        {errorApikey ? (
                            <div className="snippet-desc-error" style={{color: "red"}}>
                                Please enter Api Key
                            </div>
                        ) : null}
                    </div>
                    <div>
                    </div>
                </div>

            </ModalBody>
            <ModalFooter style={{border: "none", padding: "0px 0px 10px 30px"}}>
                <Button outline color="primary" onClick={props.toggle}>
                    Cancel
                </Button>{" "}
                <Button color="primary" onClick={handleSubmit}>
                    Authorize
                </Button>{" "}
            </ModalFooter>
        </div>
    )
};

export default OpenAIModal;
