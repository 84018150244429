import React, { useEffect, useState } from 'react';
import Nouislider from 'nouislider-react';
import 'nouislider/distribute/nouislider.css';
import { Label } from 'reactstrap';

export interface FilterSliderProps {
    min: number;
    max: number;
    rangeLabel: string;
    onChangeValue: (id: string, startValue: number, endValue: number) => void;
    id: string;
    startValue: number;
    endValue: number;
}

const FilterSlider: React.FC<FilterSliderProps> = (props) => {
    const { id, min, max, rangeLabel, startValue, endValue } = props;
    const [value, setValue] = useState<[number, number]>([startValue, endValue]);

    useEffect(() => {
        setValue([startValue, endValue]);
    }, [startValue, endValue]);

    const handleSliderChange = (values: any[], handle: number, unencoded: number[]) => {
        const newStartValue = Math.round(unencoded[0]); // Round to nearest integer
        const newEndValue = Math.round(unencoded[1]);   // Round to nearest integer

        props.onChangeValue(id, newStartValue, newEndValue);
        setValue([newStartValue, newEndValue]);
    };

    return (
        <>
            <Label className="range_label">{rangeLabel}</Label>
            <Nouislider
                range={{ min, max }}
                start={value}
                connect={true}
                behaviour="drag"
                onChange={handleSliderChange}
                tooltips={[true, true]}
                format={{
                    to: function (value) {
                        return Math.round(value).toString();
                    },
                    from: function (value) {
                        return Math.round(value).toString();
                    },
                }}
            />
        </>
    );
};

export default FilterSlider;
