import React from "react";
import {CFormSwitch} from "@coreui/react";
import {ISwitchFilterItemProps} from "./FilterPanel";


export const defaultSwitchProps: ISwitchFilterItemProps = { id: "", label: "", component_name: "FilterSwitch", text: "true", setChosen: (val) => { } };
const FilterSwitch: React.FC<ISwitchFilterItemProps> = (props: ISwitchFilterItemProps) => {
  return (
    <CFormSwitch
      id={props.id}
      checked={Boolean(props.text)}
      className={"mx-1"}
      label={true}
      variant={"pill"}
      color={"primary"}
      size={'lg'}
      onChange={(e: any) => {
        props.setChosen(e.target.checked);
        props.onChangeValue && props.onChangeValue(props.id, e.target.checked, e);
        return true;
      }}
    />
  );
}

FilterSwitch.defaultProps = defaultSwitchProps;
export default FilterSwitch;