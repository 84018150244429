import {useContext} from "react";
import {
    IDeal,
    IMeeting,
    IMeetingCount,
    IMeetings,
    IPlaylist,
    ITranscript,
    ITranscriptInfo,
    Seeking,
    ObjectContext,
    SelectedTranscript,
} from "./../context/AppStore";
import {getColors} from "../utils/Utility";
import {DictionaryItem} from "../types";

export interface ObjectHook {
    meetings: IMeeting[];
    searchMeetings: IMeetings[];
    meetingCount: IMeetingCount[];
    transcripts: ITranscriptInfo[];
    searchedTranscripts: any[];
    showSearch: boolean;
    loading: boolean;
    selectedMeeting: IMeeting;
    redirectMeetingId: String | undefined;
    meetingSortBy: string;
    dealSortBy: string;
    playlistSortBy: string;
    deals: IDeal[];
    dealId: string;
    autoScroll: boolean;
    notesData: any[];
    setAutoScroll: (autoScroll: boolean) => void;
    setDeals: (deals: IDeal[]) => void;
    setDealId: (dealId: string) => void;
    selectedDeal: IDeal;
    setSelectedDeal: (selectedDeal: IDeal) => void;
    playlist: IPlaylist[];
    setPlayList: (playlist: IPlaylist[]) => void;
    commentedData: ITranscriptInfo[],
    setCommentedData: (commentedData: ITranscriptInfo[]) => void;
    currentModule: String;
    currentLeftPanel: String;
    currentTab: String;
    selectedTranscript: SelectedTranscript;
    filterByTranscriptUser: string;
    colors: DictionaryItem<string>;
    timeStampsArray: any[];
    reset: () => void;
    resetMeetings: (meetings: IMeeting[]) => void;
    resetPlaylist: (playlist: IPlaylist[]) => void;
    resetDeals: (deals: IDeal[]) => void;
    setMeetings: (meetings: IMeeting[]) => void;
    setSearchMeetings: (searchMeetings: IMeetings[]) => void;
    setSearchedMeetings: (searchedMeetings: any[]) => void;
    setShowNavSearch: (showNavSearch: boolean) => void;
    setMeeting: (meeting: IMeeting) => void;
    setTranscripts: (transcripts: ITranscriptInfo[]) => void;
    setSearchedTranscripts: (searchedTranscripts: any[]) => void;
    setMeetingCount: (meetingCount: any[]) => void;
    setShowSearch: (showSearch: boolean) => void;
    setLoading: (loading: boolean) => void;
    setSelectedMeeting: (selectedMeetingId: string | null) => void;
    setSelectedTranscript: (selectedTranscript: SelectedTranscript) => void;
    setFilterByTranscriptUser: (filterByTranscriptUser: String) => void;
    setCurrentModule: (module: String) => void;
    setCurrentTab: (tabs: String) => void;
    setMeetingSortBy: (sortBy: string) => void;
    setDealSortBy: (sortBy: string) => void;
    setPlaylistSortBy: (sortBy: string) => void;
    setCurrentLeftPanel: (panel: String) => void;
    updateMeeting: (meetings: IMeeting) => void;
    isPlaying: boolean;
    setIsPlaying: (isPlaying: boolean) => void;
    isPaused: boolean;
    setIsPaused: (isPaused: boolean) => void;
    seek: Number;
    setSeek: (seek: Number) => void;
    seeking: Seeking;
    setSeeking: (seeking: Seeking) => void;
    changeMeeting: () => void;
    transcriptsWithComment: boolean;
    showTranscriptsWithComment: (transcriptsWithComment: boolean) => void;
    setRedirectMeetingId: (id: String) => void;
    updateMeetingData: (newData: any) => void;
    updateTranscriptSpeaker: (changedNames: any) => void;
    setMeetingsAndSearchMeetings: (meetings: IMeeting[], searchMeetings: IMeetings[]) => void;
    setNotesData: (newData: any) => void;
    setTimeStampsArray: (newData: any) => void;
    reSetTimeStampsArray: (newData?: any) => void;
    reloadTransctipt: boolean;
    setReloadTransctipt: (newData: boolean) => void;
    totalMeetingCount: number;
    setTotalMeetingCount: (totalMeetingCount: any) => void;
}

const useObjectHook = (): ObjectHook => {
    const [state, setState] = useContext(ObjectContext);

    const reset = () => {
        setState((prvState: any) => ({
            ...prvState,
            filterByTranscriptUser: "",
            selectedTranscript: undefined,
            transcripts: [],
            searchedTranscripts: [],
            loading: false,
            selectedMeeting: undefined,
            timeStampsArray: []
        }));
    };

    const updateTranscriptSpeaker = (changedNames: any) => {
        if (changedNames) {
            var transcripts: ITranscriptInfo[] = [...state.transcripts];
            changedNames.map(value => {
                transcripts.map((transcript, index) => {
                    if (transcript.name === value.oldName) {
                        var jsonObjString = JSON.stringify(transcript);
                        var jsonObj = JSON.parse(jsonObjString);
                        jsonObj.name = value.newName;
                        transcripts[index] = jsonObj;
                    }
                });
            });
            var jsonString = JSON.stringify(transcripts);
            var updatedAtrry = JSON.parse(jsonString);
            let talkTrack = [...state.selectedMeeting?.analytics?.talktrack]
            changedNames.map((e) => {
                talkTrack.map((value, index) => {
                    if (value.userid === e.oldName) {
                        var jsonObjString = JSON.stringify(value);
                        var jsonObj = JSON.parse(jsonObjString);
                        jsonObj.userid = e.newName;
                        talkTrack[index] = jsonObj;
                    }
                });
            });
            let newSelectedMeeting = {
                ...state.selectedMeeting,
                analytics: {
                    ...state.selectedMeeting.analytics,
                    talktrack: talkTrack,
                }
            }
            setState((prvState: any) => ({
                ...prvState,
                transcripts: updatedAtrry,
                selectedMeeting: newSelectedMeeting
                //autoScroll: false,
            }));
        }
    }
    const updateMeetingData = (meeting: IMeeting) => {
        if (meeting) {
            const meetings: IMeeting[] = [...state.meetings];
            const searchMeetings: IMeetings[] = [...state.searchMeetings];

            let prvMeetingIndex = meetings.findIndex(
                (meetingItem: IMeeting) =>
                    meetingItem.id === meeting.id
            );
            let prvSearchMeetingIndex = searchMeetings.findIndex(
                (searchMeetingItem: IMeetings) =>
                    searchMeetingItem.meeting && searchMeetingItem.meeting.id === meeting.id
            );

            if (prvMeetingIndex > -1 && prvSearchMeetingIndex > -1 ) {

                meetings[prvMeetingIndex] = meeting;
                searchMeetings[prvSearchMeetingIndex] = {...searchMeetings[prvSearchMeetingIndex], meeting: meeting};

                setState((prvState: any) => ({
                    ...prvState,
                    meetings: meetings,
                    searchMeetings: searchMeetings,
                    isPlaying: false,
                    autoScroll: false,
                    selectedMeeting: meeting
                }));
            }
        }
    }

    const setSelectedMeeting: any = (selectedMeetingId: string) => {
        if (state.selectedMeeting?.id !== selectedMeetingId) {
            const meetings: IMeeting[] = state.meetings;
            let newMeeting: IMeeting;
            if (meetings !== null) {
                const meeting: IMeeting[] = meetings.filter(
                    (x) => x.id === selectedMeetingId
                );
                if (meeting.length > 0) {
                    newMeeting = meeting[0];
                }
            }
            setState((prvState: any) => ({
                ...prvState,
                selectedMeeting1: "newMeeting",
                filterByTranscriptUser: "",
                selectedTranscript: undefined,
                transcripts: [],
                selectedMeeting: newMeeting,
                autoScroll: false,
                isPlaying: false,
            }));
        }
    };
    const setMeeting: any = (selectedMeeting: IMeeting) => {
        setState((prvState: any) => ({
            ...prvState,
            filterByTranscriptUser: "",
            redirectMeetingId: undefined,
            selectedTranscript: undefined,
            transcripts: [],
            selectedMeeting: selectedMeeting,

        }));
    };
    const setSelectedTranscript: any = (
        selectedTranscript: SelectedTranscript
    ) => {
        setState((prvState: any) => ({
            ...prvState,
            selectedTranscript: selectedTranscript,
        }));
    };

    const resetMeetings: any = (meetings: IMeeting[]) => {
        if (meetings !== state.meetings) {
            setState((prvState: any) => ({
                ...prvState,
                meetings: meetings,
                isPlaying: false,
                autoScroll: false,
            }));
        }
    };

    const resetPlaylist: any = (playlist: IPlaylist[]) => {
        if (playlist !== state.playlist) {
            setState((prvState: any) => ({...prvState, playlist: playlist}));
        }
    };

    const resetDeals: any = (deals: IDeal[]) => {
        if (deals !== state.deals) {
            setState((prvState: any) => ({...prvState, deals: deals}));
        }
    };

    const setMeetings: any = (meetings: IMeeting[]) => {
        if (meetings !== state.meetings) {
            setState((prvState: any) => ({
                ...prvState,
                meetings: meetings,
                selectedTranscript: undefined,
                transcripts: [],
                isPlaying: false,
                autoScroll: false,
                selectedMeeting: (meetings && meetings.length > 0) ? (meetings[0] as IMeeting) : null,
            }));
        }
    };

    const setSearchMeetings: any = (searchMeetings: IMeetings[]) => {
        if (searchMeetings !== state.searchMeetings) {
            setState((prvState: any) => ({
                ...prvState,
                searchMeetings: searchMeetings
            }));
        }
    };

    const setMeetingsAndSearchMeetings: any = (newMeetings: IMeetings[], newSearchMeetings: IMeetings[]) => {
        setState((prvState: any) => ({
            ...prvState,
            meetings: newMeetings,
            loading: false,
            searchMeetings: newSearchMeetings,
        }));
    };

    const setMeetingCount: any = (meetingCount: IMeetingCount[]) => {
        if (meetingCount !== state.meetingCount) {
            setState((prvState: any) => ({
                ...prvState,
                meetingCount: meetingCount
            }));
        }
    };
    const setTotalMeetingCount: any = (totalMeetingCount: Number) => {
        if (totalMeetingCount !== state.totalMeetingCount) {
            setState((prvState: any) => ({
                ...prvState,
                totalMeetingCount: totalMeetingCount
            }));
        }
    }

    const setSearchedMeetings: any = (searchedMeetings: any[]) => {
        setState((prvState: any) => ({
            ...prvState,
            searchedMeetings,
            showNavSearch: true,
        }));
    };
    const setShowNavSearch: any = (showNavSearch: boolean) => {
        setState((prvState: any) => ({
            ...prvState,
            showNavSearch,
            searchedMeetings: [],
        }));
    };
    const updateMeeting: any = (meeting: IMeeting) => {
        if (meeting) {
            let prvMeetingIndex = state.meetings.findIndex(
                (meetingItem: IMeeting) =>
                    meetingItem.providerMeetingId === meeting.providerMeetingId
            );
            if (prvMeetingIndex > -1) {
                let meetings = state.meetings.splice(prvMeetingIndex, 1, meeting);
                setState((prvState: any) => ({
                    ...prvState,
                    meetings: meetings,
                    isPlaying: false,
                    autoScroll: false,
                }));
            }
        }
    };
    const setTranscripts: any = (transcripts: ITranscript[]) => {
        setState((prvState: any) => ({
            ...prvState,
            transcripts: transcripts,
            selectedTranscript: {
                transcript: transcripts && transcripts.length > 0 ? transcripts[0] : undefined,
            },
            colors: getColors(transcripts, "name"),
        }));
    };
    const setSearchedTranscripts: any = (searchedTranscripts: any[]) => {
        setState((prvState: any) => ({
            ...prvState,
            searchedTranscripts,
            showSearch: true,
        }));
    };
    const setShowSearch: any = (showSearch: boolean) => {
        setState((prvState: any) => ({
            ...prvState,
            showSearch,
            searchedTranscripts: [],
        }));
    };
    const setLoading: any = (loading: boolean) => {
        setState((prvState: any) => ({...prvState, loading}));
    };
    const setFilterByTranscriptUser: any = (filterByTranscriptUser: String) => {
        setState((prvState: any) => ({
            ...prvState,
            filterByTranscriptUser: filterByTranscriptUser,
        }));
    };
    const setDeals: any = (deals: IDeal[]) => {
        if (deals !== state.deals) {
            setState((prevState: any) => ({...prevState, deals: deals}));
        }
    };
    const setDealId: any = (dealId: string) => {
        dealId && setState((prevState: any) => ({...prevState, dealId: dealId}));
    };

    const setSelectedDeal: any = (selectedDeal: string) => {
        selectedDeal &&
        setState((prevState: any) => ({...prevState, selectedDeal: selectedDeal}));
    };

    const setCurrentModule: any = (currentModule: String) => {
        setState((prvState: any) => ({...prvState, currentModule: currentModule}));
    };
    const setCurrentTab: any = (currentTab: String) => {
        setState((prvState: any) => ({
            ...prvState,
            currentTab: currentTab,
            isPlaying: false,
            autoScroll: false,
            transcriptsWithComment: false,
        }));
    };
    const setCurrentLeftPanel: any = (currentLeftPanel: String) => {
        setState((prvState: any) => ({
            ...prvState,
            currentLeftPanel: currentLeftPanel,
            isPlaying: false,
            autoScroll: false,
            transcriptsWithComment: false,
        }));
    };

    const setPlayList: any = (playlist: IPlaylist) => {
        setState((prevState: any) => ({...prevState, playlist: playlist}));
    };
    const setCommentedData: any = (commentedData: ITranscriptInfo[]) => {
        setState((prevState: any) => ({...prevState, commentedData}));
    };
    const setMeetingSortBy = (sortBy: string) => {
        setState((prvState: any) => ({...prvState, meetingSortBy: sortBy}));
    };
    const setDealSortBy = (sortBy: string) => {
        setState((prvState: any) => ({...prvState, dealSortBy: sortBy}));
    };
    const setPlaylistSortBy = (sortBy: string) => {
        setState((prvState: any) => ({...prvState, playlistSortBy: sortBy}));
    };
    const setAutoScroll = (autoScroll: boolean) => {
        setState((prvState: any) => ({...prvState, autoScroll}));
    };
    const setReloadTransctipt = (reloadTransctipt: boolean) => {
        setState((prvState: any) => ({...prvState, reloadTransctipt}));
    };

    const setIsPlaying = (isPlaying: boolean) => {
        setState((prvState: any) => ({
            ...prvState,
            isPlaying: isPlaying,
            autoScroll: isPlaying,
        }));
    };

    const setIsPaused = (isPaused: boolean) => {
        setState((prvState: any) => ({
            ...prvState,
            isPaused: isPaused,
        }));
    };

    const changeMeeting = () => {
        setState((prvState: any) => ({
            ...prvState,
            isPlaying: false,
            autoScroll: false,
            transcriptsWithComment: false,
        }));
    };
    const showTranscriptsWithComment = (isFiltered: boolean) => {
        setState((prvState: any) => ({
            ...prvState,
            transcriptsWithComment: isFiltered
        }));
    };

    const setRedirectMeetingId = (id: String) => {
        setState((prvState: any) => ({...prvState, redirectMeetingId: id}));
    };

    const setNotesData = (newData: any) => {
        setState((prvState: any) => ({...prvState, currentModule: "test", notesData: newData}));
    };

    const setSeek = (seek: Number) => {
        setState((prvState: any) => ({
            ...prvState,
            seek: seek,
        }));
    };

    const setSeeking = (seeking: Seeking) => {
        setState((prvState: any) => ({
            ...prvState,
            seeking: seeking,
        }));
    };

    const setTimeStampsArray = (newData: any) => {
        setState((prvState: any) => ({...prvState, timeStampsArray: [...prvState.timeStampsArray, newData]}));
    };
    const reSetTimeStampsArray = (newData: any = []) => {
        setState((prvState: any) => ({...prvState, timeStampsArray: newData}));
    };

    return {
        commentedData: state.commentedData,
        setCommentedData,
        currentLeftPanel: state.currentLeftPanel,
        meetings: state.meetings,
        searchMeetings: state.searchMeetings,
        meetingCount: state.meetingCount,
        transcripts: state.transcripts,
        searchedTranscripts: state.searchedTranscripts,
        showSearch: state.showSearch,
        loading: state.loading,
        redirectMeetingId: state.redirectMeetingId,
        selectedMeeting: state.selectedMeeting,
        selectedTranscript: state.selectedTranscript,
        filterByTranscriptUser: state.filterByTranscriptUser,
        colors: state.colors,
        currentModule: state.currentModule,
        currentTab: state.currentTab,
        setMeetings,
        setSearchMeetings,
        setSearchedMeetings,
        setTranscripts,
        setSearchedTranscripts,
        setShowSearch,
        setShowNavSearch,
        setMeetingCount,
        setLoading,
        setSelectedMeeting,
        setSelectedTranscript,
        setFilterByTranscriptUser,
        updateMeeting,
        deals: state.deals,
        setDeals,
        setDealId,
        dealId: state.dealId,
        autoScroll: state.autoScroll,
        setAutoScroll,
        selectedDeal: state.selectedDeal,
        setSelectedDeal,
        setMeeting,
        setCurrentModule,
        setCurrentTab,
        setCurrentLeftPanel,
        setPlayList,
        playlist: state.playlist,
        resetMeetings,
        reset,
        setMeetingSortBy,
        meetingSortBy: state.meetingSortBy,
        setDealSortBy,
        dealSortBy: state.dealSortBy,
        setPlaylistSortBy,
        playlistSortBy: state.playlistSortBy,
        resetPlaylist,
        resetDeals,
        isPlaying: state.isPlaying,
        setIsPlaying,
        isPaused: state.isPaused,
        setIsPaused,
        changeMeeting,
        transcriptsWithComment: state.transcriptsWithComment,
        showTranscriptsWithComment,
        setRedirectMeetingId,
        updateMeetingData,
        updateTranscriptSpeaker,
        setMeetingsAndSearchMeetings,
        notesData: state.notesData,
        setNotesData,
        setSeek,
        seek: state.seek,
        setSeeking,
        seeking: state.seeking,
        setTimeStampsArray,
        reSetTimeStampsArray,
        timeStampsArray: state.timeStampsArray,
        reloadTransctipt: state.reloadTransctipt,
        setReloadTransctipt,
        totalMeetingCount: state.totalMeetingCount,
        setTotalMeetingCount,
    };
};

export default useObjectHook;
