import { useContext } from "react";
import { BehaviourContext } from "../context/AppStore";
const useBehaviourContext: any = () => {
   const [state, setState] = useContext(BehaviourContext);

   const setExtraLeftPanel: any = (expand: string) => {
      setState((prvState: any) => ({ ...prvState, showExtraLeftPanel: expand }));
   };
   const setExpandLeftPanel: any = (expand: boolean) => {
      setState((prvState: any) => ({ ...prvState, expandLeftPanel: expand }));
   };
   const setShowSearchPanel: any = (expand: boolean) => {
      setState((prvState: any) => ({ ...prvState, showSearchPanel: expand }));
   };
   const setFilterTopNav: any = (expand: boolean) => {
      setState((prvState: any) => ({ ...prvState, isFilterTopNav: expand }));
   };
   const setSearchText: any = (searchterm: string) => {
      setState((prvState: any) => ({ ...prvState, searchText: searchterm, transSearch: searchterm }));
   };
   const setTransSearchText: any = (transSearch: string) => {
      setState((prvState: any) => ({ ...prvState, transSearch: transSearch }));
   };
   const setSearchFilterOn: any = (searchFilterOn: boolean) => {
      setState((prvState: any) => ({ ...prvState, searchFilterOn: searchFilterOn }));
   };

   const setNotesData = (newData: any) => {
      setState((prvState: any) => ({ ...state, notesData: newData}));
    };
   const setIsOpenTranscriptComment: any = (isOpenTranscriptComment: boolean) => {
      setState((prvState: any) => ({...prvState, isOpenTranscriptComment: isOpenTranscriptComment}))
   }

   const setMeetingSearchTerms: any = (searchterm: any) => {
      setState((prvState: any) => ({
         ...prvState,
         meetingSearchTerms: searchterm,
      }));
   };
   const setHideMiddlePanel: any = (hide: boolean) => {
      setState((prvState: any) => ({ ...prvState, hideMiddlePanel: hide }));
   };
   const setHideRightPanel: any = (hide: boolean) => {
      setState((prvState: any) => ({ ...prvState, hideRightPanel: hide }));
   };
   const setHideContentPanel: any = (hide: boolean) => {
      setState((prvState: any) => ({ ...prvState, hideContentPanel: hide }));
   };
   const setHideContentHeaderPanel: any = (hide: boolean) => {
      setState((prvState: any) => ({
         ...prvState,
         hideContentHeaderPanel: hide,
      }));
   };
   const setHideMeetingPanel: any = (hide: boolean) => {
      setState((prvState: any) => ({ ...prvState, hideMeetingPanel: hide }));
   };
   const setMeetingHiddenColumns: any = (meetingHiddenColumns: string[]) => {
      setState((prvState: any) => ({
         ...prvState,
         meetingHiddenColumns: meetingHiddenColumns,
      }));
   };
   const setLoading: any = (hide: boolean) => {
      setState((prvState: any) => ({ ...prvState, loading: hide }));
   };
   const setCurrentLeftTab: any = (tab: string) => {
      setState((prvState: any) => ({ ...prvState, currentLeftTab: tab }));
   };
   const setVideoFullExpanded: any = (expand: boolean) => {
      setState((prvState: any) => ({ ...prvState, videoFullExpanded: expand }));
   };
   const setTranscriptRow: any = (showTab: boolean) => {
      setState((prvState: any) => ({ ...prvState, transcriptRow: showTab }));
   }

   const setLoadingMeetingCount = (loading: boolean) => {
      setState((prvState: any) => ({ ...prvState, loadingMeetingCount: loading }));
   }

   const setEditCommentId = (id: string) => {
      setState((prvState: any) => ({...prvState, editCommentId: id }));
   }

   return {
      searchText: state.searchText,
      setSearchText,
      transSearch: state.transSearch,
      setTransSearchText,
      searchFilterOn: state.searchFilterOn,
      setSearchFilterOn,
      meetingSearchTerms: state.meetingSearchTerms,
      setMeetingSearchTerms,
      hideContentHeaderPanel: state.hideContentHeaderPanel,
      hideContentPanel: state.hideContentPanel,
      hideMeetingPanel: state.hideMeetingPanel,
      expandLeftPanel: state.expandLeftPanel,
      hideMiddlePanel: state.hideMiddlePanel,
      hideRightPanel: state.hideRightPanel,
      meetingHiddenColumns: state.meetingHiddenColumns,
      showSearchPanel: state.showSearchPanel,
      showNavSearchPanel: state.showNavSearchPanel,
      loading: state.loading,
      currentLeftTab: state.currentLeftTab,
      setCurrentLeftTab,
      setLoading,
      setMeetingHiddenColumns,
      setExpandLeftPanel,
      setHideMiddlePanel,
      setShowSearchPanel,
      setHideRightPanel,
      setHideContentPanel,
      setHideMeetingPanel,
      setHideContentHeaderPanel,
      setFilterTopNav,
      isFilterTopNav: state.isFilterTopNav,
      setExtraLeftPanel,
      showExtraLeftPanel: state.showExtraLeftPanel,
      setIsOpenTranscriptComment,
      isOpenTranscriptComment:state.isOpenTranscriptComment,
      notesData: state.notesData,
      setNotesData,
      videoFullExpanded: state.videoFullExpanded,
      setVideoFullExpanded,
      transcriptRow: state.transcriptRow,
      setTranscriptRow,
      loadingMeetingCount: state.loadingMeetingCount,
      setLoadingMeetingCount,
      editCommentId: state.editCommentId,
      setEditCommentId
   };
};

export default useBehaviourContext;
