import React from "react";
import {ITextAreaFilterItemProps} from "./FilterPanel";

export const defaultRangeProps: ITextAreaFilterItemProps = { id: "", label: "", component_name: "TextArea", text:"", rows:1, cols:50  };
const FilterTeaxtArea: React.FC<ITextAreaFilterItemProps> = (props: ITextAreaFilterItemProps) => {
  return (
    <div>
      <textarea  style={{fontSize:14}}  id={"input" + props.id} name={"input" + props.id}
        disabled={props.disabled || false} 
        readOnly={props.disabled || false} 
        rows = {props.rows} 
        cols = {props.cols} 
        value ={props.text} onChange={(e) => {
          e.persist();
          props.onChangeValue && props.onChangeValue(props.id, e.target.value, e);
        }}></textarea>
    </div>
  );
}

FilterTeaxtArea.defaultProps = defaultRangeProps;
export default FilterTeaxtArea;