import { Suspense, useEffect } from 'react';
import { ApolloClient, ApolloProvider, InMemoryCache, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { AppContextProvider, BehaviourContextProvider, ObjectContextProvider, UploadContextProvider } from './context/AppStore';
import Routes from './routes';
import { ToastContainer } from 'react-toastify';

// Create an HTTP link that connects to your GraphQL endpoint
const httpLink = new HttpLink({
    uri: `${process.env.REACT_APP_REPOSITORY_URL}/graphql`,
    credentials: 'include', // include, same-origin, omit
});

// Set up the middleware to include the token in the authorization header for each request
const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('token');
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    };
});

// Initialize the ApolloClient with the authLink and httpLink
let client = new ApolloClient({
    link: authLink.concat(httpLink), // Chain the auth link and the http link
    cache: new InMemoryCache(),
    connectToDevTools: true,
});

// The setAuthToken function now only needs to update the token in localStorage
export const setAuthToken = (token) => {
    localStorage.setItem('token', token);
};

// Set up global error listener for chunk loading errors with retry logic
window.addEventListener('error', (e) => {
    if (e.message && e.message.includes('Loading chunk')) {
        console.warn('Chunk load error detected. Attempting to reload...');

        // Delay before reload to allow network recovery, etc.
        setTimeout(() => {
            window.location.reload();
        }, 3000); // 3-second delay to avoid immediate reload loops
    }
});

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

const App = () => {
    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (e.stopPropagation) {
                e.stopPropagation();
            } else if (window.event) {
                window.event.cancelBubble = true;
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    return (
        <AppContextProvider meetingActiveTab={'1'}>
            <ObjectContextProvider>
                <BehaviourContextProvider>
                    <ApolloProvider client={client}>
                        <UploadContextProvider>
                            <Suspense fallback={loading()}>
                                <Routes />
                            </Suspense>
                            <ToastContainer
                                position="top-left"
                                hideProgressBar
                                theme="colored"
                                pauseOnHover
                                newestOnTop
                                autoClose={2000}
                            />
                        </UploadContextProvider>
                    </ApolloProvider>
                </BehaviourContextProvider>
            </ObjectContextProvider>
        </AppContextProvider>
    );
};

export default App;
