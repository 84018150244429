import React, { useEffect, useRef, useState } from "react";
import Components from "./Components";
import {
  Button,
  Card,
  CardBody,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import useBehaviourContext from "../../../hooks/useBehaviourHook";
import useAppContext from "../../../hooks/useAppHook";
import {GET_USERS_BY_COMPANY} from "../../../graphql/ql/schema";
import {GetCallDispositions, GetLeadSources, getMeetingProviders, GetStage,} from "./FilterSelect";
import { InputType } from "reactstrap/lib/Input";
import { DocumentNode } from "graphql";
import dropdown from "../../../assets/img/dropdown.jpeg";
import ComponentsNoFilter from "./ComponentsNoFilter";
import {getDates, getMeetingDuration} from "../../helper";
import {DictionaryItem} from "../../../types";
import {useLocation} from "react-router-dom";
import { mapFilterValues } from "../../Dashboard/utils";

/* eslint-disable */
interface IFormEvents extends React.InputHTMLAttributes<HTMLElement> {
  onChangeValue?: (name, value, e) => void;
}

export interface IFilterItemProps extends IFormEvents {
  id: string;
  label?: string;
  component_name: string;
  type?: InputType;
  text: any;
  queryDoc?: any;
  queryFilter?: any;
  disabled?: boolean;
  readOnly?: boolean;
  hidden?: boolean;
  value?: any;
  startValue?: any;
  endValue?:any;
  dependsOn?: string;
  className?: string;
  dependsOnValue?: string;
  itemFieldName?: string;
  isHeading?: boolean;
}

export interface ISwitchFilterItemProps extends IFilterItemProps {
  setChosen: (value: boolean) => void;
}

export interface ISelectFilterItemProps<T> extends IFilterItemProps {
  items?: T[];
  selectedItems?: any[];
  itemFieldName?: string;
  mode: "Multi" | "Single";
  dataProvider?: (user?: string) => Promise<T>;
  queryDoc?: DocumentNode;
  optionName: string;
  optionCode: string;
  sortBy?: string;
  filter?: DictionaryItem<String>;
}

export interface IGroupFilterItemProps extends IFilterItemProps {
  items: { code: string; label: string }[];
}

export interface ITextAreaFilterItemProps extends IFilterItemProps {
  rows: number;
  cols: number;
}

export interface IFilterGroup {
  name: string;
  formData?: {};
  filters: (
      | IFilterItemProps
      | ISwitchFilterItemProps
      | ISelectFilterItemProps<any>
      | ITextAreaFilterItemProps
      )[];
  dynamicWidth?: string;
  showAsDropdown?: any;
}

export interface IFitlerProps {
  groups?: [IFilterGroup];
  submitForm?: (formData: any) => any;
  cancelForm?: () => any;
  submitText?: string;
  toggle?: () => any;
  filterForm?: any;
  setFilterForm?: (filterForm: any) => any;
  isOpen?: boolean;
  isFilter?: boolean;
  isFiltered?: boolean;
  setIsFiltered?: (bool: any) => any;
  showTranscriptsWithComment?: (bool: any) => any;
  clearSearch?: Function;
  showAsDropdown?: boolean;
}

export const defaultProps: any = {
  groups: [
    {
      name: "Meetings",
      filters: [
        {
          id: "callDirection",
          label: "CALL DIRECTIONS",
          component_name: "Select",
          items: ["Inbound", "Outbound"],
          mode: "Single",
          value: "",
        },
        {
          id: "callType",
          label: "CALL TYPE",
          component_name: "Select",
          items: ["Web Conferencing", "Dialer"],
          mode: "Single",
          value: "",
        },
        {
          id: "meetingStage",
          label: "DEAL STAGE AT TIME OF MEETING",
          component_name: "Select",
          mode: "Single",
          dataProvider: GetStage,
          itemFieldName: "stageList",
          value: "",
        },
        {
          id: "callScope",
          label: "CALL SCOPE",
          component_name: "Select",
          items: ["External", "Internal"],
          mode: "Single",
          value: "",
        },
        {
          id: "providers",
          label: "MEETING SOURCES",
          component_name: "Select",
          dataProvider: getMeetingProviders,
          mode: "Single",
        },
        {
          id: "callDisposition",
          label: "CALL DISPOSITIONS",
          mode: "Multi",
          component_name: "Select",
          dataProvider: GetCallDispositions,
          value: "",
          optionCode: "name",
          optionName: "name",
        },
      ],
    },
    {
      name: "Metrics",
      filters: [
        {
          id: "talkListenRatio",
          toolTipText: "Percent of time spent by Rep talking vs listening",
          label: "TALK LISTEN RATIO",
          component_name: "Range",
          rangeLabel: "0%-100%",
          // value:[0,100],
          startValue: 0,
          endValue: 100,
          min: 0,
          max: 100,
        },
        {
          id: "longestMonologue",
          toolTipText: "Duration of the longest speech by Rep (in mins)",
          label: "LONGEST MONOLOGUE",
          component_name: "Range",
          rangeLabel: "0-20 MIN",
          startValue: 0,
          endValue: 20,
          // value: { min: 0, max: 20 },
          value:[0,100],
          min: 0,
          max: 20,
        },
        {
          id: "longestStory",
          toolTipText: "Duration of the longest story by Prospect (in mins)",
          label: "LONGEST STORY",
          component_name: "Range",
          rangeLabel: "0-20 MIN",
          // value: { min: 0, max: 20 },
          value:[0,100],
          startValue: 0,
          endValue: 20,
          min: 0,
          max: 20,
        },
        {
          id: "interactivity",
          toolTipText: "Average number of speaker switches per minute",
          label: "INTERACTIVITY",
          component_name: "Range",
          rangeLabel: "0-10",
          // value: { min: 0, max: 10 },
          value:[0,100],
          startValue: 0,
          endValue: 10,
          min: 0,
          max: 10,
        },
        {
          id: "patience",
          toolTipText:
              "Average time (in secs) Rep waited before responding to Prospect",
          label: "PATIENCE",
          component_name: "Range",
          rangeLabel: "0-3 SECS",
          // value: { min: 0, max: 3 },
          startValue: 0,
          value:[0,100],
          endValue: 3,
          min: 0,
          max: 3,
        },
        {
          id: "hostQuestions",
          toolTipText: "Number of questions asked by Rep during call",
          label: "NUMBER OF HOST QUESTIONS",
          component_name: "Range",
          rangeLabel: "0-100 QUESTIONS",
          // value: { min: 0, max: 100 },
          startValue: 0,
          value:[0,100],
          endValue: 100,
          min: 0,
          max: 100,
        },
        {
          id: "prospectQuestions",
          toolTipText: "Number of questions asked by Prospect during call",
          label: "NUMBER OF PROSPECT QUESTIONS",
          component_name: "Range",
          rangeLabel: "0-100 QUESTIONS",
          // value: { min: 0, max: 100 },
          startValue: 0,
          value:[0,100],
          endValue: 100,
          min: 0,
          max: 100,
        },
        {
          id: "rateofHostQuestions",
          toolTipText: "Rate at which Rep asked questions during call",
          label: "RATE OF HOST QUESTIONS",
          component_name: "Range",
          rangeLabel: "0-100 QUESTIONS",
          // value: { min: 0, max: 100 },
          startValue: 0,
          value:[0,100],
          endValue: 100,
          min: 0,
          max: 100,
        },
        {
          id: "rateofProspectQuestions",
          toolTipText: "Rate at which Prospect asked questions during call",
          label: "RATE OF PROSPECT QUESTIONS",
          component_name: "Range",
          rangeLabel: "0-100 QUESTIONS",
          // value: { min: 0, max: 100 },
          startValue: 0,
          value:[0,100],
          endValue: 100,
          min: 0,
          max: 100,
        },
      ],
    },
    {
      name: "CRM",
      filters: [
        {
          id: "currentStage",
          label: "CURRENT DEAL STAGE",
          mode: "Single",
          component_name: "Select",
          dataProvider: GetStage,
          itemFieldName: "currentStageList",
        },
        {
          id: "amount",
          label: "AMOUNT",
          component_name: "Select",
          mode: "Single",
          items: [
            "0-10000",
            "10000-20000",
            "30000-40000",
            "40000-50000",
            ">50000",
          ],
        },
        {
          id: "closeDate",
          label: "CLOSE DATE",
          component_name: "Select",
          mode: "Single",
          items: [
            "Last 7 days",
            "Last 30 days",
            "Last 60 days",
            "Last 90 days",
            "Last 180 days",
            "Last year",
          ],
          value: "",
        },
        {
          id: "owner",
          label: "OWNER",
          component_name: "Select",
          queryDoc: GET_USERS_BY_COMPANY,
          // queryFilter: {"customerId": user.customer.id},
          mode: "Single",
          itemFieldName: "usersByCustomer",
          optionCode: "id",
          optionName: "firstName,lastName",
          value: "",
        },
        {
          id: "lastUpdatedDate",
          label: "LAST UPDATED DATE",
          component_name: "Select",
          mode: "Single",
          items: [
            "Last 7 days",
            "Last 30 days",
            "Last 60 days",
            "Last 90 days",
            "Last 180 days",
            "Last year",
          ],
          value: "",
        },
        {
          id: "leadSources",
          label: "LEAD SOURCES",
          mode: "Single",
          component_name: "Select",
          dataProvider: GetLeadSources,
        },
      ],
    },
  ],
};

export const GroupPanel: React.FC<IFilterGroup & IFormEvents> = (
    props: IFilterGroup & IFormEvents
) => {
  return (
      <Card
          key={"group-" + props.name}
          style={{
            border: "none",
            width: props?.dynamicWidth ? props?.dynamicWidth : "30%",
          }}
      >
        {!props?.showAsDropdown ? (
            <p style={{ marginBottom: "5px" }}>
              <b>{props.name}</b>
            </p>
        ) : null}
        <CardBody className={props.name === "Metrics" ? "filterMetrics" : ""}>
          {props.filters.map((block) => {
            let value = block.text;
            let disabled = false;
            let disabledValue = "";
            if (block?.isHeading)
              return (
                  <p style={{ marginBottom: "5px" }}>
                    <b>{block.label}</b>
                  </p>
              );
            if (props.formData) {
              value = props.formData[block.id] || value;
              if (block["mode"] && block["mode"] == "Multi") {
                block["selectedItems"] = value;
              }
              if (block.dependsOn) {
                if (typeof props.formData[block.dependsOn] === "object") {
                  disabledValue = props.formData[block.dependsOn].value;
                } else {
                  disabledValue = props.formData[block.dependsOn];
                }
                disabled = disabledValue === block.dependsOnValue ? false : true;
              }
              if (block["component_name"] == "Range" && !value) {
                const value = {
                  min: props.formData[`${block.id}Min`] || block.min,
                  max: props.formData[`${block.id}Max`] || block.max,
                };
                block.value = value;
              }
            }
            if (!block.hidden) {
              return Components({
                ...block,
                text: value,
                disabled: disabled,
                ...{ onChangeValue: props.onChangeValue },
                showAsDropdown: props?.showAsDropdown,
              });
            }
          })}
        </CardBody>
      </Card>
  );
};

export const GroupPanelNoFilter: React.FC<IFilterGroup & IFormEvents> = (
    props: IFilterGroup & IFormEvents
) => {
  return (
      <Card key={"group-" + props.name} style={{ border: "none", width: "90%" }}>
        <p style={{ marginBottom: "5px" }}>
          <b>{props.name}</b>
        </p>
        <CardBody className={props.name === "Metrics" ? "filterMetrics" : ""}>
          {props.filters.map((block) => {
            let value = block.text;
            let disabled = false;
            let disabledValue = "";
            if (block?.isHeading)
              return (
                  <p style={{ marginBottom: "5px" }}>
                    <b>{block.label}</b>
                    <img
                        style={{ marginTop: "-5px", marginLeft: "15px" }}
                        src={dropdown}
                        alt="dd"
                    />
                  </p>
              );
            if (props.formData) {
              value = props.formData[block.id] || value;
              if (block.dependsOn) {
                if (typeof props.formData[block.dependsOn] === "object") {
                  disabledValue = props.formData[block.dependsOn].value;
                } else {
                  disabledValue = props.formData[block.dependsOn];
                }
                disabled = disabledValue === block.dependsOnValue ? false : true;
              }
            }
            if (!block.hidden) {
              return ComponentsNoFilter({
                ...block,
                text: value,
                disabled: disabled,
                ...{ onChangeValue: props.onChangeValue },
              });
            }
          })}
        </CardBody>
      </Card>
  );
};

const updateCentralPanelDefaultProps = (formData, groups) => {
  const DATA = groups;
  let filters = DATA.find((item) => item?.name === "Metrics")?.filters;
  // let { filters } = DATA[1];
  if (formData && formData instanceof Object && Object.keys(formData).length) {
    filters = filters.map((data, i) => {
      const value = {
        min: formData[`${data.id}Min`] || data.min,
        max: formData[`${data.id}Max`] || data.max,
      };
      filters[i].value = value;
    });
  }
  return DATA;
};

const FilterPanel: React.FC<IFitlerProps> = (props: IFitlerProps) => {
  const { user, setFilterData } = useAppContext();
  const [formData, updateFormData] = React.useState(props.filterForm);
  const location = useLocation();
  // updateFormData()
  const {
    setMeetingSearchTerms,
    meetingSearchTerms,
    setShowSearchPanel,
    setFilterTopNav,
    searchText,
    setExtraLeftPanel,
    setHideMeetingPanel,
  } = useBehaviourContext();
  const [filterInput, setFilterInput] = useState({});
  const [fieldGroups, updateFieldGroups] = React.useState(props.groups);

  useEffect(() => {
    if (!props.filterForm || Object.keys(props.filterForm).length == 0) {
      updateFormData({});
    }
  }, [props.filterForm]);

  const handleChange = (name, value, e) => {
    let metricsFilters = ["talkListenRatio", "longestMonologue", "longestStory", "interactivity", "patience",
      "hostQuestions", "prospectQuestions", "rateofHostQuestions", "rateofProspectQuestions"]
    if (metricsFilters.includes(name)) {
      let temp = value;
      value = {};
      value.min = temp;
      value.max = e;
    }
    let newValue = value;
    setExtraLeftPanel("");
    let newName = name;
    if (value === undefined || value == null) {
      if (e) {
        newValue = e.currentTarget.value;
        if (name === undefined) {
          newName = e.currentTarget.id;
        }
      } else if (name && name.currenTarget !== undefined) {
        newValue = name.currenTarget.value;
        if (name === undefined) {
          newName = e.currentTarget.id;
        }
      } else {
        if (name) {
          newName = name;
        }
        updateFormData({
          ...formData,
          // Trimming any whitespace
          [newName]: newValue,
        });
      }
    }
    if (!newName) return;

    if (value && (value.min || value.max)) {
      const data = {
        ...formData,
        [`${name}Min`]: value.min,
        [`${name}Max`]: value.max,
      };
      updateFormData(data);
      updateFieldGroups(updateCentralPanelDefaultProps(data, fieldGroups));
    }
    if (value && !value.min && !value.max) {
      updateFormData({
        ...formData,
        // Trimming any whitespace
        [newName]: newValue,
      });
    }
  };

  const clearFilter = (e: React.FormEvent<HTMLFormElement> | any) => {
    e.preventDefault();
    setFilterInput({});
    if (props.setFilterForm) {
      props.setFilterForm({});
      setFilterData({});
      updateFormData({});
    }
    if (props.clearSearch) {
      props.clearSearch();
    }
    if (props.setIsFiltered) {
      props.setIsFiltered(false);
    }
    setShowSearchPanel(true);
    if (location?.pathname === "/meetings") {
      setHideMeetingPanel(true);
    } else {
      setHideMeetingPanel(false);
    }
    props.toggle && props.toggle();
  };

  const getFilter = () => {
    let filter: any = {};
    let crmfilter: any = {};
    let metricsfilter: any = {};
    let hosts = [];
    for (let item in formData["representative"])
      hosts[item] = formData["representative"][item].value;
    if (hosts.length) filter.hosts = hosts;

    let teams = [];
    for (let item in formData["team"])
      teams[item] = formData["team"][item].value;
    if (teams.length) filter.teams = teams;
    if (formData["meetingDate"]) {
      const { minDate, maxDate } = formData["meetingDate"].data;
      filter.dateMin = minDate;
      filter.dateMax = maxDate;
    }

    if (formData["meetingDuration"]) {
      let temp = getMeetingDuration(formData["meetingDuration"].value);
      filter.durationMin = parseFloat(String(temp?.split("-")[0])) * 60;
      filter.durationMax = parseFloat(String(temp?.split("-")[1])) * 60;
    }
    if (formData["meetingStage"])
      filter.stageName = formData["meetingStage"].value;
    if (formData["callCategory"])
      filter.callCategory = formData["callCategory"].value;
    if (formData["callType"]) {
      filter.callType = formData["callType"].value;
    }
    if (formData["callScope"]) filter.callScope = formData["callScope"].value;
    if (formData["callDirection"]) filter.callDirection = formData["callDirection"].value;
    if (formData["leadSources"])
      filter.leadSources = formData["leadSources"].name;

    if (formData["providers"]) {
      if (formData["providers"].value === "Google")
        filter.providers = formData["providers"].value.toLowerCase();
      else filter.providers = formData["providers"].value;
    }

    let participants = [];
    for (let item in formData["participants"])
      participants[item] = formData["participants"][item].value;
    if (participants.length) filter.participants = participants;

    let callDispositions = [];
    for (let item in formData["callDisposition"])
      callDispositions[item] = formData["callDisposition"][item].label;
    if (callDispositions.length) filter.callDisposition = callDispositions;

    let topics = [];
    for (let item in formData["topics"])
      topics[item] = formData["topics"][item].value;
    if (topics.length) filter.topics = topics;

    let trackers = [];
    for (let item in formData["trackers"])
      trackers[item] = formData["trackers"][item].value;
    if (trackers.length) filter.trackers = trackers;

    let currentStage: String[] = [];
    if (formData["currentStage"]) {
      currentStage.push(formData["currentStage"].value);
    }
    if (currentStage.length) crmfilter.currentStage = currentStage;

    if (formData["amount"]) {
      crmfilter.amountMin = +formData["amount"].value.split("-")?.[0];
      crmfilter.amountMax = +formData["amount"].value.split("-")?.[1];
    }
    if (formData["closeDate"]) {
      crmfilter.closeDateMin = getDates(formData["closeDate"].value);
      crmfilter.closeDateMax = new Date().toISOString();
    }
    if (formData["owner"]) crmfilter.owner = formData["owner"].value;
    if (formData["accountName"])
      crmfilter.accountName = formData["accountName"].value;
    if (formData["lastUpdatedDate"]) {
      crmfilter.updatedDateMin = getDates(formData["lastUpdatedDate"].value);
      crmfilter.updatedDateMax = new Date().toISOString();
    }
    if (crmfilter) filter.crmFilter = crmfilter;

    if (formData["talkListenRatioMin"])
      metricsfilter.talkListenRatioMin = formData["talkListenRatioMin"];
    if (formData["talkListenRatioMax"])
      metricsfilter.talkListenRatioMax = formData["talkListenRatioMax"];

    if (formData["longestMonologueMin"])
      metricsfilter.longestMonologueMin = formData["longestMonologueMin"] * 60;
    if (formData["longestMonologueMax"])
      metricsfilter.longestMonologueMax = formData["longestMonologueMax"] * 60;

    if (formData["longestStoryMin"])
      metricsfilter.longestStoryMin = formData["longestStoryMin"] * 60;
    if (formData["longestStoryMax"])
      metricsfilter.longestStoryMax = formData["longestStoryMax"] * 60;

    if (formData["interactivityMax"])
      metricsfilter.interactivityMax = formData["interactivityMax"];
    if (formData["interactivityMin"])
      metricsfilter.interactivityMin = formData["interactivityMin"];

    if (formData["patienceMin"])
      metricsfilter.patienceMin = formData["patienceMin"];
    if (formData["patienceMax"])
      metricsfilter.patienceMax = formData["patienceMax"];

    if (formData["hostQuestionsMin"])
      metricsfilter.hostQuestionsMin = formData["hostQuestionsMin"];
    if (formData["hostQuestionsMax"])
      metricsfilter.hostQuestionsMax = formData["hostQuestionsMax"];

    if (formData["prospectQuestionsMin"])
      metricsfilter.prospectQuestionsMin = formData["prospectQuestionsMin"];
    if (formData["prospectQuestionsMax"])
      metricsfilter.prospectQuestionsMax = formData["prospectQuestionsMax"];

    if (formData["rateofHostQuestionsMin"])
      metricsfilter.rateofHostQuestionsMin = formData["rateofHostQuestionsMin"];
    if (formData["rateofHostQuestionsMax"])
      metricsfilter.rateofHostQuestionsMax = formData["rateofHostQuestionsMax"];

    if (formData["rateofProspectQuestionsMin"])
      metricsfilter.rateofProspectQuestionsMin =
          formData["rateofProspectQuestionsMin"];
    if (formData["talkListenRatioMax"])
      metricsfilter.rateofProspectQuestionsMax =
          formData["rateofProspectQuestionsMax"];

    if (metricsfilter) filter.metricsFilter = metricsfilter;
    return filter;
  };

  const applyFilter = (e: React.FormEvent<HTMLFormElement> | any) => {
    e.preventDefault();
    if (props.setIsFiltered) {
      props.setIsFiltered(true);
    }
    let filter = getFilter();
    setFilterInput({ ...filter });
    if (props.setFilterForm) {
      props.setFilterForm(formData);
      setFilterData(formData);
      if (formData instanceof Object && Object.keys(formData).length) {
        const DATA = mapFilterValues(formData);
        if (DATA instanceof Object && Object.keys(DATA).length > 0) {
          setFilterTopNav(true);
        } else {
          if (!searchText) {
            setFilterTopNav(false);
          }
        }
      }
    }
    setShowSearchPanel(true)
    let newFilter = (meetingSearchTerms instanceof Object && Object.keys(meetingSearchTerms).length > 0) ?
        { ...meetingSearchTerms, ...filter } : filter;

    setMeetingSearchTerms(
        (meetingSearchTerms instanceof Object && Object.keys(meetingSearchTerms).length > 0) ?
            { ...meetingSearchTerms, ...filter } : filter
    );
    setHideMeetingPanel(location?.pathname === "/meetings");
    props.toggle && props.toggle();
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      const onClickOutsideListener = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (props.toggle) {
            props?.toggle();
          }
        }
      };
      // Bind the event listener
      document.addEventListener("mousedown", onClickOutsideListener);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", onClickOutsideListener);
      };
    }, [ref]);
  }

  const RenderCardOnly = () => {
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);
    return (
        <div className={"filterpanel filter-panel-dropdown "} ref={wrapperRef}>
          <Form noValidate={true} className={"filter-panel-dropdown-form"}>
            <Card style={{ border: "none", paddingBottom: "20px" }}>
              <CardBody>
                <div
                    className="filter-panel"
                    style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {fieldGroups?.map((block, index) => {
                    if (block?.name === "Meetings") {
                      block.filters[0].queryFilter = {
                        customerId: user?.customer?.id,
                      };
                    }
                    if (block?.name === "Topics & Trackers") {
                      block.filters[0].queryFilter = { id: user?.customer?.id };
                      block.filters[1].queryFilter = { id: user?.customer?.id };
                      block.filters[6].queryFilter = {
                        customerId: user?.customer?.id,
                      };
                      block.filters[7].queryFilter = {
                        customerId: user?.customer?.id,
                      };
                    }
                    if (block?.name === "CRM") {
                      block.filters[3].queryFilter = {
                        customerId: user?.customer?.id,
                      };
                      block.filters[4].queryFilter = {
                        customerId: user?.customer?.id,
                      };
                    }
                    let props = { ...block, formData: { ...formData } };
                    return (
                        <GroupPanel
                            showAsDropdown
                            dynamicWidth={fieldGroups?.length == 1 ? "100%" : "30%"}
                            key={"filter-panel-row" + block.name}
                            {...props}
                            onChangeValue={handleChange}
                        ></GroupPanel>
                    );
                  })}
                </div>
              </CardBody>
            </Card>
            <div
                style={{
                  textAlign: "center",
                  position: "absolute",
                  justifyContent: "center",
                  width: "100%",
                  background: "white",
                  bottom: 0,
                  paddingTop: "6px",
                  paddingBottom: "8px",
                }}
            >
              <Button color="primary" onClick={applyFilter}>
                Apply
              </Button>
              <Button
                  style={{ marginLeft: 20 }}
                  color="primary"
                  onClick={clearFilter}
              >
                Clear
              </Button>
            </div>
          </Form>
        </div>
    );
  };

  return (
      <>
        {props.showAsDropdown ? (
            <>{props.isOpen && <RenderCardOnly />}</>
        ) : (
            <Modal
                size={fieldGroups?.length == 1 ? "" : "xl"}
                className="appProvider-container"
                isOpen={props.isOpen}
                toggle={props.toggle}
                placement="auto-end"
            >
              <ModalHeader
                  toggle={props.toggle}
                  style={{ border: "none", padding: "15px 15px 5px" }}
                  charCode="x"
              >
                <span style={{ fontSize: "20px", fontWeight: 500 }}>Filters</span>
              </ModalHeader>
              <ModalBody>
                <div className={"animated fadeIn filterpanel"}>
                  <Form noValidate={true}>
                    <Card style={{ border: "none" }}>
                      <CardBody>
                        <div
                            className="filter-panel"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                        >
                          {fieldGroups?.map((block, index) => {
                            if (block?.name === "Meetings") {
                              block.filters[0].queryFilter = {
                                customerId: user?.customer?.id,
                              };
                            }
                            if (block?.name === "Topics & Trackers") {
                              block.filters[0].queryFilter = {
                                id: user?.customer?.id,
                              };
                              block.filters[1].queryFilter = {
                                id: user?.customer?.id,
                              };
                              block.filters[6].queryFilter = {
                                customerId: user?.customer?.id,
                              };
                              block.filters[7].queryFilter = {
                                customerId: user?.customer?.id,
                              };
                            }
                            if (block?.name === "CRM") {
                              block.filters[3].queryFilter = {
                                customerId: user?.customer?.id,
                              };
                              block.filters[4].queryFilter = {
                                customerId: user?.customer?.id,
                              };
                            }
                            let props = { ...block, formData: { ...formData } };
                            return (
                                <GroupPanel
                                    dynamicWidth={
                                      fieldGroups?.length == 1 ? "100%" : "30%"
                                    }
                                    key={"filter-panel-row" + block.name}
                                    {...props}
                                    onChangeValue={handleChange}
                                ></GroupPanel>
                            );
                          })}
                        </div>
                      </CardBody>
                    </Card>
                    <div style={{ textAlign: "center" }}>
                      <Button color="primary" onClick={applyFilter}>
                        Apply
                      </Button>
                      <Button
                          style={{ marginLeft: 20 }}
                          color="primary"
                          onClick={clearFilter}
                      >
                        Clear
                      </Button>
                    </div>
                  </Form>
                </div>
              </ModalBody>
            </Modal>
        )}
      </>
  );
};

FilterPanel.defaultProps = defaultProps;
export default FilterPanel;
