import React, {useRef, useState} from "react";
import {Button, Col, Form, Row} from "reactstrap";
import {useAppContext} from "../../hooks";
import {postAxiosService} from "../../services/callAxios";
import {AudioRecorder} from "../core/AudioRecorder";
import {toastService} from "../../services/toast.service";

const BioMetrics = (props) => {
    const recordRef = useRef(null);
    const [message, setMessage] = useState("");
    const {user} = useAppContext();
    const [biometricUrl, setBiometricUrl] = useState(props.bioMetricUrl);
    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.currentTarget;
        const data = new FormData(form);
        handleSubmit(data);
    }
    const handleSubmit = async (formData: FormData) => {
        if (recordRef.current) {
            if (!(recordRef.current as any).state.blob) {
                setMessage(
                    "Please record before submitting the change."
                );
                return;
            }
            formData.append("file", (recordRef.current as any).state.blob);
        }
        formData.set("userId", user.id);
        formData.set("customerId", user.customer.id);
        let data1 = await postAxiosService("users/uploadFile", formData);
        if (data1.data === "success") {
            toastService.info("Successfully updated");
            props.toggle();
        } else {
            setMessage("upload failed");
        }
    }

    return (
        <>
            {message && <h3 className="error"> {message} </h3>}
            <Row style={{paddingLeft:15, paddingRight:15}}>
                <Col sm="12">
                    <Form onSubmit={submit}>
                        <div className="form-group">
                            <label className="label">Voice Pattern Recording *</label>
                            <p><strong>
                                Press the <img src={"../../assets/img/record.svg"} style={{width: "18px", height: "18px"}}/>
                                button, read the {" "}
                                <span style={{
                                    backgroundColor: "rgb(0, 84, 204, 0.05)",
                                    padding: "2px 5px 2px 5px",
                                    border: "0.5px solid #e4e5ee",
                                    borderRadius: 8,
                                    color: "#5c6873"
                                }}>text in the box</span>
                                {" "} below slowly and clearly without any background noise.
                                When done click on the {" "} <img src={"../../assets/img/stoprecord.svg"}
                                                                  style={{width: "18px", height: "18px"
                                                                  }}/> button,
                                press {" "} <img src={"../../assets/img/play.svg"}
                                                 style={{width: "18px", height: "18px"}}/> button to confirm
                                recording. Click {"  "}
                                <span style={{
                                    borderRadius: 8,
                                    backgroundColor: "#0052cc",
                                    color: "#fff",
                                    padding: "2px 5px 2px 5px"
                                }}>Next</span>{"  "} to continue.
                            </strong></p>
                            <textarea autoFocus className="form-control" readOnly style={{
                                height: "160px", fontSize: "14px", resize: "none",
                                backgroundColor: "rgb(0, 84, 204, 0.05)"
                            }}>
                                          In today’s world, working from home is growing more
                                          and more common. But sometimes, it can be helpful to
                                          have space where you can retreat and direct 100% of
                                          your focus to carrying out work tasks. Get connected
                                          in a workspace alongside your entire team, or settle in
                                          for a focused work session in a life like office,
                                          overlooking your favorite cityscape with the
                                          landscapes feature.
                                        </textarea>
                            <div className="form-group d-flex align-items-center"
                                 style={{width: "100%", padding: 10}}>
                                {<AudioRecorder ref={recordRef}
                                                bioMetricUrl={biometricUrl}></AudioRecorder>}
                            </div>
                        </div>
                        <Button type="submit" color="primary" className="btn-submit" style={{display: "flex", justifyContent:"flex-end"}}>
                            Submit
                        </Button>
                    </Form>
                </Col>
            </Row>
        </>
    );
};

export default BioMetrics;
