function pad(num: number, size: number): string {
    let s = num.toString();
    while (s.length < size) s = "0" + s;
    return s;
}
export function toMinutes(timeinSecs: number): string {
    if (timeinSecs || timeinSecs === 0) {
        let time = parseFloat(timeinSecs.toFixed(3));
        let hours = Math.floor(time / 60 / 60);
        let minutes = Math.floor(time / 60) % 60;
        let seconds = Math.floor(time - (minutes * 60) - (hours * 3600));
        return (hours ? hours + ":" : "") + (pad(minutes, 2)) + ':' + pad(seconds, 2);
    }
    return "";
}

export function toMinuteOrHrs(timeinSecs: number): string {
    let duration = "";
    if (timeinSecs) {
        let time = parseFloat(timeinSecs.toFixed(3));
        let hours = Math.floor(time / 60 / 60);
        let minutes = Math.floor(time / 60) % 60;
        let seconds = Math.floor(time - (minutes * 60) - (hours * 3600));

        if (hours) {
            duration += hours + ":";
        }

        if (minutes || hours) {
            duration += pad(minutes, 2) + ":";
        }

        duration += pad(seconds, 2);

        if (!hours && !minutes) {
            duration += " secs";
        } else {
            duration += hours ? " hrs" : " mins";
        }
    }
    return duration;
}

export function toMinuteOrHrsObject(timeinSecs: number): { time: string, desc: String } {
    let duration = "";
    let desc = "";
    if (timeinSecs) {
        let time = parseFloat(timeinSecs.toFixed(3));
        let hours = Math.floor(time / 60 / 60);
        let minutes = Math.floor(time / 60) % 60;
        let seconds = Math.floor(time - (minutes * 60));
        duration = (hours ? hours + "" : "");
        duration = duration + (hours && minutes ? ":" : "") + (minutes ? (pad(minutes, 2)) : "");
        if (!hours && !minutes) {
            desc = "sec";
            if (seconds === 0)
                duration = ("00:00");
            else
                duration = ("00:" + pad(seconds, 2));
        } else {
            desc = (hours ? "hrs" : "mins");
            duration = duration + (hours || minutes ? ":" : "") + (seconds ? (pad(seconds, 2)) : "00");
        }
    } else {
        duration = ("00:00");
        desc = ("mins");
    }
    return {time: duration, desc: desc};
}

const monthNames = [
    "January", "February", "March",
    "April", "May", "June",
    "July", "August", "September",
    "October", "November", "December"
];

export const convertDurationToSeconds = (duration) => {
    const timeComponents = duration.split(':');
    let seconds = 0;
    switch (timeComponents.length) {
        case 3:
            seconds +=  (Number(timeComponents[0]) * 60 * 60) +
                (Number(timeComponents[1]) * 60) +
                (Number(timeComponents[2]));
            break;
        case 2:
            seconds +=  (Number(timeComponents[0]) * 60) +
                (Number(timeComponents[1]));
            break;
        case 1:
            seconds +=  (Number(timeComponents[0]));
            break;
    }

    return seconds;
};

const getMonthName = (date: Date) => {
    return monthNames[date.getMonth()];
};
const getShortMonthName = (date: Date) => {
    return getMonthName(date).substr(0, 3);
};

//Jan 21, Tue | 10:30 AM
export function toFormatedDate(srcdate: string): string {
    if (srcdate) {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const date = new Date(srcdate);
        const month = months[date.getMonth()];
        const day = date.getDate();
        const dayOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][date.getDay()];
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const amPm = hours >= 12 ? "PM" : "AM";
        const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
        const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
        return `${month} ${day}, ${dayOfWeek} | ${formattedHours}:${formattedMinutes} ${amPm}`;
    }
    return "";
}

export function toFormatedDateWithYear(srcdate: string): string {
    if (srcdate) {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const date = new Date(srcdate);
        const year = date.getFullYear(); // Get the full year
        const month = months[date.getMonth()];
        const day = date.getDate();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const amPm = hours >= 12 ? "PM" : "AM";
        const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
        const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
        return `${month} ${day}, ${year}, ${formattedHours}:${formattedMinutes} ${amPm}`; // Include the year in the output
    }
    return "";
}


// MM/DD/YYYY Format
export function toDateOnly(srcdate: string): string {
    if (srcdate) {
        const date = new Date(srcdate);
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Add 1 because months are 0-indexed
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
    }
    return "";
}

//Apr 1, 2023, 11:20AM
export function toStandardFormattedDate(srcdate: string): string {
    if (srcdate) {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const date = new Date(srcdate);
        const month = months[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const amPm = hours >= 12 ? "PM" : "AM";
        const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
        const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
        return `${month} ${day}, ${year}, ${formattedHours}:${formattedMinutes} ${amPm}`;
    }
    return "";
}

export const getLastYearDate = () => {
    const today = new Date();
    const lastYearDate = today.setFullYear(today.getFullYear() - 1);
    return new Date(lastYearDate).toISOString();
}

export const getLastNinetyDaysDate = () => {
    const today = new Date();
    const lastNinetyDaysDate = new Date(today.setDate(today.getDate() - 90));
    return lastNinetyDaysDate.toISOString();
}

export function toMeetingFormatedDate(srcdate: string): string {
    if (srcdate) {
        let date = new Date(srcdate);
        let hr = date.getHours();
        let min = date.getMinutes();
        let year = date.getFullYear();
        let minValue = min + "";
        if (min < 10) {
            minValue = "0" + min;
        }
        var ampm = "AM";
        if (hr >= 12) {
            hr -= 12;
            ampm = "PM";
        }
        return getShortMonthName(date) + " " + date.getDate() + " " + year + ", " + date.toLocaleString('en-us', {weekday: 'short'}) + " " + hr + ":" + minValue + " " + ampm;
    }
    return "";
}

export function toDateMonth(date: string): string {
    const dateFormat = new Date(date)
    return dateFormat && `${getShortMonthName(dateFormat)} ${dateFormat.getDate()}`
}

export function toDateMonthYear(date: string): string {
    const dateFormate = new Date(date)
    return dateFormate && `${dateFormate.getDate()} ${getShortMonthName(dateFormate)} ${dateFormate.getFullYear()}`
}

export function toMinuteOrHrsNumber(timeinSecs: number) {
    let duration = "";
    if (timeinSecs) {
        let time = parseFloat(timeinSecs.toFixed(3));
        let hours = Math.floor(time / 60 / 60);
        let minutes = Math.floor(time / 60) % 60;
        let seconds = Math.floor(time - (minutes * 60));
        duration = (hours ? hours + "" : "");
        duration = duration + (hours && minutes ? "." : "") + (minutes ? (pad(minutes, 1)) : "") + (seconds ? ('.' + (pad(seconds, 2))) : "");
        return parseFloat(duration);
    }
    return 0;
}

export function formatSeconds(seconds) {
    let duration = "";
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

    duration = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    return duration;
}

// Function to convert time in seconds to a decimal value
export function convertSecondsToDecimal(timeInSeconds) {
    const decimalTime = timeInSeconds / 3600;
    return decimalTime.toFixed(1);
}

export function displayDuration(timeinSecs: number): string {
    let duration = "";
    if (timeinSecs) {
        let hours = Math.floor(timeinSecs / 3600);
        let minutes = Math.floor((timeinSecs % 3600) / 60);
        let seconds = timeinSecs % 60;

        if (hours > 0) {
            // For hours, we show hours and minutes (but not seconds)
            duration += `${hours}h `;
            if (minutes > 0) {
                duration += `${minutes}m`;
            }
        } else if (minutes > 0) {
            // For minutes, we show minutes and seconds
            duration += `${minutes}m `;
            if (seconds > 0) {
                duration += `${seconds}s`;
            }
        } else {
            // Only showing seconds
            duration += `${seconds}s`;
        }
    }
    return duration.trim(); // Trim to remove any extra space
}
