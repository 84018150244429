import { IMeeting, ITranscriptInfo, TopicCounts } from "../context/AppStore";
import { DictionaryItem, DictionaryItems } from "../types";
import { findUniques, findUniquesByKey } from "./CollectionUtil";
import { TranscriptInfoInput, MeetingInput, CustomerInfo, getFullMeetingById_meeting_analytics_subtopic_count } from "../graphql/ql/operation-result-types";
export const COLORS = ["color1", "color2", "color3", "color4", "color5", "color6", "color7", "color8", "color9", "color10", "color11", "color12", "color13", "color14", "color15", "success-color-dark",
  "warning-color-dark", "info-color-dark", "primary-color-dark", "danger-color-dark",
  "secondary-color-dark", "default-color-dark", "purple-color-dark", "danger-color", "success-color", "info-color", "warning-color", "default-color", "primary-color", "secondary-color"];

const COLORS_OBJECT = ['#14bb7f', '#ff9900', '#ff4d97', '#917fd8', '#36b7d3', '#298ce8', '#362bb0', '#bb6bd9', '#dc47c4', '#f68e6d', '#63831e', '#a8b700', '#5094ba', '#00695c', '#4f37e3', '#0052cc', '#2d323c', '#002d52','#96b2c9','#334252','#ff4444','#ffbb33','#00C851','#33b5e5','#cc0000','#007e33','#ff8800','#0099cc','#2bbbad','#4285f4','#aa66cc','#00695c','#0d47a1','#9933cc','#4a148c']

export const getColorsForArray = (arraySize: number = 0) => COLORS_OBJECT.slice(0, arraySize);

const development: boolean = process.env.REACT_APP_STAGE === 'development';

export function isDev(): boolean {
  return development;
}
export function visibility(): "hidden" | "-moz-initial" | "inherit" | "initial" | "revert" | "unset" | "collapse" | "visible" | undefined {
  return "visible"
}
export function disabled(): boolean {
  return !development;
}

export const getItemElement = (id: string): Element | null => {
  let el: Element | null = null;
  if (id) {
    el = document.querySelector(
      `[id="${id}" ]`
    );
  }
  return el;
}
export function scrollToView(id: string): boolean {

  let e: Element | null = getItemElement(id);
  if (e !== null) {
    e.scrollIntoView({ behavior: "smooth" });
    return true;
  }
  return false;
}

export function generateColor(): string {
  return "#" + Math.random().toString(16).substr(-6);
}
export function randDarkColor(colors: string[]): string {
  let color: string = "";
  while (color.length === 0 || colors.indexOf(color) > -1) {
    const rand: number = Math.ceil(Math.random() * 3);
    let red: number = 1;
    let green: number = 1;
    let blue: number = 1;
    let seed: number = 255;
    if (rand === 1) {
      seed = Math.ceil(Math.random() * 255);
      red = 1;
      green = .5;
      blue = .25;
    } else if (rand === 2) {
      seed = Math.ceil(Math.random() * 200);
      red = .5;
      green = 1;
      blue = .25;
    } else if (rand === 3) {
      seed = Math.ceil(Math.random() * 255);
      red = .25;
      green = .50;
      blue = 1;
    }
    const r: number = Math.floor(red * seed);
    const g: number = Math.floor(green * seed);
    const b: number = Math.floor(blue * seed);
    color = `rgb(${r},${g},${b})`;
  }
  colors.push(color);
  return color;
}
export const getAttendees = (meeting: IMeeting) => {
  return meeting.attendees ? meeting.attendees.length : 0;
}
export function groupByCategory(data: ITranscriptInfo[]): any {
  if (data == null) { return []; }
  return data.reduce(function (category: DictionaryItems<ITranscriptInfo>, item: ITranscriptInfo): any {
    if (item.subTopics) {
      item.subTopics && item.subTopics.forEach(chunk => {
        if (chunk) {
          (category[chunk] = category[chunk] || []).push(item);
        }
      })
    }
    item.trackers && item.trackers.forEach(chunk => {
      if (chunk) {
        (category[chunk] = category[chunk] || []).push(item);
      }
    });
    item.chunkCategories && item.chunkCategories.forEach(chunk => {
      if (chunk && chunk.category !== null) {
        (category[chunk.category] = category[chunk.category] || []).concat(item);
      }
    });
    return category;
  }, {});
}

export function groupByTracker(data: ITranscriptInfo[]): any {
  if (data == null) { return []; }
  return data.reduce(function (category: DictionaryItems<ITranscriptInfo>, item: ITranscriptInfo): any {
    item.subTopics && item.subTopics.forEach(chunk => {
      if (chunk) {
        (category[chunk] = category[chunk] || []).push(item);
      }
    });
    item.trackers && item.trackers.forEach(chunk => {
      if (chunk) {
        (category[chunk] = category[chunk] || []).concat(item);
      }
    });

    item.chunkCategories && item.chunkCategories.forEach(chunk => {
      if (chunk && chunk.category !== null) {
        (category[chunk.category] = category[chunk.category] || []).concat(item);
      }
    });
    return category;
  }, {});
}

export function groupByTopics(topicRules: any, subtopics: DictionaryItems<any>, subtracker, data: ITranscriptInfo[]): any {
  if (!topicRules) return subtopics;
  let topics: DictionaryItem<DictionaryItems<ITranscriptInfo[]>> = {};
  if (subtopics == null) { return []; }
  topicRules.forEach(item => {
    let topic = topics[item.name] = topics[item.name] || {};
    if (item.topics) {
      item.topics.forEach(key => {
        let subtopic = topic[key] = topic[key] || [];
        if (subtopics[key]) {
          subtopic.push(...subtopics[key])
        }
      })
    }
  })
  return topics;
}

export function getTopicCounts(arr: DictionaryItems<ITranscriptInfo[]>, subtopic_counts:
  (getFullMeetingById_meeting_analytics_subtopic_count | null)[] | null | undefined): TopicCounts {
  let mentionCount: number = 0;
  let topicCount: number = 0;
  let percentageSum: number = 0;
  let durationSum: number = 0;

  if (subtopic_counts) {
    Object.keys(arr).forEach((key: string) => {
      let subtopic = subtopic_counts.find((x) => x?.name === key);
      if (subtopic) {
        let mention = (subtopic.mentions ? subtopic?.mentions : 0);
        if (mention) {
          topicCount++;
          percentageSum = percentageSum + (subtopic.percentage ? subtopic?.percentage : 0);
          durationSum = durationSum + (subtopic.duration ? subtopic?.duration : 0);
        }
        mentionCount = mentionCount + mention;
      }
    });
  }
  return { topicCount: topicCount, mentionCount: mentionCount, percentageSum: percentageSum, durationSum: durationSum };
}

export function getTopicCount(arr: DictionaryItems<ITranscriptInfo[]>, subtopic_counts:
  (getFullMeetingById_meeting_analytics_subtopic_count | null)[] | null | undefined): number {
  let topicCount: number = 0;
  if (subtopic_counts) {
    Object.keys(arr).forEach((key: string) => {
      let subtopic = subtopic_counts.find((x) => x?.name === key);
      if (subtopic) {
        let mention = (subtopic.mentions ? subtopic?.mentions : 0);
        if (mention) {
          topicCount++;
        }
      }
    });
  }
  return topicCount;
}

export function randColor(colors: string[]): string {
  let color: string = "";
  while (color.length === 0 || colors.indexOf(color) > -1) {
    const rand: number = Math.ceil(Math.random() * 12);
    color = COLORS[rand];
    if (colors.length >= 13) {
      color = "balck"
      break;
    }
  }
  colors.push(color);
  return color;
}

export function getColor(index: number): string {
  if (index < COLORS.length) {
    return COLORS[index];
  }
  return "black";
}
export function getCategoryColors(nodes: DictionaryItems<any>): DictionaryItem<string> {
  const items: DictionaryItem<string> = {};
  if (nodes == null) {
    return items;
  }
  Object.keys(nodes).forEach((s, index) => { items[s] = getColor(index); });
  return items;
}

export function getColors(nodes: any[], key: string): DictionaryItem<string> {
  const items: DictionaryItem<string> = {};
  if (nodes == null) {
    return items;
  }
  const groups: string[] = findUniques(nodes, key);
  groups.forEach((s, index) => { items[s] = getColor(index); });
  return items;
}

// OpportunityResult.representative,  then setColors({},OpportunityResult,undefined,"representative")
// OpportunityResult.contacts.contactName,  then  setColors({},OpportunityResult,"contacts","contactName")
export function setColors(colorList:DictionaryItem<string>, nodes: any[] | null | undefined, objectName:string| undefined,key: string): DictionaryItem<string> {
  if (!nodes) {
    return colorList;
  }
  let groups: string[] = [];
  if(objectName) {
    groups = findUniquesByKey(nodes, objectName, key);
  }
  else{
    groups = findUniques(nodes, key);
  }
  let colorIndex = Object.keys(colorList).length;
  if(colorIndex > COLORS.length) {
    colorIndex = 0;
  }
  groups.forEach((s, index) => { 
    if(!colorList[s]) {
      colorList[s] = getColor(colorIndex); 
      colorIndex++;
    }
    if(colorIndex > COLORS.length) {
      colorIndex = 0;
    }
  });
  return colorList;
}

export function toTranscriptInput(selectedTranscript: any): TranscriptInfoInput {
  let transcripts: TranscriptInfoInput = {
    id: selectedTranscript.id,
    start: selectedTranscript.start,
    end: selectedTranscript.end,
    name: selectedTranscript.name,
    text: selectedTranscript.text,
    chunkCategories: selectedTranscript.chunkCategories,
    subTopics: selectedTranscript.subTopics,
    trackers: selectedTranscript.trackers
  };
  return transcripts;
}


export const omitDeep = (value, key) => {
  if (Array.isArray(value)) {
    return value.map(i => omitDeep(i, key));
  } else if (typeof value === 'object' && value !== null) {
    return Object.keys(value).reduce((newObject, k) => {
      if (k === key || k === "id") return newObject;
      return Object.assign({ [k]: omitDeep(value[k], key) }, newObject);
    }, {});
  }
  return value;
};

export const copyDeep = (value) => {
  if (Array.isArray(value)) {
    return value.map(i => copyDeep(i));
  } else if (typeof value === 'object' && value !== null) {
    return Object.keys(value).reduce((newObject, k) => {
      return Object.assign({ [k]: copyDeep(value[k]) }, newObject);
    }, {});
  }
  return value;
};

export function toMeetingtInput(selectedMeeting: any): MeetingInput {
  return omitDeep(selectedMeeting, "__typename");

}

export function toCustomerInput(selectedCustomer: any): CustomerInfo {
  return omitDeep(selectedCustomer, "__typename");

}

export const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const buildURI = ((data, uFEFF, headers, separator, enclosingCharacter) => {
  const type = isSafari() ? 'application/csv' : 'text/csv';
  const blob = new Blob([uFEFF ? '\uFEFF' : '', data], {type});
  const dataURI = `data:${type};charset=utf-8,${uFEFF ? '\uFEFF' : ''}${data}`;

  const URL = window.URL || window.webkitURL;

  return (typeof URL.createObjectURL === 'undefined')
    ? dataURI
    : URL.createObjectURL(blob);
});

export function querystring(name, url = window.location.href) {
  name = name.replace(/[[]]/g, "\\$&");

  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
  const results = regex.exec(url);

  if (!results) {
    return null;
  }
  if (!results[2]) {
    return "";
  }

  return decodeURIComponent(results[2].replace(/\+/g, " "));
}



export const isWebEnabled = (user) => {
  return (user.customer?.providers?.filter(item => item.service === "Web Conferencing")?.length > 0 ||
                              user?.providers?.filter(item => item.service === "Web Conferencing")?.length > 0)
}

export const isDialerEnabled = (user) => {
  return user?.providers?.filter(item => item?.service?.toLowerCase() === "dialer")?.length > 0;
}

export const isCrmEnabled = (user) => {
  return user.customer?.providers?.filter(item => item?.service?.toLowerCase() === "crm")?.length > 0;
}
export const isCalendarEnabled = (user) => {
  return user.providers?.filter(item => item?.service?.toLowerCase() === "calendar")?.length > 0
}

export const isUserComplate = (user) => {
  return (user.firstName && user.lastName && user.designation && user.phone && user.bioMetricUrl)
}