import React, {useEffect, useState} from "react";
import {Button, Col, Form, Input, Label, Row} from "reactstrap";
import axios from 'axios';
import {
    createMeeting,
    createMeetingVariables,
    getAllInProgressMeetings,
    getAllInProgressMeetingsVariables,
    MeetingInput,
} from "../../graphql/ql/operation-result-types";
import useAppContext from "../../hooks/useAppHook";
import {useMutation, useQuery} from "@apollo/client";
import {GET_ALL_IN_PROGRESS_MEETINGS, UPDATE_MEETING} from "../../graphql/ql/schema";
import {toastService} from "../../services/toast.service";
import {postJsonAxiosService} from "../../services/callAxios";

const LiveMeeting = (props) => {
    const [error, setError] = useState("");
    const {user, setUser} = useAppContext();
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [url, setUrl] = useState("");
    const [providerMeetingId, setProviderMeetingId] = useState("");

    const [updateMeeting] = useMutation<createMeeting, createMeetingVariables>(UPDATE_MEETING, {
        async onCompleted(data: createMeeting) {
            if (data != null) {
                props.toggle();
                let result = await addNotetaker(data?.createMeeting?.id).catch(function (error: any): any {
                    toastService.error("Failed to add Rafiki NoteTaker to the call.");
                    console.log(error);
                })

                if (result && result?.status === 200){
                    toastService.info("Invitation was successful. Rafiki NoteTaker will join your call in the next minute or two.");
                }
                else {
                    toastService.info("Invitation was not successful.");
                }
            }
        },
        onError(e: any): void {
            // toastService.error("Failed to add Rafiki NoteTaker to the call");
            console.log(e);
        },
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        let provider = "";
        if (!url.includes("meet.google.com") && !url.includes("zoom.us") && !url.includes("teams.microsoft.com")) {
            setError("Invalid URL. Only Google Meet, Zoom and Teams are supported");
            return;
        }
        if (url.includes("zoom.us"))
            provider = "Zoom";
        else if (url.includes("teams.microsoft.com"))
            provider = "microsoft";
        else if (url.includes("meet.google.com"))
            provider = "google";

        let meetingID = url.split('/');
        setProviderMeetingId(meetingID[meetingID.length - 1] + "_" + user.customer.id);

        let attendees:any = [];
        let attendee: any = {
            name: user?.firstName + " " + user?.lastName,
            email: user?.email,
            role: "organizer",
            status: "accepted",
        };
        attendees.push(attendee);

        let meetingInput: MeetingInput = {
            providerMeetingId: providerMeetingId,
            provider: provider,
            title: title,
            description: description,
            subtitle: title,
            status: "In Progress",
            callType: "Web Conferencing",
            callScope: "External",
            callCategory: "All",
            duration: 0,
            location: url,
            license: "Basic",
            host: user.email,
            owner: user.email,
            customerId: user.customer.id,
            date: new Date(),
            attendees: attendees,
            recordingStatus: true,
            relation: {},
        };

        updateMeeting({variables: {meeting: meetingInput}});
    }

    async function addNotetaker(meetingId) {
        try {
            let meetingObj = {
                meetingId: meetingId,
                url: url,
            };
            const response = await postJsonAxiosService("users/addNotetaker", meetingObj);
            return response; // Return the response
        } catch (error) {
            console.error("Error adding Notetaker:", error);
            throw error; // Rethrow the error to be handled by the caller
        }
    }
    function handleTitleChange(evt) {
        setTitle(evt.target.value);
    }

    function handleDescriptionChange(evt) {
        setDescription(evt.target.value);
    }

    function handleURLChange(evt) {
        let temp = evt.target.value;
        if (!temp.startsWith("http://") && !temp.startsWith("https://")) {
            temp = "https://" + temp;
        }
        setUrl(temp);
        let meetingID = temp.split('/');
        setProviderMeetingId(meetingID[meetingID.length - 1] + "_" + user.customer.id);
    }

    return (
        <>
            {error && <h3 className="error"> {error} </h3>}
            <section className="livemeeting-sec">
                <div className="container">
                    <div className="d-flex align-items-stretch">
                        <div className={"meetingData"} style={{width: "100%"}}>
                            <Form className="loginForm" style={{paddingTop: 0, whiteSpace: "nowrap"}}
                                  onSubmit={handleSubmit}>
                                <Row>
                                    <Col lg={12}>
                                        <Label style={{paddingTop: 10}}>Title</Label>
                                        <Input type="text" autoFocus required
                                               style={{fontSize: "14px", color: "#2d323c"}}
                                               onChange={(e) => handleTitleChange(e)} placeholder="Enter title"/>
                                    </Col>
                                    {/*<div className="col-6">*/}
                                    {/*    <Label style={{paddingTop: 10}}>Subtitle</Label>*/}
                                    {/*    <Input type="text" autoFocus style={{fontSize: "14px", color: "#2d323c"}}*/}
                                    {/*           onChange={(e) => handleSubtitleChange(e)} placeholder="Enter subtitle"/>*/}
                                    {/*</div>*/}
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <Label style={{paddingTop: 10}}>Description</Label>
                                        <Input type="text" autoFocus style={{fontSize: "14px", color: "#2d323c"}}
                                               onChange={(e) => handleDescriptionChange(e)}
                                               placeholder="Enter description"/>

                                        <Label style={{paddingTop: 10}}>Full Meeting URL, eg: Google Meet, Zoom or Teams</Label>
                                        <Input type="text" autoFocus required
                                               style={{fontSize: "14px", color: "#2d323c"}}
                                               onChange={(e) => handleURLChange(e)} placeholder="Enter Meeting URL"/>
                                    </Col>
                                </Row>
                                <div style={{paddingTop: 20}}>
                                    <Button className="btn-submit"
                                            style={{background: "#0052CC", color: "#FFFFFF", float: "right"}}>
                                        Submit
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default LiveMeeting;
