import React, {useRef, useState, useImperativeHandle} from "react";
import {Button, Input, Modal, ModalBody, UncontrolledTooltip} from "reactstrap";
import {useNavigate} from "react-router-dom";
import throttle from "lodash/throttle";
import useBehaviourContext from "../../hooks/useBehaviourHook";
import useAppContext from "../../hooks/useAppHook";
import FilterPanel from "../core/Filters/FilterPanel";
import Select, {components} from "react-select";
import {CREATE_ALERT, GET_MY_ALERTS} from "../../graphql/ql/schema";
import {useMutation, useQuery} from "@apollo/client";
import {
    createAlert,
    createAlertVariables,
    getMyAlerts,
    getMyAlertsVariables
} from "../../graphql/ql/operation-result-types";
import Alert from './AlertModal';
import {toastService} from "../../services/toast.service";
import {pluck} from "../helper";


/* eslint-disable */
const SearchInput = React.forwardRef((props: any, ref) => {
    const {
        setShowSearchPanel,
        searchText,
        setSearchText,
        setMeetingSearchTerms,
        meetingSearchTerms,
        setHideContentPanel,
        setLoading,
        setCurrentLeftTab,
        setFilterTopNav,
        setExtraLeftPanel,
        setHideMeetingPanel,
        setTransSearchText
    } = useBehaviourContext();
    const {filterData, setFilterData, user, setSavedFilter} = useAppContext();
    const [isOpen, setIsOpen] = useState(false);
    const [savedState, setsavedState] = useState(null);
    const [openMsg, updateStatus] = useState(false);
    const textInput = useRef(null);
    const [filterForm, setFilterForm] = useState({});
    const toggle = () => setIsOpen(!isOpen);
    const [isFiltered, setIsFiltered] = useState(false);
    const [isEnableAlert, setIsEnableAlert] = useState(false);
    const [searchObject, setSearchObject] = useState<any>({});
    const history = useNavigate();


    const toggleAlertModal = (alertObject) => {
        setSearchObject(alertObject?.data ? alertObject.data : null);
        setIsEnableAlert(!isEnableAlert)
    };

    const {states = [], id = null} = user || {};

    const searchedStates = states
        ? states.filter((data) => data.type === "FILTER")
        : [];

    const {loading, error, data} = useQuery<getMyAlerts, getMyAlertsVariables>(GET_MY_ALERTS, {
        fetchPolicy: "network-only",
        variables: {userId: id}
    });

    const [createalert] = useMutation<createAlert, createAlertVariables>(
        CREATE_ALERT,
        {
            onCompleted(data: any): void {
                if (data != null) {
                    toastService.info("Alert successfully saved");
                }
            },
            onError(e: any): void {
                console.log(e);
                toastService.error("Error while saving alert settings");
            },
        }
    );

    const objectIds = data?.myAlerts && data.myAlerts.map(pluck("objectId"))

    const getEnableType = (objectId) => {
        const filteredAlert = data?.myAlerts && data.myAlerts.filter(alert => alert?.objectId && alert.objectId === objectId);
        return filteredAlert && filteredAlert.length && filteredAlert[0]?.triggerOn;
    }

    const constructedAlertData = searchedStates && searchedStates.reduce((acc: any, states) => {
        if (objectIds && objectIds.includes(states.id)) {
            const object = {
                ...states,
                isEnabled: (getEnableType(states.id) === 'Disabled') ? false : true,
                enableType: getEnableType(states.id),
                alertObject: data?.myAlerts && data.myAlerts.filter(alert => alert?.objectId && alert.objectId === states.id)[0]
            }
            acc.push(object);
        }
        return acc;
    }, []);

    const options = () =>
        constructedAlertData && constructedAlertData.map((searchData) => ({
            value: searchData?.data ? searchData.data : "",
            name: searchData.name,
            label: <>
                <span id="dditem" style={{paddingRight: "5px"}}>{searchData.name}</span>
                <UncontrolledTooltip placement="bottom" target="dditem">
                    Run Saved Search
                </UncontrolledTooltip>
            </>,
            isEnabled: searchData.isEnabled,
            enableType: searchData.enableType,
            alertObject: searchData.alertObject,
            id: searchData.id,
        }));


    const groupedOptions = [
        {
            label: "My Saved Searches",
            options: options()
        }
    ];
    const {Option} = components;
    const DropdownIndicator = (props) => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <svg className="down-arrow-1"/>
                </components.DropdownIndicator>
            )
        );
    };
    const dot = () => ({
        alignItems: 'center',
        display: 'flex',
    });

    const customStyles = {
        dropdownIndicator: (base, state) => ({
            ...base,
            marginTop: '-5px',
            marginRight: '10px',
            transition: 'all .5s ease',
            transform: state.selectProps.menuIsOpen && 'rotate(180deg)'
        }),
        input: styles => ({...styles, ...dot()}),
        placeholder: styles => ({...styles, ...dot()}),
        control: styles => ({
            ...styles,
            boxShadow: 'none',
            borderRadius: "20px",
            marginLeft: "4px",
            paddingTop: "5px",
            fontSize: 11,
            '&:hover': {
                border: '1px solid grey',
                cursor: 'pointer'
            }
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? "var(--topBannerColor1)" : "white",
            color: state.isFocused ? "var(--mainColor)" : "black",
            '&:hover': {
                backgroundColor: 'var(--topBannerColor2 !important',
                cursor: 'pointer'
            }
        }),
    };

    const IconOption = (props) => {
        return (<Option {...props}>
            {props.data.label}
            <span>
                <img
                    id="editAlert"
                    alt=""
                    onClick={() => toggleAlertModal(props)}
                    src={"../../assets/img/edit.svg"}
                    style={{
                        float: "right",
                        width: "18px",
                        height: "16px",
                        display: "block",
                        cursor: "pointer",
                        marginLeft: "10px"
                    }}
                />
                <UncontrolledTooltip placement="bottom-start"
                                     target="editAlert">Manage Saved Search & Alert</UncontrolledTooltip>
            </span>
            <span>
                <img
                    id="alertMsg"
                    alt=""
                    src={props.data.isEnabled ? "../../assets/img/alert_enabled.svg" : "../../assets/img/alert_disabled.svg"}
                    style={{
                        float: "right",
                        width: "18px",
                        height: "16px",
                        display: "block",
                        cursor: "auto",
                    }}
                />
            </span>

        </Option>)
    }

    const handleOnInputChange = (event) => {
        let meetings = [];
        if (event.keyCode === 13) {
            if (!event.target.value || event.target.value.length > 1) {
                setSearchText(event.target.value);
                if (true
                    //event.target.value ||
                    //(filterData instanceof Object && Object.keys(filterData).length)
                ) {
                    handleSearch(event.target.value);
                    setFilterTopNav(true);
                } else {
                    updateStatus(true);
                    setFilterTopNav(false);
                }
            }
        }
    };

    const handleSearch = throttle((val) => {
        setCurrentLeftTab("1");
        history(`/meetings`);
        setSearchText(val);
        setMeetingSearchTerms(
            meetingSearchTerms
                ? {...meetingSearchTerms, searchText: val}
                : {searchText: val}
        );
        setShowSearchPanel(val && val.length > 0);
        setExtraLeftPanel("");
        setHideMeetingPanel(true);
    }, 250);

    const clearSearch = () => {
        setSearchText("");
        if (textInput && textInput.current) {
            (textInput.current as any).value = "";
        }
        setsavedState(null);
        if (props.clearSearchCallBack) {
            props.clearSearchCallBack();
        }
    };
    useImperativeHandle(ref, () => ({
        clear: clearSearch
    }));

    const handleSearchSelection = (data) => {
        setsavedState(data);
        if (data.value) {
            const appliedFilters = JSON.parse(data.value);
            if (appliedFilters instanceof Object) {
                const {
                    searchText,
                    callType,
                    callScope,
                    callDisposition,
                    leadSource,
                    providers,
                    hostEmails,
                    teams,
                    dateMax,
                    dateMin,
                    hosts,
                    durationMax,
                    durationMin,
                    stageName,
                    crmFilter,
                    metricsFilter,
                    topics,
                } = appliedFilters;
                const defaultFilters = {
                    callType: callType || null,
                    callScope: callScope || null,
                    callDisposition: callDisposition || null,
                    leadSource: leadSource || null,
                    providers: providers || null,
                    hostEmails: hostEmails || null,
                    teams: teams || null,
                    dateMax: dateMax || null,
                    dateMin: dateMin || null,
                    hosts: hosts && hosts.length ? hosts : null,
                    durationMax: durationMax || null,
                    durationMin: durationMin || null,
                    stageName: stageName || "",
                    topics: topics && topics.length ? hosts : null,
                    metricsFilter:
                        metricsFilter && Object.keys(metricsFilter).length
                            ? metricsFilter
                            : null,
                    crmFilter:
                        crmFilter && Object.keys(crmFilter).length ? crmFilter : null,
                };
                const defaultFiltersValue = Object.values(defaultFilters);
                const validFilters = defaultFiltersValue.filter((value) => value);
                setSearchText(searchText || "");
                if (textInput && textInput.current) {
                    (textInput.current as any).value = searchText || "";
                }
                let temp = {...defaultFilters, searchText};
                setMeetingSearchTerms(temp);
                setSavedFilter(true);
                if (searchText || validFilters.length) {
                    setFilterTopNav(true);
                } else {
                    updateStatus(true);
                    setFilterTopNav(false);
                }
            }
        }
    };

    function ModalFilter(props) {
        return (
            <FilterPanel
                isFiltered={isFiltered}
                setIsFiltered={setIsFiltered}
                isOpen={props.isOpen}
                toggle={props.toggle}
                filterForm={filterForm}
                setFilterForm={setFilterForm}
            />
        );
    }

    return (
        <>
            {isEnableAlert ? <Alert
                isOpen={isEnableAlert}
                toggle={toggleAlertModal}
                searchObject={searchObject}
                handleSearchSubmit={createalert}
                user={user}
            /> : null}
            <div className={isFiltered ? "activeSeachFilter nav-search" : "nav-search"}>
                <span className="search-icon">
                  <img src={"../../assets/img/search.svg"}/>
                </span>
                <Input
                    type="search"
                    onKeyDown={handleOnInputChange}
                    onChange={(e) => {
                        // setSearchText(e.target.value);
                    }}
                    className="nav-search-input borderless"
                    width="105%"
                    innerRef={textInput}
                    placeholder="Search meetings, keywords, speakers, topics"
                ></Input>
                {/*<div className="nave-search-action">*/}
                <div className={isFiltered ? "activeProperty clearfilter-icon-topnav" : "clearfilter-icon-topnav"}
                     onClick={() => {
                         if (textInput && textInput.current) {
                             (textInput.current as any).value = "";
                         }
                         setSearchText("");
                         setFilterForm({});
                         setFilterData(filterData);
                         setMeetingSearchTerms(null);
                         setShowSearchPanel(false);
                         setIsFiltered(false);
                         setsavedState(null);
                         setHideMeetingPanel(false);
                         setTransSearchText("");
                         if (props.clearSearchCallBack) {
                             props.clearSearchCallBack();
                         }
                     }} id="clearFilter">
                </div>
                <UncontrolledTooltip placement="bottom-end" target="clearFilter">
                    Clear all filters
                </UncontrolledTooltip>
            </div>
            <FilterPanel
                isFiltered={isFiltered}
                setIsFiltered={setIsFiltered}
                isOpen={isOpen}
                toggle={toggle}
                filterForm={filterForm}
                setFilterForm={setFilterForm}
                clearSearch={clearSearch}
            />
            {openMsg ? (
                <Modal
                    size="sm"
                    centered={true}
                    isOpen={openMsg}
                    toggle={() => updateStatus(false)}
                    placement="center"
                >
                    <ModalBody className="modal-content-search">
                        <span style={{paddingBottom: 40}}>No Results Found</span>
                        <Button color="primary" onClick={() => updateStatus(false)}>
                            OK
                        </Button>
                    </ModalBody>
                </Modal>
            ) : null}
            <div className="nav-search-select">
                <Select
                    key={"select-topsearch"}
                    id="select-topsearch"
                    isMulti={false}
                    name="select-topsearch"
                    placeholder={
                        <span className="span-label" style={{marginTop: "-3px"}}>
                            SAVED
                        </span>
                    }
                    className="nave-search-select"
                    classNamePrefix="nave-search-select"
                    components={{Option: IconOption, DropdownIndicator}}
                    options={groupedOptions}
                    styles={customStyles}
                    //  Check this
                    // title={"Run Saved Search"}
                    value={savedState}
                    onChange={(value) => handleSearchSelection(value)}
                />
            </div>
        </>
    );
});


// export const Search = withRouter<any, any>(SearchInput);
export const Search = SearchInput;