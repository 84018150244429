import useAppContext from "./hooks/useAppHook";
import React, {useEffect} from "react";
import {Navigate, useLocation} from "react-router-dom";
import {useLazyQuery} from '@apollo/client';
import {login, loginVariables} from "./graphql/ql/operation-result-types";
import {LOGIN} from "./graphql/ql/schema";
import {callAxiosServiceWithParam} from "./services/callAxios";
import { setAuthToken } from "./App";

const RequireAuth = ({children}) => {
    const {user, setUser, restrictedToken, token, setToken} = useAppContext();
    const {pathname, search} = useLocation();
    const [executeLogin, {
        called,
        loading,
        data,
        error
    }] = useLazyQuery<login, loginVariables>(LOGIN, {fetchPolicy: "network-only"});
    useEffect(() => {
        if (data && data.login?.token) {
            localStorage.setItem("token", data.login?.token);
            localStorage.setItem("userName", data.login.user?.login?.userName ? data.login.user?.login?.userName : "");
            let url = data.login?.user?.customer?.id + "/profile/" + data.login?.user?.id;
            callAxiosServiceWithParam("users/signedurl", "filepath=" + encodeURIComponent(url)).then((filePath => {
                if (data.login?.user) {
                    data.login.user.photoUrl = filePath.data;
                }
            })).catch((e) => {
            });
            setToken(data.login?.token);
            setUser(data.login.user);
        }
    }, [data, loading]);

    if (loading) return <>Authenticating</>;
    let url: any = `${pathname}${search}`;
    if (pathname && pathname === "/") {
        url = null;
    }
    if (error) {
        localStorage.setItem("token", "");
        localStorage.setItem("userName", "");
        setToken("");
        return <Navigate replace to={url ? `/login?redirect=${url}` : `/login`}/>;
    }
    let auth = restrictedToken || token || localStorage.getItem("token");
    if (auth && (!user || !user.id)) {
        if (!loading && (!user || !user.id)) { //&& children.props.name !== "Home") {
            if (!called) {
                setAuthToken(auth);
                executeLogin({variables: {"userName": "", "password": ""}})
            }
        }
        if (data && data.login?.token) {
            if (!localStorage.getItem("token")) {
                return <Navigate replace to={url ? `/login?redirect=${url}` : `/login`}/>;
            }
            return children;
        } else if (!loading && called) {
            if ((!data || ((data && !data.login?.token)))) {
                return <Navigate replace to={url ? `/login?redirect=${url}` : `/login`}/>;
            }
            return children;
        }
    } else {
        if (!user || !user.id) {
            return <Navigate to={url ? `/login?redirect=${url}` : `/login`}/>;
        } else {
            return children;
        }
    }
    return <></>;
};

export default RequireAuth