import React, {useState} from "react";
import {Button, Input, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {useMutation} from "@apollo/client";
import {UPDATE_PROVIDER} from "../../graphql/ql/schema";
import {useAppContext} from "../../hooks";
import { postJsonAxiosService } from "../../services/callAxios";
import { toastService } from "../../services/toast.service";

const TwilioModal = (props) => {
    const {user} = useAppContext();
    const {provider, toggle} = props;
    const [accountSID, setAccountSID] = useState("");
    const [authToken, setAuthToken] = useState("");
    const [phone, setPhone] = useState("");
    const [errorAccountSID, setErrorAccountSID] = useState(false);
    const [errorAuthToken, setErrorAuthToken] = useState(false);
    const [errorPhone, setErrorPhone] = useState(false);

    const handleSubmit = async (values) => {
        if(!phone) {
            setErrorPhone(true);
        }
        if(!accountSID) {
            setErrorAccountSID(true);
        }
        if(!authToken) {
            setErrorAuthToken(true);
        }
        if(errorAccountSID || errorAuthToken || errorPhone) {
            return;
        }
        let provider = {
            providerId: phone,
            accountId: accountSID,
            accessToken: authToken,
            provider: "twilio",
            service:"dialer"
        }
        try {
            let response = await  postJsonAxiosService("twilio/webhook/validate",{provider:provider});
            if(response) {
                if(!user.providers || user.providers.length == 0) {
                    user.providers = [];
                }
                user.providers.push(provider);
                toastService.info("Twilio Integrated");
                window.location.reload();
            }
            else {
                toastService.error("Invalid Account SID, Auth Token or Phone number.");
            }
        }
        catch(e) {
            toastService.error("Error while updating integration");
        }
    };

    return (
        <div style={{padding: 20}}>
            <ModalHeader toggle={props.toggle}
                         style={{fontSize: 24, fontWeight: 600, border: "none"}}>
                <span>Add Twilio Integration</span>
            </ModalHeader>
            <ModalBody>
                <div>
                    <div className="snippet-desc" style={{fontSize: 14, color: "#202124"}}>
                        <label>Account SID</label>
                        <Input style={{fontSize: 14, color: "#202124"}}
                               placeholder="Enter Account SID"
                               value={accountSID}
                               onChange={(e) => {
                                   setAccountSID(e.target.value);
                                   setErrorAccountSID(false);
                               }}
                        />
                        {errorAccountSID ? (
                            <div className="snippet-desc-error" style={{color: "red"}}>
                                Please enter Account SID
                            </div>
                        ) : null}
                    </div>
                    <div className="snippet-desc" style={{fontSize: 14, color: "#202124", paddingTop:20}}>
                        <label>Auth Token</label>
                        <Input style={{fontSize: 14, color: "#202124"}}
                               placeholder="Enter Auth Token"
                               value={authToken}
                               onChange={(e) => {
                                   setAuthToken(e.target.value);
                                   setErrorAuthToken(false);
                               }}
                        />
                        {errorAuthToken ? (
                            <div className="snippet-desc-error" style={{color: "red"}}>
                                Please enter Auth Token
                            </div>
                        ) : null}
                    </div>
                    <div className="snippet-desc" style={{fontSize: 14, color: "#202124", paddingTop:20}}>
                        <label>Twilio Number for Outgoing Calls</label>
                        <Input style={{fontSize: 14, color: "#202124"}}
                               placeholder="Enter outgoing number in the format 13435555555 "
                               value={phone}
                               onChange={(e) => {
                                   setPhone(e.target.value);
                                   setErrorPhone(false);
                               }}
                        />
                        {errorPhone? (
                            <div className="snippet-desc-error" style={{color: "red"}}>
                                Please enter Twilio Phone Number
                            </div>
                        ) : null}
                    </div>
                </div>

            </ModalBody>
            <ModalFooter style={{border: "none", padding: "0px 0px 10px 30px"}}>
                <Button outline color="primary" onClick={props.toggle}>
                    Cancel
                </Button>{" "}
                <Button color="primary" onClick={handleSubmit}>
                    Authorize
                </Button>{" "}
            </ModalFooter>
        </div>
    )
};

export default TwilioModal;
