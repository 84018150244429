import React from 'react';
import App from './App';
import {createRoot} from 'react-dom/client';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css'
import './App.scss';
import './index.css'; // scroll fix

const container = document.getElementById('root');
if (container !== null) {
    const root = createRoot(container);
    root.render(
        <React.StrictMode>
            <App/>
        </React.StrictMode>
    );
}
serviceWorker.unregister();

