import React from "react";
import { 
  Toast, ToastHeader, ToastBody,
  ListGroup, ListGroupItem,
  Row, Col,
  Progress,
} from 'reactstrap';

interface DownloadToastProps {
  open?: boolean;
  onClose?: (e) => void;
  title?: string;
  files: {file: File, error: string, uploading: boolean, uploadPercent: number}[]
}

const  DownloadToast = (props: DownloadToastProps) => {
  const { 
    open, 
    onClose, 
    title,
    files,
  } = props;

  const renderListItem = (props: { file: File, error: string, uploading: boolean, uploadPercent: number }) => {
    const { file, error, uploading, uploadPercent } = props;

    return (
      <ListGroupItem>
        <Row style={{ padding: 8, paddingLeft: 4, paddingRight: 4 }}>
          <Col>
            {file.name}
          </Col>
          <Col xs="2" style={{ position: 'relative' }}>
            { uploading ? 
              <div style={{ position: 'absolute', right: 15, width: 30 }}>
              <Progress
                value={uploadPercent}
                color="purple" style={{ height: '6px', marginTop: 4 }}
              /> 
            </div>
              : (uploadPercent === 100 && !error ) ? 
              // Success icon
              <div style={{ position: 'absolute', right: 20 }}>
                <svg className={'green_checkmark'}/>
              </div>
              : 
              // Fail icon
              <div style={{ position: 'absolute', right: 20 }}>
                <svg className={'warning'}/>
              </div>
            }
          </Col>
        </Row>
        { error && <p style={{ color: "red" }}>{error}</p>}
      </ListGroupItem>
    )
  }

  return (
    <Toast isOpen={open} style={{ position: 'absolute', maxHeight: 300, top: 'unset', bottom: 15 , right: 15}}>
      <ToastHeader toggle={onClose}>
        {title && 'Uploading Files'}
      </ToastHeader>
      <ToastBody style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: 250 }}>
        <ListGroup>
          { files.map(renderListItem) }
        </ListGroup>
      </ToastBody>
    </Toast>
  )
}

export default DownloadToast