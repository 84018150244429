import React, { useState, useEffect } from "react"
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { DELETE_ALERT, DELETE_STATE, EDIT_ALERT, EDIT_STATE, GET_USERS_BY_COMPANY, GET_TEAMS } from "../../graphql/ql/schema";
import { useMutation, useQuery } from "@apollo/client";
import {
    AlertInput,
    deleteAlert,
    deleteAlertVariables,
    deleteState,
    deleteStateVariables,
    editAlert,
    editAlertVariables,
    editState,
    editStateVariables,
    getUsernames, getUsernamesVariables, teams, teamsVariables
} from "../../graphql/ql/operation-result-types";
import { toastService } from "../../services/toast.service";
import useAppContext from "../../hooks/useAppHook";


const AlertModal = (props) => {
    const { toggle, user, searchObject, setSearchObject } = props;
    const [inputText, setInputText] = useState("");
    const [errorText, setError] = useState("");
    const [inputCheckbox, setInputCheckBox] = useState(searchObject?.enableType);
    // const [host, setHost] = useState(JSON.parse(searchObject?.value)?.hosts)
    // const appliedFilters = JSON.parse(searchObject?.value);

    const host = JSON.parse(searchObject?.value)?.hosts ? JSON.parse(searchObject?.value)?.hosts : null;
    const team = JSON.parse(searchObject?.value)?.teams ? JSON.parse(searchObject?.value)?.teams : null;

    const { user: users } = useAppContext();


    // useEffect(() => {
    //     setHost(JSON.parse(searchObject?.value)?.hosts ?? null);
    // },[searchObject])

    const { loading, error, data: userData } = useQuery<getUsernames, getUsernamesVariables>(GET_USERS_BY_COMPANY, {
        fetchPolicy: "network-only",
        // @ts-ignore
        variables: { customerId: users.customer.id },
    });

    const usersByCustomer: any = userData?.usersByCustomer;
    const hosts = (host && usersByCustomer) ? usersByCustomer.filter(user => user.id === host[0]) : [];
    const hostName = hosts?.length > 0 ? `${hosts[0]?.firstName} ${hosts[0]?.lastName}` : '';


    const { data }: any = useQuery<teams, teamsVariables>(GET_TEAMS, {
        fetchPolicy: "network-only",
        variables: { customerId: users.id },
    });

    const teamData = data?.teams;
    const teams = (team && teamData) ? teamData.filter(el => el.id === team[0]) : [];
    const teamName = teams?.length > 0 ? `${teams[0]?.name}` : '';




    const [editalert] = useMutation<editAlert, editAlertVariables>(
        EDIT_ALERT,
        {
            onCompleted(data: any): void {
                if (data != null) {
                    toastService.info("Alert successfully edited");
                }
            },
            onError(e: any): void {
                console.log(e);
                toastService.error("Error while edting alert settings");
            },
        }
    );
    const [editstate] = useMutation<editState, editStateVariables>(
        EDIT_STATE,
        {
            onCompleted(data: any): void {
                if (data != null) {
                    toastService.info("Search filters successfully updated");
                    searchObject.name = data.editState?.name;
                    searchObject.isEnabled = inputCheckbox === 'Disabled' ? false : true;
                    searchObject.enableType = inputCheckbox;
                    searchObject.value = data.editState?.data;
                    let state = "Active";
                    if (inputCheckbox === "Disabled")
                        state = "InActive";
                    const alert: AlertInput = {
                        customerId: user.customer.id,
                        userId: user.id,
                        name: inputText,
                        objectId: data?.editState?.id,
                        objectType: "State",
                        state: state,
                        triggerOn: inputCheckbox,
                        triggerAt: "0 18 * * 1-5 (America/Los_Angeles)",
                    };
                    editalert({ variables: { id: searchObject?.alertObject?.id, alert: alert } });
                    toggle();
                }
            },
            onError(e: any): void {
                console.log(e);
                toastService.error("Error while saving filter settings");
            },
        }
    );

    const onButtonClick = () => {
        const { alertObject } = searchObject;
        let newState = "Active";
        if (inputCheckbox === "Disabled") {
            newState = "InActive";
        }
        if (inputText && inputText !== searchObject?.name) {
            let stateData = {
                userId: user.id,
                name: inputText,
                type: "FILTER",
                data: searchObject?.value,
            };
            editstate({ variables: { id: searchObject?.id, state: stateData } });
        } else {
            const alert = {
                customerId: user.customer.id,
                userId: user.id,
                name: searchObject?.alertObject.name,
                objectId: alertObject.objectId,
                objectType: "State",
                state: newState,
                triggerOn: inputCheckbox,
                triggerAt: "0 18 * * 1-5 (America/Los_Angeles)",
            };
            editalert({ variables: { id: searchObject?.alertObject?.id, alert: alert } });
            toggle();
        }
    };

    const capitalize = (text) => text.charAt(0).toUpperCase() + text.slice(1);


    const renderObject = (searchObj) => {
        return Object.keys(searchObj).map((obj, i) => {
            const value = JSON.stringify(searchObj[obj]);
            if (obj === 'hosts' || obj === 'teams') return <></>;
            if (typeof searchObj[obj] === 'object' && Object.keys(searchObj[obj]).length === 0) return <></>;
            if (!Array.isArray(searchObj[obj]) && typeof searchObj[obj] === 'object' && Object.keys(searchObj[obj]).length > 0) return renderObject(searchObj[obj]);
            if (Array.isArray(searchObj[obj])) return (searchObj[obj].length > 0 ? <div ><strong>{`${capitalize(obj)}: `}</strong>{value.replace(/[^a-zA-Z0-9]/g, '')} </div> : <></>)
            if (searchObj[obj] !== ('' || {})) return <div ><strong>{`${capitalize(obj)}: `}</strong>{value.replace(/[^a-zA-Z0-9]/g, '')} </div>;
            else return <></>;
        })
    }

    const [deleteAlert] = useMutation<deleteAlert, deleteAlertVariables>(
        DELETE_ALERT,
        {
            onCompleted(data: any): void {
                if (data != null) {
                    toastService.info("Saved search & alert deleted successfully ");
                }
            },
            onError(e: any): void {
                console.log(e);
                toastService.error("Error while deleting saved search");
            },
        }
    );

    const [deleteState] = useMutation<deleteState, deleteStateVariables>(
        DELETE_STATE,
        {
            onCompleted(data: any): void {
                deleteAlert({ variables: { "id": searchObject?.alertObject?.id } });
            },
            onError(e: any): void {
                console.log(e);
                toastService.error("Error while deleting saved search");
            },
        }
    );

    return (
        <Modal
            className="meeting-modal-wrapper"
            isOpen={props.isOpen}
            centered={true}
            toggle={props.toggle}
            placement="auto-end"
            target={props.targetId}
        >
            <ModalHeader
                toggle={props.toggle}
                charCode="x"
                style={{ border: "none", paddingBottom: 10, paddingLeft: 30 }}
            >
                <span style={{ fontSize: "18px", paddingLeft: "0px" }}>
                    {`Manage Saved Search: ${searchObject?.name ? searchObject.name : ""}`}
                </span>
            </ModalHeader>
            <ModalBody>
                <div className="saveAlertModal">
                    <label>Name</label>
                    <Input style={{ fontSize: "14px", color: "#2d323c" }}
                        defaultValue={inputText || searchObject?.name}
                        onChange={(e) => {
                            setError("");
                            setInputText(e.target.value);

                        }}
                    />
                    {errorText ? (
                        <div className="snippet-name-error">{errorText}</div>
                    ) : null}
                </div>

                <FormGroup style={{ marginLeft: 18 }}>
                    <label>Search Criteria</label>
                    <div style={{ marginTop: 10, border: 'solid 0.5px #e4e5ee', padding: '10px', borderRadius: '8px', width: "88%" }}>
                        {hostName ? <div ><strong>Hosts:&nbsp;</strong>{hostName} </div> : <></>}
                        {teamName ? <div ><strong>Teams:&nbsp;</strong>{teamName} </div> : <></>}
                        {
                            renderObject(JSON.parse(searchObject?.value))
                        }
                        {/* <Input readonly name="filters" id="filters"
                               value={JSON.stringify(JSON.parse(searchObject?.value))}
                               style={{ width: "350px", height: "60px", fontSize: 14, color: "#2d323c"}}  >
                        </Input> */}
                        {/* <Button color="primary" style={{ marginLeft: "16px", marginTop: "10px" }}
                            onClick={(e) => { }}>Edit Filter</Button> */}
                    </div>
                </FormGroup>
                <div className="saveAlertModal">
                    <label>ALERT SCHEDULE</label>
                </div>
                <div className="saveAlertModal">
                    <label className="saveAlertModal">
                        <Input
                            type="radio"
                            name="disabled"
                            id="0"
                            value="Disabled"
                            onClick={(e) => setInputCheckBox(e.currentTarget.value)}
                            checked={inputCheckbox === "Disabled"}
                        />{" "}
                        Disabled
                    </label>
                    <label className="saveAlertModal">
                        <Input
                            type="radio"
                            name="disabled"
                            id="1"
                            value="Daily"
                            onClick={(e) => setInputCheckBox(e.currentTarget.value)}
                            checked={inputCheckbox === "Daily"}
                        />{" "}
                        Daily
                    </label>
                    <label className="saveAlertModal">
                        <Input
                            type="radio"
                            name="disabled"
                            id="2"
                            value="Weekly"
                            onClick={(e) => setInputCheckBox(e.currentTarget.value)}
                            checked={inputCheckbox === "Weekly"}
                        />{" "}
                        Weekly
                    </label>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button variant="warning"
                    style={{ borderColor: "red", background: "#fff", color: "red", }}
                    onClick={() => {
                        toggle();
                        deleteState({ variables: { "id": searchObject?.id } });
                    }}>Delete</Button>{'   '}
                <Button outline
                    color="primary"
                    style={{
                        borderColor: "#0052CC", background: "#fff",
                        color: "#0052cc",
                    }}
                    onClick={() => {
                        toggle();
                    }}
                >
                    Cancel
                </Button>{'   '}
                <Button
                    color="primary"
                    style={{ background: "#0052cc", color: "#fff" }}
                    onClick={() => onButtonClick()}
                    className={inputCheckbox === 'Weekly' || 'Daily' ? "" : "disabled"}
                >
                    Save
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default AlertModal;