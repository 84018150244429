import React, {useState, useRef} from "react";
import {Button, Col, Input, Label, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {
    createMeeting,
    createMeetingVariables,
    editMeeting,
    editMeetingVariables,
    MeetingInput
} from "../../graphql/ql/operation-result-types";
import useAppContext from "../../hooks/useAppHook";
import {useMutation} from "@apollo/client";
import {EDIT_MEETING, UPDATE_MEETING} from "../../graphql/ql/schema";
import {toastService} from "../../services/toast.service";
import uuid from "react-uuid";
import {postAxiosService, postJsonAxiosService} from "../../services/callAxios";
import { UploadContext } from "../../context/AppStore";
import { useContext } from "react";

const UploadMeeting = (props) => {
    const {user} = useAppContext();
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [organizerName, setOrganizerName] = useState(user?.firstName + " " + user?.lastName || "");
    const [organizerEmail, setOrganizerEmail] = useState(user.email || "");
    const [files, setFiles] = useState<Array<any>>([]);
    const [sources, setSources] = useState<Array<string>>([]);
    const [errorTitle, setErrorTitle] = useState(false);
    const [errorOrganizerName, setErrorOrganizerName] = useState(false);
    const [errorOrganizerEmail, setErrorOrganizerEmail] = useState(false);
    const [errorAttendees, setErrorAttendees] = useState(false);
    const [errorFiles, setErrorFiles] = useState(false);
    const [attendee, setAttendee] = useState("");
    const [attendees, setAttendees] = useState<Array<string>>([]);
    const [isUploading, setIsUploading] = useState(false);

    const { isUploading: isUploadingMeetingFiles, uploadMeetingFiles } = useContext(UploadContext);

    const [updateMeeting] = useMutation<createMeeting, createMeetingVariables>(UPDATE_MEETING, {
        async onCompleted(data: createMeeting) {
            if (data != null) {
                return data?.createMeeting?.id;
            }
        },
        onError(e: any): void {

        },
    });

    const [editMeeting] = useMutation<editMeeting, editMeetingVariables>(EDIT_MEETING, {
        async onCompleted(data: editMeeting) {
            if (data != null) {
                return data?.editMeeting?.id;
            }
        },
        onError(e: any): void {

        },
    });

    const fileSelectedHandler = (e) => {
        let fileAttachments: any[] = [];
        let filenames: any[] = [];
        // let newFiles: DictionaryItem<String> = {};
        if (sources.length > 0)
            fileAttachments = fileAttachments.concat(sources);
        if (files.length > 0)
            filenames = filenames.concat(files);
        var largeFile =  false
        var clonedFiles = [...e.target.files]
        const dt = new DataTransfer()

        clonedFiles = clonedFiles.filter((file,index) => {
            if (file.size > 3e8) {
                largeFile = true;
                return false
            }
            dt.items.add(file)
            return true
        });
        e.target.files = dt.files
        clonedFiles.forEach(file => {
            let fileAttachment = {
                name: file.name,
                fileType: file.type,
                size: file.size,
            };
            fileAttachments.push(fileAttachment);
            filenames.push(file);
        });
        setSources(fileAttachments);
        setFiles(filenames);
        if (largeFile) {
            window.alert("Please upload a file smaller than 300 MB");
            return;
        }
        setErrorFiles(false);
    }

    const removeFiles = (filesIndex: number) => {
        let index = files.splice(filesIndex, 1);
        let index1 = sources.splice(filesIndex, 1);
        setFiles([...files]);
        setSources([...sources]);
    }

    function setupAttendees() {
        let meetingAttendees: any[] = [];
        meetingAttendees.push({
            name: organizerName,
            email: organizerEmail,
            role: "organizer",
            status: "accepted"
        });
        attendees.forEach(att => {
            if ( !validateEmail(att["email"])) {
                setErrorAttendees(true);
                return;
            }
            let temp = {
                name: att["name"],
                email: att["email"],
                role: "attendees",
                status: "accepted"
            };
            meetingAttendees.push(temp);
        });

        return meetingAttendees;
    }

    function splitFilenameAndExtension(filename) {
        const lastDotIndex = filename.lastIndexOf('.');
        if (lastDotIndex === -1 || lastDotIndex === 0) {
            return { name: filename, extension: '' };
        }
        return {
            name: filename.substring(0, lastDotIndex),
            extension: filename.substring(lastDotIndex + 1),
        };
    }


    const handleAttendeeSelected = (): void => {
        if (attendee) {
            let nameAndEmail = attendee.split(",");
            if (nameAndEmail.length < 2 || !validateEmail(nameAndEmail[1]) ||
                nameAndEmail[0].trim() === "" || nameAndEmail[1].trim() === "" ||
                validateEmail(nameAndEmail[0])) {
                setErrorAttendees(true);
                return;
            }

            let temp = {
                name: nameAndEmail[0].trim(),
                email: nameAndEmail[1].trim(),
                role: "attendees",
                status: "accepted"
            };

            if (attendees.findIndex((key) => key === attendee) === -1) {
                const att1: any = [...attendees, attendee];
                setAttendees(att1);
            }
        }
    }

    const removeAttendee = (attendeeIndex: number) => {
        let index = attendees.splice(attendeeIndex, 1);
        setAttendees([...attendees]);
    }

    // const submit = (e: React.FormEvent<HTMLFormElement>) => {
    //     e.preventDefault();
    //     e.stopPropagation();
    //     const form = e.currentTarget;
    //     const data = new FormData(form);
    //     handleSubmit(data);
    // }

    function validateEmail(e) {
        var filter = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
        return String(e).search(filter) != -1;
    }

    const handleSubmit = async () => {
        if (!title) {
            setErrorTitle(true);
            return;
        } else if (sources.length === 0) {
            setErrorFiles(true);
            return;
        } else if (!organizerName) {
            setErrorOrganizerName(true);
            return;
        } else if (!organizerEmail || !validateEmail(organizerEmail)) {
            setErrorOrganizerEmail(true);
            return;
        }
        if (attendees.length >= 1) {
            const hasInvalidEmail = attendees.some(att => !validateEmail(att["email"]));
            if (hasInvalidEmail) {
                setErrorAttendees(true);
                return;
            }
        }
        setIsUploading(true);
        let inputData = {
            "checkType": "isDownloadAllowed",
            "provider": "Uploaded",
            "meetingId": null,
            "downloadUrl": null,
        }
        let data1 = await postJsonAxiosService("users/checkLicenseConfig?user=" + organizerEmail, inputData);
        if (data1.data) {

        } else {
            toastService.error("Exhausted storage limits for current license. Please upgrade to be able to upload/record meetings.");
        }

        let meetingAttendees = setupAttendees();
        let externalAttendees = true;
        // let domain = organizerEmail?.split("@")[1];
        // attendees.forEach(att => {
        //     let participantDomain = att["email"]?.split("@")[1];
        //     if (domain !== participantDomain) {
        //         externalAttendees = true;
        //         return;
        //     }
        // });

        let meetingInput: MeetingInput = {
            providerMeetingId: uuid(),
            provider: "Uploaded",
            title: title,
            description: description,
            subtitle: title,
            status: "Recorded",
            host: organizerEmail,
            owner: organizerEmail,
            customerId: user.customer.id,
            license: "Basic",
            duration: 0,
            date: new Date(),
            recordingStatus: true,
            callType: "Web Conferencing",
            callScope: "External",
            relation: {},
            sources: [],
            attendees: meetingAttendees,
        };
        updateMeeting({variables: {meeting: meetingInput}}).then(async (data) => {
            if (data?.data?.createMeeting?.id) {
                uploadMeetingFiles(files, data?.data?.createMeeting?.id, user.id, user.customer.id)
                props.toggle();
                setIsUploading(false);
            } else {
                toastService.error("Failed to create");
            }
        })
            .catch((error) => {
                console.error('Error occurred:', error);
            });
    }

    const toTitleCase = (str) => {
        return str.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    };

    const processAttendeesInput = (inputValue) => {
        // Split the input by comma to get individual emails
        const emails = inputValue.split(',').map(email => email.trim());
        const attendeesArray = emails.map(email => {
            const [namePart,] = email.split('@'); // Split email to get the name part
            const name = toTitleCase(namePart.replace('.', ' ')); // Replace dot with space and convert to title case
            return { name, email };
        });
        return attendeesArray;
    };


    return (<>
            <ModalHeader toggle={props.toggle} charCode="x" style={{paddingBottom: 0, border: "none"}}>
                <span style={{fontSize: "24px", paddingLeft: "10px"}}>
                   Upload Recording
                </span>
            </ModalHeader>
            <ModalBody>
                <section className="livemeeting-sec">
                    <div className="container">
                        <div className="d-flex align-items-stretch">
                            <Col className={"meetingData"} style={{width: "100%"}}>
                                <Row>
                                    <Col lg={12} style={{paddingLeft: 0}}>
                                        <Label style={{paddingTop: 10, fontWeight: 600}}>Title *</Label>
                                        <Input type="text" autoFocus required
                                               style={{fontSize: "14px", color: "#2d323c"}}
                                               onKeyPress={(e) => {
                                                   if (e.key === 'Enter') {
                                                       e.preventDefault();
                                                       e.stopPropagation();
                                                   }
                                               }}
                                               onChange={(e) => {
                                                   if (e.target.value !== null) {
                                                       setTitle(e.target.value);
                                                       setErrorTitle(false);
                                                   }
                                               }} placeholder="Enter title"/>
                                        {errorTitle ? (
                                            <div className="snippet-name-error" style={{color: "red"}}>
                                                Please enter valid email addresses</div>
                                        ) : null}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} style={{paddingLeft: 0}}>
                                        <Label style={{paddingTop: 10, fontWeight: 600}}>Description</Label>
                                        <Input type="text" autoFocus style={{fontSize: "14px", color: "#2d323c"}}
                                               onKeyPress={(e) => {
                                                   if (e.key === 'Enter') {
                                                       e.preventDefault();
                                                       e.stopPropagation();
                                                   }
                                               }}
                                               onChange={(e) => {
                                                   if (e.target.value !== null) {
                                                       setDescription(e.target.value);
                                                   }
                                               }}
                                               placeholder="Enter description"/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} style={{paddingLeft: 0}}>
                                        <Label style={{paddingTop: 10, fontWeight: 600}}>Organizer Name & Email </Label>
                                        <Row>
                                            <Col lg={6}>
                                                <Input type="text" autoFocus
                                                       style={{fontSize: "14px", color: "#2d323c"}}
                                                       value={organizerName}
                                                       onKeyPress={(e) => {
                                                           if (e.key === 'Enter') {
                                                               e.preventDefault();
                                                               e.stopPropagation();
                                                           }
                                                       }}
                                                       onChange={(e) => {
                                                           if (e.target.value !== null) {
                                                               setOrganizerName(e.target.value);
                                                               setErrorOrganizerName(false);
                                                           }
                                                       }}
                                                       placeholder="Enter organizer name"/>
                                                {errorOrganizerName ? (
                                                    <div className="snippet-name-error" style={{color: "red"}}>
                                                        Please enter name of meeting organizer</div>
                                                ) : null}
                                            </Col>
                                            <Col lg={6}>
                                                <Input type="text" autoFocus
                                                       style={{fontSize: "14px", color: "#2d323c"}}
                                                       value={organizerEmail}
                                                       onKeyPress={(e) => {
                                                           if (e.key === 'Enter') {
                                                               e.preventDefault();
                                                               e.stopPropagation();
                                                           }
                                                       }}
                                                       onChange={(e) => {
                                                           if (e.target.value !== null) {
                                                               e.preventDefault();
                                                               e.stopPropagation();
                                                               setOrganizerEmail(e.target.value);
                                                               setErrorOrganizerEmail(false);
                                                           }
                                                       }}
                                                       placeholder="Enter organizer email"/>
                                                {errorOrganizerEmail ? (
                                                    <div className="snippet-name-error" style={{color: "red"}}>
                                                        Please enter email of meeting organizer</div>
                                                ) : null}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} style={{paddingLeft: 0, paddingTop: 10}}>
                                        <Label style={{paddingTop: 10, fontWeight: 600}}>Attendee Email </Label>
                                        <div className="pwm-option" style={{display: "flex"}}>
                                            <Input style={{fontSize: 14, width: 400}}
                                                   placeholder="Comma separated list of emails"
                                                   onKeyPress={(e) => {
                                                       if (e.key === 'Enter') {
                                                           e.preventDefault();
                                                           e.stopPropagation();
                                                       }
                                                   }}
                                                   onBlur={(e) => {
                                                       e.preventDefault();
                                                       e.stopPropagation();
                                                       try {
                                                           const attendeeArray = processAttendeesInput(e.target.value);
                                                           if (attendeeArray && attendeeArray.length === 0) {
                                                               setErrorAttendees(true);
                                                           } else {
                                                               setAttendees(attendeeArray);
                                                               setErrorAttendees(false);
                                                           }
                                                       } catch (error) {
                                                           console.error("Error processing attendees: ", error);
                                                           setErrorAttendees(true);
                                                       }
                                                   }}
                                            />
                                        </div>
                                        {errorAttendees ? (
                                            <div className="snippet-name-error">
                                                <p>Enter comma-separated list of valid email addresses for all attendees
                                                    <br></br><b>e.g. peter@abc.com, john@abc.com and so on</b></p>
                                            </div>
                                        ) : null}
                                    </Col>
                                </Row>
                                <Row>
                                    <div style={{display: "flex", padding: "1rem 0px"}}>
                                        {user?.licenseConfig?.allowedVideoStorage !== -2 ?
                                            <div>
                                                <p style={{width: "25em"}}><b>Upload Audio/Video Files</b> (*.mp3, *.mp4, *.m4a, *.wav less than 300MB)</p>
                                                <Input type="file" accept="video/x-m4v,video/mp4,audio/mp3,audio/m4a,audio/wav,audio/x-m4a,audio/x-wav"
                                                    // multiple
                                                       onChange={fileSelectedHandler}
                                                />
                                            </div>
                                            :
                                            (user?.licenseConfig?.allowedAudioStorage !== -2) &&
                                            <div>
                                                <p style={{width: "25em"}}><b>Upload Audio Files</b> (*.mp3, *.m4a, *.wav less than 300MB)</p>
                                                <Input type="file" multiple accept="audio/mp3,audio/m4a,audio/wav" onChange={fileSelectedHandler}/>
                                            </div>}
                                        {errorFiles ? (
                                            <div className="snippet-name-error" style={{color: "red"}}>
                                                Please select audio/video files</div>
                                        ) : null}
                                    </div>
                                    <div>
                                        {/* Multi file upload no longer suported */}
                                        {/* <div className="md-chips">
                                                {Object.values(files).map((files: any, index) => {
                                                    return <div className="md-chip" key={files.name}>
                                                        <span>{files.name}</span>
                                                        <button type="button" className="md-chip-remove"
                                                                onClick={(e) => {
                                                                    removeFiles(index)
                                                                }}>
                                                        </button>
                                                    </div>
                                                })}
                                            </div> */}
                                    </div>
                                </Row>
                            </Col>
                        </div>
                    </div>
                </section>
            </ModalBody>
            <ModalFooter>
                <Button outline color="primary" style={{marginRight: 10}}
                        onClick={() => {
                            props.toggle();
                        }}>
                    Cancel
                </Button>{"   "}
                <Button color="primary" disabled={isUploading || isUploadingMeetingFiles}
                        onClick={handleSubmit}>
                    {!isUploading ? <b>Upload</b> : <b>Uploading</b>}
                </Button>{"   "}

            </ModalFooter>
        </>
    );
};

export default UploadMeeting;
