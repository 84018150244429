import React, {useEffect, useState} from "react";
import {useAppContext} from "../../hooks";
import {Modal, ModalBody, NavLink,ModalHeader} from "reactstrap";
import BioMetrics from "../User/biometrics";

function ModalBiometrics(props) {
    return (
        <Modal
            size="md"
            className="appProvider-container"
            isOpen={props.isOpen}
            toggle={props.toggle}
            placement="auto-end"
            centered={true}
            target={props.targetId}
        >
            <ModalHeader
                toggle={props.toggle}
                charCode="x"
                style={{paddingBottom: 0, border: "none"}}
            >
        <span style={{fontSize: "24px", paddingLeft: "20px"}}>
          Save Voice Pattern
        </span>
            </ModalHeader>
            <ModalBody>
                <BioMetrics
                    targetId="btnUserProfile"
                    toggle={props.toggle}
                    toggleError={props.toggleError}
                    bioMetricUrl={props.bioMetricUrl}
                ></BioMetrics>
            </ModalBody>
        </Modal>
    );
}

export const InfoModal = (props) => {
    const {user} = useAppContext();
    const [calendar, setCalendar] = useState(false);
    let url = `${process.env.REACT_APP_EXTERNAL_URL}/google?user=${user.email}`;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const toggleModalOpen = () => setIsModalOpen(!isModalOpen);

    useEffect(() => {
        if (user) {
            const myCalendar = user.providers.filter((e) => {
                return (
                    (e.provider.toLowerCase() === "google" || e.provider.toLowerCase() === "microsoft") &&
                    e.service.toLowerCase() === "calendar"
                )
            });
            if (myCalendar.length > 0) {
                setCalendar(myCalendar[0]?.accessToken);
            }
        }
    }, [user]);

    return (
        <div style={{padding: "0px 10px 5px 10px"}}>
            <ol type="1">
                {!calendar &&
                <li>Calendar integration incomplete - <b>Meetings will NOT be recorded.</b> Please check the boxes
                    during Calendar integration to grant Rafiki app permissions to read calendar. <br/>
                    <a target="_blank" href={url} style={{color: "blue", fontWeight: 700}}>
                        <b>Redo Calendar Integration</b>
                    </a>
                </li>}
                <li>Notetaker will join your meetings as a participant at the start of the meeting</li>
                <li>Notetaker can be removed from the meeting at any time like any other participant</li>
                <li>Your <NavLink id="bioLink" style={{display: "inline", textDecoration: "underline"}} onClick={toggleModalOpen}>
                    <span style={{ color: "#0052CC", cursor: "pointer"}}><b>Voice Pattern</b></span>
                    </NavLink> needs to be setup to be able identify your voice in call
                    <ModalBiometrics targetId="bioLink" isOpen={isModalOpen} toggle={toggleModalOpen}></ModalBiometrics>
                </li>
            </ol>
        </div>
    );
};