/* eslint-disable */
import {useLazyQuery, useQuery} from "@apollo/client";
import {OperationVariables} from "apollo-boost";
import {DocumentNode} from "graphql";
import React, {useEffect} from "react";

export const useImperativeQuery = (query) => {
    const { refetch } = useQuery(query, { skip: true });
      
    const imperativelyCallQuery = (variables) => {
      return refetch(variables);
    } 
      
    return imperativelyCallQuery;
}

export function useListQuery<TData = any, TVariables = OperationVariables>(query: DocumentNode,searchTerm: any= null) {
    // there are cases where no query is passed which broke FilterSelect
    if (!query) return { called: false, loading: false, data: undefined, error: undefined };

    const [load, { called, loading, data, error }] = useLazyQuery(query, {
        variables: searchTerm ? {...searchTerm}: null,
        fetchPolicy: "network-only"
      });
      useEffect(() => {
        if (query) {
          load();
        }
      }, [query])
      return { called,loading, error, data }  
}
