import React from "react";
import {IGroupFilterItemProps} from "./FilterPanel";
import {Col, Row, Input} from "reactstrap";

export const defaultSelectProps: IGroupFilterItemProps = {  id: "select",type:"radio",  items: [{code:"tesT", label:"test"}], component_name: "Group", text:"test" };
const FilterGroup: React.FC<IGroupFilterItemProps> = (props: IGroupFilterItemProps) => {
  return (
    <Row>
    { 
      props && ((props.items)).map((o: any, i) => {
        return <Col sm="12" key={"select" + props.id + o.code}><div key={"select" + props.id + o.code}  className="app-radio app-radio-inline">
                 <Input style={{fontSize:14}} label = {o.label} type='radio' id={o.code}  name={props.id} onChange={(e: any) => {
                      e.persist();
                      props.onChangeValue && props.onChangeValue(props.id, o, e);}}
                      disabled={props.disabled || false}
                      defaultChecked={props.text === o.code}
                      readOnly={props.readOnly || false}
                      value={o.code}
                    />
      </div></Col>}
      
    )
  }
  </Row>
  )
}
FilterGroup.defaultProps = defaultSelectProps;
export default FilterGroup;