import React from "react";
import {Col, Input, Row} from "reactstrap";

const AppDetails = (props) => {
  // const handleSubmit = async (values) => {
  //
  // };
  return (
    <>
      <Row>
        <Col sm="12">
                    <div className="form-group">
                      <label className="label">User Id</label>
                      <Input
                        type="text"
                        style={{fontSize:"14px"}}
                        placeholder="User Id"
                        defaultValue={"XXXXXX"}
                        readOnly = {true} />
                    </div>
                    <div className="form-group">
                      <label className="label">Account Id</label>
                      <Input
                        type="text"
                        placeholder="Account Id"
                        style={{fontSize:"14px"}}
                        defaultValue={"XXXXXX"}
                        readOnly = {true} />
                    </div>
                    <div className="form-group">
                      <label className="label">Access Token</label>
                      <Input
                        type="text"
                        placeholder="Access Token"
                        style={{fontSize:"14px"}}
                        id="phone"
                        defaultValue={"XXXXXX"}
                        readOnly = {true} />
                    </div>
        </Col>
      </Row>
    </>
  );
};

export default AppDetails;
