import React from "react"
import { Modal } from 'reactstrap'
const  ModalComponent = (props) => {
  const { isModal, toggle, children, className, size, backdrop='static'} = props
  return (
    <Modal isOpen={isModal} toggle={toggle} className={className} centered={true} size={size} backdrop={backdrop}>
      { children }
    </Modal>
  )
}

export default ModalComponent