import React, {useState} from "react";
import {IFilterItemProps} from "./FilterPanel";
import {Input} from "reactstrap";

export const defaultRangeProps: IFilterItemProps = { id: "", label: "", component_name: "FilterRange", min: new Date().toISOString().substr(0, 10), max: new Date().toISOString().substr(0, 10), text:undefined };
const FilterDateRange: React.FC<IFilterItemProps> = (props: IFilterItemProps) => {
  let minValue =  props.min;
  let maxValue =  props.max;
  const [min, setMin] = useState(minValue);
  const [max, setMax] = useState(maxValue);

  let fromId = "input_from_" + props.id;
  let toId = "input_to_" + props.id;
  var dateReg = /^\d{4}([./-])\d{2}\1\d{2}$/
  function handleMin(e) {
    if (e.currentTarget.value) {
      if (!e.currentTarget.value.match(dateReg)) return;
      let newMin = new Date(e.currentTarget.value);

      if (max) {
        let newMax = new Date(max);
        if (newMax < newMin) {
          newMin = newMax;
        }
      }
      setMin(newMin.toISOString().substr(0, 10));
    }
    else {
      setMin("undefined");
    }
    props.onChangeValue && props.onChangeValue(props.id, { min: e.currentTarget.value, max: max }, e);
  }
  function handleMax(e): any {
    if (e.currentTarget.value) {
      if (!e.currentTarget.value.match(dateReg)) return;
      let newMax = new Date(e.currentTarget.value);
      if (min) {
        let newMin = new Date(min);
        if (newMax < newMin) {
          newMax = newMin;
        }
      }
      setMax(newMax.toISOString().substr(0, 10));
    } else {
      setMax("undefined");
    }
    props.onChangeValue && props.onChangeValue(props.id, { min: min, max: e.currentTarget.value }, e);
  }

  return (
    <div>
      <span>From</span>
      <Input style={{fontSize:14}} key={fromId} id={fromId} name={fromId}
        type="date"
        defaultValue={min}
        disabled={props.disabled || false}
        onChange={(e) => handleMin(e)}></Input>
      <span>To</span>
      <Input style={{fontSize:14}} id={toId} name={toId}
        type="date"
        disabled={props.disabled || false}
        defaultValue={max} onChange={handleMax}></Input>
    </div>
  );
}

FilterDateRange.defaultProps = defaultRangeProps;
export default FilterDateRange;