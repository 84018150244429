export function camelToWords(str: string) {
  let newKey = ''
  let index = 0
  let code
  let wasPrevNumber = true
  let wasPrevUppercase = true

  while (index < str.length) {
    code = str.charCodeAt(index)
    if (index === 0) {
      newKey += str[index].toUpperCase()
    } else if ((!wasPrevUppercase && code >= 65 && code <= 90) || (!wasPrevNumber && code >= 48 && code <= 57)) {
      newKey += ' '
      newKey += str[index].toUpperCase()
    } else {
      newKey += str[index].toLowerCase()
    }
    wasPrevNumber = code >= 48 && code <= 57
    wasPrevUppercase = code >= 65 && code <= 90
    index++
  }

  return newKey
}

export function titleCase(str) {
  return str?.toLowerCase().split(' ').map(function (word) {
    return word?.replace(word[0], word[0] && word[0].toUpperCase());
  }).join(' ');
}

export function getInitial(firstName, lastName) {
  let initial = "";
  if(firstName && firstName.length > 0) {
    initial = firstName.charAt(0); 
  }
  if(lastName && lastName.length > 0) {
    initial += lastName.charAt(0);
  }
  return initial.toUpperCase();
}