import React, {CSSProperties, useEffect, useImperativeHandle} from "react";
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import {
    addDays,
    endOfDay,
    startOfDay,
    startOfMonth,
    endOfMonth,
    addMonths,
    startOfWeek,
    endOfWeek,
    isSameDay,
    differenceInCalendarDays,
    startOfYear,
    format,
    startOfQuarter,
    endOfQuarter,
    endOfYear,
} from "date-fns";
import {DateRangePicker } from "react-date-range";

interface Props {
    id: string;
    onChangeValue: (name, value) => void;
    text?: { // starting
        data: {
            minDate: string;
            maxDate: string;
        };
        label: string;
    };
    value?: { // controlled value
        data: {
            minDate: string;
            maxDate: string;
        };
        label: string;
    } | null;
    placeholder?: string;
    maxPastDays?: number;
    maxFutureDays?: number;
    inputStyle?: CSSProperties;
    defaultIsThisMonth?: boolean;
    startOfInputLabel?: string;
    title?: string;
    hideInput?: boolean
}

export interface DateRangeRef {
    openDateRangeModal: () => void;
}

const FilterCustomDateRange = React.forwardRef((props: Props, ref) => {
    const {
        id,
        onChangeValue,
        text,
        value,
        inputStyle,
        placeholder = 'Select date range',
        maxPastDays,
        maxFutureDays,
        defaultIsThisMonth = false,
        startOfInputLabel = '',
        title,
        hideInput = false,
    } = props;
    const defineds = {
        startOfWeek: startOfWeek(new Date()),
        endOfWeek: endOfWeek(new Date()),
        startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
        endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
        startOfToday: startOfDay(new Date()),
        endOfToday: endOfDay(new Date()),
        startOfYesterday: startOfDay(addDays(new Date(), -1)),
        endOfYesterday: endOfDay(addDays(new Date(), -1)),
        startOfMonth: startOfMonth(new Date()),
        endOfMonth: endOfMonth(new Date()),
        startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
        endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
        startOfNextMonth: startOfMonth(addMonths(new Date(), +1)),
        endOfNextMonth: endOfMonth(addMonths(new Date(), +1)),
        startOfQuarter: startOfQuarter(new Date()),
        endOfQuarter: endOfQuarter(new Date()),
        startOfLastQuarter: startOfQuarter(addMonths(new Date(), -3)),
        endOfLastQuarter: endOfQuarter(addMonths(new Date(), -3)),
        startOfNextQuarter: startOfQuarter(addMonths(new Date(), +3)),
        endOfNextQuarter: endOfQuarter(addMonths(new Date(), +3)),
        startOfYear: startOfYear(new Date()),
        endOfYear: endOfYear(new Date()),
    };

    const [state, setState] = React.useState([
        {
            startDate: text ? new Date(text?.data?.minDate) : (defaultIsThisMonth ? defineds.startOfMonth : defineds.startOfToday),
            endDate: text ? new Date(text?.data?.maxDate) : (defaultIsThisMonth ? defineds.endOfMonth : defineds.endOfToday),
            key: "selection",
            label: text ? text?.label : (defaultIsThisMonth ? "This Month" : ""),
        },
    ]);

    const [input, setInput] = React.useState<string>(text ? text?.label : (defaultIsThisMonth ? "This Month" : ""));

    useEffect(() => {
        if (value || value === null) {
            setState([
                {
                    startDate: value ? new Date(value?.data?.minDate) : (defaultIsThisMonth ? defineds.startOfMonth : defineds.startOfToday),
                    endDate: value ? new Date(value?.data?.maxDate) : (defaultIsThisMonth ? defineds.endOfMonth : defineds.endOfToday),
                    key: "selection",
                    label: value ? value?.label : (defaultIsThisMonth ? "This Month" : ""),
                },
            ])
            setInput(value ? value?.label : (defaultIsThisMonth ? "This Month" : ""))
        }
    }, [value]);

    const [isOpenModal, setIsOpenModal] = React.useState(false);

    useImperativeHandle(ref, () => ({
        openDateRangeModal() {
            setIsOpenModal(true)
        }
    }));

    const today = new Date();
    const lastYear = new Date(
        today.getFullYear() - 1,
        today.getMonth(),
        today.getDate() + 1
    );
    const nextYear = new Date(
        today.getFullYear() + 1,
        today.getMonth(),
        today.getDate() + 1
    );

    function getDaysFromToday(endDate) {
        return differenceInCalendarDays(defineds.startOfToday, endDate);
    }

    const pastRanges = [
        ...(maxPastDays && !(maxPastDays >= 0) ? [] : [
            {
                label: "Today",
                range: () => ({
                    startDate: defineds.startOfToday,
                    endDate: defineds.endOfToday,
                    label: "Today",
                }),
                isSelected(range) {
                    const definedRange = this.range();
                    return (
                        isSameDay(range.startDate, definedRange.startDate) &&
                        isSameDay(range.endDate, definedRange.endDate)
                    );
                },
            },
        ]),
        ...(maxPastDays && !(maxPastDays >= 1) ? [] : [
            {
                label: "Yesterday",
                range: () => ({
                    startDate: defineds.startOfYesterday,
                    endDate: defineds.endOfYesterday,
                    label: "Yesterday",
                }),
                isSelected(range) {
                    const definedRange = this.range();
                    return (
                        isSameDay(range.startDate, definedRange.startDate) &&
                        isSameDay(range.endDate, definedRange.endDate)
                    );
                },
            },
        ]),
        ...(maxPastDays && !(maxPastDays >= getDaysFromToday(defineds.startOfWeek)) ? [] : [
            {
                label: "This Week",
                range: () => ({
                    startDate: defineds.startOfWeek,
                    endDate: defineds.endOfWeek,
                    label: "This Week",
                }),
                isSelected(range) {
                    const definedRange = this.range();
                    return (
                        isSameDay(range.startDate, definedRange.startDate) &&
                        isSameDay(range.endDate, definedRange.endDate)
                    );
                },
            },
        ]),
        ...(maxPastDays && !(maxPastDays >= getDaysFromToday(defineds.startOfLastWeek)) ? [] : [
            {
                label: "Last Week",
                range: () => ({
                    startDate: defineds.startOfLastWeek,
                    endDate: defineds.endOfLastWeek,
                    label: "Last Week",
                }),
                isSelected(range) {
                    const definedRange = this.range();
                    return (
                        isSameDay(range.startDate, definedRange.startDate) &&
                        isSameDay(range.endDate, definedRange.endDate)
                    );
                },
            },
        ]),
        ...(maxPastDays && !(maxPastDays >= getDaysFromToday(defineds.startOfMonth)) ? [] : [
            {
                label: "This Month",
                range: () => ({
                    startDate: defineds.startOfMonth,
                    endDate: defineds.endOfMonth,
                    label: "This Month",
                }),
                isSelected(range) {
                    const definedRange = this.range();
                    return (
                        isSameDay(range.startDate, definedRange.startDate) &&
                        isSameDay(range.endDate, definedRange.endDate)
                    );
                },
            },
        ]),
        ...(maxPastDays && !(maxPastDays >= getDaysFromToday(defineds.startOfLastMonth)) ? [] : [
            {
                label: "Last Month",
                range: () => ({
                    startDate: defineds.startOfLastMonth,
                    endDate: defineds.endOfLastMonth,
                    label: "Last Month",
                }),
                isSelected(range) {
                    const definedRange = this.range();
                    return (
                        isSameDay(range.startDate, definedRange.startDate) &&
                        isSameDay(range.endDate, definedRange.endDate)
                    );
                },
            },
        ]),
        ...(maxPastDays && !(maxPastDays >= getDaysFromToday(startOfYear(new Date()))) ? [] : [
            {
                label: "This Year",
                range: () => ({
                    startDate: startOfYear(new Date()),
                    endDate: endOfDay(new Date()),
                    label: "This Year",
                }),
                isSelected(range) {
                    const definedRange = this.range();
                    return (
                        isSameDay(range.startDate, definedRange.startDate) &&
                        isSameDay(range.endDate, definedRange.endDate)
                    );
                },
            },
        ]),
        ...(maxPastDays && !(maxPastDays >= getDaysFromToday(lastYear)) ? [] : [
            {
                label: "Last Year",
                range: () => ({
                    startDate: lastYear,
                    endDate: today,
                    label: "Last Year",
                }),
                isSelected(range) {
                    const definedRange = this.range();
                    return (
                        isSameDay(range.startDate, definedRange.startDate) &&
                        isSameDay(range.endDate, definedRange.endDate)
                    );
                },
            },
        ]),
    ];

    const futureRanges = [
        ...(maxFutureDays && !(maxFutureDays >= 0) ? [] : [
            {
                label: "This Month",
                range: () => ({
                    startDate: defineds.startOfMonth,
                    endDate: defineds.endOfMonth,
                    label: "This Month",
                }),
                isSelected(range) {
                    const definedRange = this.range();
                    return (
                        isSameDay(range.startDate, definedRange.startDate) &&
                        isSameDay(range.endDate, definedRange.endDate)
                    );
                },
            },
        ]),
        ...(maxFutureDays && !(maxFutureDays >= getDaysFromToday(defineds.startOfQuarter)) ? [] : [
            {
                label: "This Quarter",
                range: () => ({
                    startDate: defineds.startOfQuarter,
                    endDate: defineds.endOfQuarter,
                    label: "This Quarter",
                }),
                isSelected(range) {
                    const definedRange = this.range();
                    return (
                        isSameDay(range.startDate, definedRange.startDate) &&
                        isSameDay(range.endDate, definedRange.endDate)
                    );
                },
            },
        ]),
        ...(maxFutureDays && !(maxFutureDays >= getDaysFromToday(defineds.startOfNextMonth)) ? [] : [
            {
                label: "Next Month",
                range: () => ({
                    startDate: defineds.startOfNextMonth,
                    endDate: defineds.endOfNextMonth,
                    label: "Next Month",
                }),
                isSelected(range) {
                    const definedRange = this.range();
                    return (
                        isSameDay(range.startDate, definedRange.startDate) &&
                        isSameDay(range.endDate, definedRange.endDate)
                    );
                },
            },
        ]),
        ...(maxFutureDays && !(maxFutureDays >= getDaysFromToday(defineds.startOfNextQuarter)) ? [] : [
            {
                label: "Next Quarter",
                range: () => ({
                    startDate: defineds.startOfNextQuarter,
                    endDate: defineds.endOfNextQuarter,
                    label: "Next Quarter",
                }),
                isSelected(range) {
                    const definedRange = this.range();
                    return (
                        isSameDay(range.startDate, definedRange.startDate) &&
                        isSameDay(range.endDate, definedRange.endDate)
                    );
                },
            },
        ]),
        ...(maxFutureDays && !(maxFutureDays >= getDaysFromToday(defineds.startOfLastMonth)) ? [] : [
            {
                label: "Last Month",
                range: () => ({
                    startDate: defineds.startOfLastMonth,
                    endDate: defineds.endOfLastMonth,
                    label: "Last Month",
                }),
                isSelected(range) {
                    const definedRange = this.range();
                    return (
                        isSameDay(range.startDate, definedRange.startDate) &&
                        isSameDay(range.endDate, definedRange.endDate)
                    );
                },
            },
        ]),
        ...(maxFutureDays && !(maxFutureDays >= getDaysFromToday(defineds.startOfLastQuarter)) ? [] : [
            {
                label: "Last Quarter",
                range: () => ({
                    startDate: defineds.startOfLastQuarter,
                    endDate: defineds.endOfLastQuarter,
                    label: "Last Quarter",
                }),
                isSelected(range) {
                    const definedRange = this.range();
                    return (
                        isSameDay(range.startDate, definedRange.startDate) &&
                        isSameDay(range.endDate, definedRange.endDate)
                    );
                },
            },
        ]),
        ...(maxFutureDays && !(maxFutureDays >= getDaysFromToday(startOfYear(new Date()))) ? [] : [
            {
                label: "This Year",
                range: () => ({
                    startDate: startOfYear(new Date()),
                    endDate: endOfYear(new Date()),
                    label: "This Year",
                }),
                isSelected(range) {
                    const definedRange = this.range();
                    return (
                        isSameDay(range.startDate, definedRange.startDate) &&
                        isSameDay(range.endDate, definedRange.endDate)
                    );
                },
            },
        ]),
        ...(maxFutureDays && !(maxFutureDays >= getDaysFromToday(lastYear)) ? [] : [
            {
                label: "Last Year",
                range: () => ({
                    startDate: lastYear,
                    endDate: today,
                    label: "Last Year",
                }),
                isSelected(range) {
                    const definedRange = this.range();
                    return (
                        isSameDay(range.startDate, definedRange.startDate) &&
                        isSameDay(range.endDate, definedRange.endDate)
                    );
                },
            },
        ]),
    ];

    const staticRanges = maxFutureDays ? futureRanges : pastRanges;
    const inputRanges = [
        {
            label: "days up to today",
            range(value) {
                const minimumDays = Math.min(Math.max(Number(value), 1));
                const daysUpToToday = maxPastDays ? Math.min(minimumDays, maxPastDays) : minimumDays;
                return {
                    startDate: addDays(
                        defineds.startOfToday,
                        (daysUpToToday - 1) * -1
                    ),
                    endDate: defineds.endOfToday,
                    label: `${value} days up to today`,
                };
            },
            getCurrentValue(range) {
                if (!isSameDay(range.endDate, defineds.endOfToday)) return "-";
                if (!range.startDate) return "∞";
                return (
                    differenceInCalendarDays(defineds.endOfToday, range.startDate) + 1
                );
            },
        },
        ...(typeof maxFutureDays !== 'undefined' && maxFutureDays >= 0 ? [
            {
                label: "days from today",
                range(value) {
                    const minimumDays = Math.max(Number(value), 1);
                    const daysFromToday = maxFutureDays
                        ? Math.min(minimumDays, maxFutureDays)
                        : minimumDays;
                    return {
                        startDate: defineds.startOfToday,
                        endDate: addDays(
                            defineds.startOfToday,
                            daysFromToday - 1
                        ),
                        label: `${value} days from today`,
                    };
                },
                getCurrentValue(range) {
                    if (!isSameDay(range.startDate, defineds.startOfToday)) return "-";
                    if (!range.endDate) return "∞";
                    const dayDifference = differenceInCalendarDays(range.endDate, defineds.startOfToday) + 1;
                    return dayDifference > 0 ? dayDifference : "-";
                },
            },
        ] : [])
    ];


    const handleChangesApplied = () => {
        const {startDate, endDate, label} = state[0];
        setInput(label);
        setIsOpenModal(false);

        // Capture user's timezone offset in minutes
        const timezoneOffset = new Date().getTimezoneOffset();

        // Convert startDate and endDate to the user's local time
        const adjustedStartDate = new Date(startDate.getTime() + timezoneOffset * 60 * 1000);
        const adjustedEndDate = new Date(endDate.getTime() + timezoneOffset * 60 * 1000);

        onChangeValue(id, {
            value: label,
            label,
            data: {
                minDate: adjustedStartDate.toISOString(),
                maxDate: adjustedEndDate.toISOString(),
            },
        });
    };

    const toggle = () => {
        setIsOpenModal((prevState) => !prevState);
    };

    const clearSelection = () => {
        setInput((defaultIsThisMonth ? "This Month" : ""));
        setState([
            {
                startDate: (defaultIsThisMonth ? defineds.startOfMonth : defineds.startOfToday),
                endDate: (defaultIsThisMonth ? defineds.endOfMonth : defineds.endOfToday),
                key: "selection",
                label: (defaultIsThisMonth ? "This Month" : ""),
            },
        ]);
    };

    const handleDateRangePickerChange = (item) => {
        const {startDate, endDate} = item.selection;
        const staticRange = staticRanges.find((sr) => {
            const range = sr.range();
            return (
                range.startDate.getTime() - startDate.getTime() === 0 &&
                range.endDate.getTime() - endDate.getTime() === 0
            );
        });
        item.selection.label = staticRange
            ? staticRange.label
            : format(item.selection.startDate, "MM/dd/yyy") +
            " - " +
            format(item.selection.endDate, "MM/dd/yyyy");
        setState([item.selection]);
    };

    const minDate = maxPastDays ? addDays(defineds.startOfToday, (maxPastDays - 1) * -1) : undefined;
    const maxDate = maxFutureDays ? addDays(defineds.endOfToday, (maxFutureDays)) : new Date();

    return (
        <div>
            { !hideInput &&
                <input
                    type="text"
                    readOnly
                    onFocus={toggle}
                    placeholder={placeholder}
                    autoFocus={false}
                    value={startOfInputLabel + input}
                    className="date-range-picker"
                    style={inputStyle}
                />}
            <Modal
                isOpen={isOpenModal}
                toggle={toggle}
                size="xl"
                returnFocusAfterClose={false}
            >
                {title && <ModalHeader toggle={toggle}>{title}</ModalHeader>}
                <ModalBody>
                    <DateRangePicker
                        onChange={handleDateRangePickerChange}
                        showPreview={true}
                        moveRangeOnFirstSelection={false}
                        ranges={state}
                        months={2}
                        direction="horizontal"
                        staticRanges={staticRanges}
                        inputRanges={inputRanges}
                        minDate={minDate}
                        maxDate={maxDate}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" outline onClick={clearSelection}>
                        Clear
                    </Button>
                    <Button color="primary" onClick={handleChangesApplied}>
                        Select
                    </Button>
                    <Button color="secondary" onClick={toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
});

export default FilterCustomDateRange;