import React from 'react';
import MicRecorder from 'mic-recorder-to-mp3';
import {Link} from "react-router-dom";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

export class AudioRecorder extends React.Component<{bioMetricUrl: any;},{isRecording: boolean,blobURL: string,blob: string,
isBlocked: boolean}> {
  constructor(props){
    super(props);
    this.state = {
      isRecording: false,
      blobURL: props.bioMetricUrl,
      blob: props.bioMetricUrl,
      isBlocked: false,
    };
  }
  
  getBlob() {
    return this.state.blob;
  }

  start = () => {
    if (this.state.isBlocked) {
      console.log('Permission Denied');
    } else {
      Mp3Recorder
        .start()
        .then(() => {
          this.setState({ isRecording: true });
        }).catch((e) => console.error(e));
    }
  };

  stop = () => {
    Mp3Recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob)

        this.setState({ blobURL, isRecording: false, blob: blob });
      }).catch((e) => console.log(e));
  };

  componentDidMount() {
  
        this.setState({ isBlocked: false });
  }

  render(){
    return (
      <div className="record-action-container">
        <audio src={this.state.blobURL} controls={true}
                 style={{position:"relative", top:"-5px", width: "300px", paddingLeft:"20px", paddingRight: "20px"}}/>
        <Link to="#"  style={{float: "right", marginLeft: "250px", marginTop: "5px"}}
                  onClick={(e) => { this.state.isRecording ? this.stop() : this.start() }}
                  className={`recbutton ${this.state.isRecording  ? "stoprecord" : ""}`}
                ></Link>
      </div>
    );
  }
}
