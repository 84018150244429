import { useContext } from "react";
import { ObjectContext, IDeal,  } from "./../context/AppStore";

export interface DealHook {
  deals: IDeal[];
  activeDealTab: string;
  selectedDealFromTable: IDeal;
  dealId: string;
  lastMeetingDate: string;
  setLastMeetingDate: (lastMeetingDate: string) => void;
  setDeals: (deals: IDeal[]) => void;
  setDealId: (dealId: string) => void;
  selectedDeal: IDeal;
  setSelectedDeal: (selectedDeal: IDeal) => void;
  setActiveDealTab: (activeDealTab: string) => void;
  setSelectedDealFromTable: (selectedDealFromTable: IDeal) => void;
  meetingCount: number;
  setMeetingCount: (meetingCount:number) => void;
  resetSelectedDeal : () => void;
}
const useDealHook = (): DealHook => {
  const [state, setState] = useContext(ObjectContext);

  const setDeals: any = (deals: IDeal[]) => {
    if (deals !== state.deals) {
      setState((prevState: any) => ({ ...prevState, deals: deals }));
    }
  };
  const setDealId: any = (dealId: string) => {
    dealId && setState((prevState: any) => ({ ...prevState, dealId: dealId }))
  };

  const setSelectedDeal: any = (selectedDeal: string) => {
    selectedDeal && setState((prevState: any) => ({ ...prevState, selectedDeal: selectedDeal }))
  };

  const setActiveDealTab : any = (activeDealTab: string) => setState((prevState: any) =>({ ...prevState, activeDealTab: activeDealTab })) 

  const setSelectedDealFromTable : any = (setSelectedDealFromTable: IDeal) => setState((prevState: any) =>({...prevState, selectedDealFromTable: setSelectedDealFromTable}));
  const setMeetingCount: any = (meetingCount: number) => setState((prevState: any) =>({ ...prevState, meetingCount: meetingCount }))
  const setLastMeetingDate: any = (lastMeetingDate: number) => setState((prevState: any) =>({ ...prevState, lastMeetingDate: lastMeetingDate }));
  const resetSelectedDeal : any = () => setState((prevState: any) =>({ ...prevState, selectedDeal: {} }));


  return {
    deals: state.deals,
    setDeals,
    setDealId,
    dealId: state.dealId,
    selectedDeal: state.selectedDeal,
    setSelectedDeal,
    activeDealTab: state.activeDealTab,
    setActiveDealTab,
    selectedDealFromTable: state.selectedDealFromTable,
    setSelectedDealFromTable,
    setMeetingCount,
    meetingCount: state.meetingCount,
    lastMeetingDate:state.lastMeetingDate,
    setLastMeetingDate,
    resetSelectedDeal
  };
};

export default useDealHook;