import {useLazyQuery, useMutation} from "@apollo/client";
import {OperationVariables, ApolloError} from "apollo-boost";
import {useEffect, useMemo, useRef, useState} from "react";
import {IMeeting} from "../../context/AppStore";
import {useBehaviourContext} from "../../hooks";
import useObjectHook from "../../hooks/useObjectHook";
import useAppContext from "../../hooks/useAppHook";
import {
    addTracker,
    addTrackerVariables,
    updateSystemTopics,
    updateSystemTopicsVariables,
    createMeeting,
    createMeetingVariables,
    getFullMeetingById,
    getFullMeetingByIdVariables,
    meeting,
    meetingVariables,
    searchDealsMeetingIdSearchCountsWithConfig,
    searchDealsMeetingIdSearchCountsWithConfigVariables,
    editMeeting,
    editMeetingVariables,
    MeetingInput,
} from "../ql/operation-result-types";
import {
    ADD_TRACKER,
    UPDATE_SYSTEM_TOPICs,
    APPLY_FILTER_MEETING_ID_SEARCH_COUNTS_WITH_CONFIG,
    GET_FULL_MEETING_BY_ID,
    GET_MEETING_NOTES,
    UPDATE_MEETING,
    EDIT_MEETING,
} from "../ql/schema";
import {useLocation} from "react-router-dom";
import { lastXDays } from "../../components/helper";
import uuid from "react-uuid";
import {toastService} from "../../services/toast.service";
import {postAxiosService} from "../../services/callAxios";

export const UpdateMeeting = () => {
    const [meeting] = useMutation<createMeeting,
        createMeetingVariables>(UPDATE_MEETING, {
        onCompleted(data: createMeeting): void {
            if (data != null) {
                // return data.createMeeting;
            }
        },
        onError(e: any): void {
            console.log(e);
        },
    });
    return meeting;
}

export const UpdateTracker = () => {
    const [tracker] = useMutation<addTracker,
        addTrackerVariables>(ADD_TRACKER, {
        onCompleted(data: addTracker): void {
            if (data != null) {
                // return data.createMeeting;
            }
        },
        onError(e: any): void {
            console.log(e);
        },
    });
    return tracker;
}

export const UpdateTopics = () => {
    const [topic] = useMutation<updateSystemTopics,
        updateSystemTopicsVariables>(UPDATE_SYSTEM_TOPICs, {
        onCompleted(data: updateSystemTopics): void {
            if (data != null) {
                // console.log('topics', data);
                // return data.createMeeting;
            }
        },
        onError(e: any): void {
            console.log(e);
        },
    });
    return topic;
}

/* eslint-disable */
export function useMeetingsQuery<TData = any, TVariables = OperationVariables>() {
    const {setMeeting, selectedMeeting} = useObjectHook();
    const {setHideContentPanel, isFilterTopNav} = useBehaviourContext();
    const [doRequestForMeeting, {called, loading, data, error}] =
        useLazyQuery<getFullMeetingById, getFullMeetingByIdVariables>
        (GET_FULL_MEETING_BY_ID, {
            fetchPolicy: "network-only"
        });
    useEffect(() => {
        if (data) {
            setHideContentPanel(false);
            let meeting = data?.meeting;
            if (selectedMeeting && Object.keys(selectedMeeting).length == 0)
                setMeeting(meeting as IMeeting);
            if (isFilterTopNav)
                setMeeting(meeting as IMeeting);
        }
    }, [loading, data]);
    return {doRequestForMeeting, loading, data}
}

export function useMeetingsNotesQuery<TData = any, TVariables = OperationVariables>() {
    const {notesData, setNotesData} = useBehaviourContext();
    const [getNotesForMeeting, {called, loading, data, error}] =
        useLazyQuery<meeting, meetingVariables>
        (GET_MEETING_NOTES, {
            fetchPolicy: "network-only"
        });
    useEffect(() => {
        if (data) {
            let meeting = data?.meeting;
            if (meeting && meeting.analytics?.notes)
                setNotesData(JSON.parse((meeting).analytics?.notes?.toString()));
        }
    }, [loading, data]);
    return {getNotesForMeeting, loading, data}
}

/* eslint-disable */
export function useFilteredMeetingsQuery<TData = any, TVariables = OperationVariables>() {
    const [meetingsReload, setMeetingsReload] = useState(false);
    const [meetingsLoading, setMeetingsLoading] = useState(true);
    const [meetingsError, setMeetingsError] = useState<ApolloError>();
    const [meetingsData, setMeetingsData] = useState<searchDealsMeetingIdSearchCountsWithConfig>();
    const [loadingMoreData, setLoadingMoreData] = useState(false);
    let reload = useRef(false);
    const skipCount = useRef(0);
    const history = useLocation();
    const {setMeetingsAndSearchMeetings, selectedMeeting, setSelectedMeeting, redirectMeetingId, setSearchMeetings} = useObjectHook();
    const {meetingSearchTerms, searchText} = useBehaviourContext();
    const { user } = useAppContext();
    const isAdmin =  user?.role?.name === 'Admin';
    const hideAllMeetings =  user?.customer?.hideAllMeetings;
    const isManager =  user?.role?.name === 'Manager';
    const isRep =  (user?.role?.name === 'Rep/SDR' || user?.role?.name === 'AE/Other');

    let filters = {...meetingSearchTerms}
    if (!isAdmin && hideAllMeetings) {
        const userTeamIds = user?.teams?.map(team => team.id);
        (isManager || isRep) && userTeamIds && (filters = {...filters, teams: userTeamIds});
        isRep && user?.email && (filters = {...filters, hostEmails: user?.email });
    }

    const [doRequestForMeetingsFull, {
        loading: loadingFull,
        error: errorFull,
        data: dataFull,
        fetchMore
    }] = useLazyQuery<searchDealsMeetingIdSearchCountsWithConfig, searchDealsMeetingIdSearchCountsWithConfigVariables>
    (APPLY_FILTER_MEETING_ID_SEARCH_COUNTS_WITH_CONFIG, {
        fetchPolicy: "network-only", variables: {
            searchTerm: {
                ...filters, searchText: searchText, returnTranscripts: true,
                weeklyCount: false, fullList: true
            }, args: {
                // @ts-ignore
                limit: 50,
                skip: 0,
                query: "",
                sortBy: "",
                sortDir: ""
            }
        },
    });

    const startDate = useMemo(() => lastXDays(7), []);
    const [doRequestForMeetingsLast7, {
        loading: loadingLast7,
        error: errorLast7,
        data: dataLast7,
    }] = useLazyQuery<searchDealsMeetingIdSearchCountsWithConfig, searchDealsMeetingIdSearchCountsWithConfigVariables>
    (APPLY_FILTER_MEETING_ID_SEARCH_COUNTS_WITH_CONFIG, {
        fetchPolicy: "network-only", variables: {
            searchTerm: {
                ...filters, searchText: searchText, returnTranscripts: true,
                weeklyCount: false, fullList: true,
                dateMin: startDate,
            }, args: {
                // @ts-ignore
                limit: 50,
                skip: 0,
                query: "",
                sortBy: "",
                sortDir: ""
            }
        },
    });

    useEffect(() => {
        if(!loadingFull) {
            setMeetingsLoading(loadingFull);
            setMeetingsError(errorFull);
            setMeetingsData(dataFull);
            if (dataFull && reload.current && !loadingFull) {
                setMeetingsReload(true);
                reload.current = false;
            }
        } else {
            setMeetingsLoading(loadingLast7);
            setMeetingsError(errorLast7);
            setMeetingsData(dataLast7);
            if (dataLast7 && reload.current && !loadingLast7) {
                setMeetingsReload(true);
            }
        }
    }, [loadingLast7, errorLast7, dataLast7, loadingFull, errorFull, dataFull])

    const doRequestForMeetings = (props) => {
        const last7Props = {...props, variables: { ...props.variables, searchTerm: { ...props.variables.searchTerm, dateMin: startDate } }}
        doRequestForMeetingsLast7(last7Props);
        return doRequestForMeetingsFull(props);
    }

    const fetchMoreData = () => {
        skipCount.current += 50;
        setLoadingMoreData(true)
        fetchMore({
            variables: {
                searchTerm: {
                    ...filters, searchText: "", returnTranscripts: true, weeklyCount: false, fullList: true
                }, args: {
                    // @ts-ignore
                    limit: 50,
                    skip: skipCount.current,
                    query: "",
                    sortBy: "",
                    sortDir: ""
                }
            },
            updateQuery: (prev: any, {fetchMoreResult}, ...rest) => {
                if (!fetchMoreResult) {
                    return prev;
                }
                let newData = prev?.searchDealsWithConfig?.searchResults?.searchResult;
                let fetchedArray = fetchMoreResult?.searchDealsWithConfig?.searchResults?.searchResult;
                let finalArray = newData.concat(fetchedArray);
                let returnFetchedData = Object.assign({}, prev, {
                    searchDealsWithConfig: {
                        ...prev.searchDealsWithConfig,
                        searchResults: {...prev.searchDealsWithConfig.searchResults, searchResult: finalArray}
                    }
                });
                setLoadingMoreData(false)
                setSearchMeetings(finalArray) // new addition
                return returnFetchedData;
            }
        })
    }

    const {doRequestForMeeting, loading: loading1, data: data1} = useMeetingsQuery();
    const {getNotesForMeeting, loading: loading3, data: data3} = useMeetingsNotesQuery();

    useEffect(() => {
        if (meetingsData && meetingsReload && !meetingsLoading) {
            setMeetingsReload(false);
            let meetings1: any = meetingsData?.searchDealsWithConfig?.searchResults?.searchResult?.map(m => { // new line
                let m1: any = {...m};
                if (m1 && m1.meeting && m1.meeting.account == null) {
                    m1["meeting"] = Object.assign({}, m1["meeting"], {
                        account: {
                            name: m1.accountName,
                            owner: {id: "", email: "", firstName: "", lastName: ""},
                            type: "",
                            industry_code: "",
                            industry: ""
                        }
                    });
                }
                if (m1 && m1.meeting) {
                    m1["meeting"] = Object.assign({}, m1["meeting"], {
                        leadCustomer: {name: m1.leadCustomer},
                        leadName: m1.leadName,
                        opportunityName: m1.opportunityName,
                        contactName: m1.contactName,
                    });
                }
                return m1?.meeting;
            });

            if (!meetings1 || meetings1.length == 0) {
                meetings1 = [];
            }
            let sortedMeeting = (meetings1 as IMeeting[]).sort(
                (a: IMeeting, b: IMeeting) =>
                    new Date(b.date).getTime() - new Date(a.date).getTime()
            ) || []
            setMeetingsAndSearchMeetings(
                sortedMeeting,
                meetingsData?.searchDealsWithConfig?.searchResults?.searchResult as any
            )
            if ((meetings1 as Partial<IMeeting>[]).length > 0) {
                if (!redirectMeetingId) {
                    let id: string = "";
                    if (selectedMeeting != null && selectedMeeting.id != null) {
                        id = selectedMeeting.id.toString();
                    }
                    if (!id)
                        id = (meetings1 as Partial<IMeeting>)[0].id.toString();
                    if (history?.pathname === "/meetings-full") {
                        setSelectedMeeting(null);
                        doRequestForMeeting({variables: {id: id}});
                        getNotesForMeeting({variables: {id: id}});
                    }
                } else {
                    doRequestForMeeting({variables: {id: redirectMeetingId.toString()}});
                    getNotesForMeeting({variables: {id: redirectMeetingId.toString()}});
                }
            } else {
                if (history?.pathname === "/meetings-full") {
                    setSelectedMeeting(null);
                }
            }
        }
    }, [meetingsLoading, meetingsData]);

    return {doRequestForMeetings, error: meetingsError, loading: meetingsLoading, loading1, data1, data2: meetingsData, reload, fetchMoreData, loadingMoreData};
}

export function useUploadFiles<TData = any, TVariables = OperationVariables>(props: { onUploadFiles?: () => void }) {
    const { onUploadFiles } = props;
    const [uploadingFiles, setUploadingFiles] = useState<{file: File, error: string, uploading: boolean, uploadPercent: number}[]>([]);
    const [isUploading, setIsUploading] = useState(false);

    const updateFileStatus = (i: number, status: {file: File, error: string, uploading: boolean, uploadPercent: number}) => {
        setUploadingFiles((prev => {
            const uploadingFiles = [...prev];
            uploadingFiles[i] = status;
            return uploadingFiles;
        }));
    }

    const [editMeeting] = useMutation<editMeeting, editMeetingVariables>(EDIT_MEETING, {
        async onCompleted(data: editMeeting) {
            if (data != null) {
                return data?.editMeeting?.id;
            }
        },
        onError(e: any): void {

        },
    });

    async function uploadFiles(files: any[], meetingId: string, userId: string, customerId: string) {
        let uploadFailed = false;
        let formData1 = new FormData();
        let newSources: any = [];
        files.forEach((item, i) => {
            let tempName = item.name.replace(/ /g, '_');
            let fileAttachment = {
                uuid: uuid(),
                url: customerId + "/" + meetingId + "/upload_" + meetingId + "_" + tempName,
                playURL: customerId + "/" + meetingId + "/" + meetingId + "_" + tempName,
                downloadURL: customerId + "/" + meetingId + "/" + meetingId + "_" + tempName,
                fileType: item.fileType,
                size: item.size,
            };
            newSources.push(fileAttachment);
        });

        let meetingInput: MeetingInput = {
            sources: newSources,
        };

        try {
            editMeeting({variables: {id: meetingId, meeting: meetingInput}}).then(data => {
                if (data?.data?.editMeeting?.id) {
                } else {
                }
            });
            // Uploading multiple files is broken currently
            for (let index = 0; index < files.length; index++) {
                const item = files[index];
                let tempName = item.name.replace(/ /g, '_');
                let fileName = tempName.split('.').slice(0, -1).join('.');
                let fileExt = tempName.split('.').pop() || 'blob';

                try {
                    // if file size is larger than 31MB split it into chunks and upload naming it as upload_<filename>_<chunkNumber>of<totalChunks>.extension
                    // split item.name into name and extension  and then append the chunk number and total chunks to the name
                    // make sure file data is clear out after each chunk is uploaded
                    // if file size is less than 31MB upload it as is
                    if (item.size > 31e6) {
                        let chunkSize = 31e6;
                        let totalChunks = Math.ceil(item.size / chunkSize);
                        let chunkNumber = 1;
                        let start = 0;
                        let end = chunkSize;
                        let fileData = item;
                        while (chunkNumber <= totalChunks) {
                            let chunkName = "upload_" + item.name + "_" + chunkNumber + "of" + totalChunks;
                            let chunk = fileData.slice(start, end);
                            formData1.append("file", chunk, chunkName);
                            formData1.set("userId", userId);
                            formData1.set("meetingId", meetingId);
                            formData1.set("customerId", customerId);
                            formData1.set("fileType", fileExt);
                            formData1.set("fileName",  "uploadsplit_" + chunkNumber + "of" + totalChunks + "." + fileExt);
                            formData1.set("finalName", "uploadsplit_" + chunkNumber + "of" + totalChunks + "." + fileExt);
                            let data1 = await postAxiosService("users/uploadToGCP", formData1);
                            if (data1.data === "success") {
                                const percent = (chunkNumber / totalChunks) * 100;
                                let isLoading = true
                                if(chunkNumber === totalChunks) isLoading = false;
                                updateFileStatus(index, { file: item as File, error: "", uploading: isLoading, uploadPercent: percent })
                            } else {
                                uploadFailed = true;
                                updateFileStatus(index, { file: item as File, error: "Upload failed", uploading: false, uploadPercent: 0 })
                            }
                            start = end;
                            end = end + chunkSize;
                            chunkNumber++;
                            formData1.delete("file");
                            // only removes chunk update progress here
                        }
                    } else {
                        formData1.append("file", item, tempName);
                        formData1.set("userId", userId);
                        formData1.set("meetingId", meetingId);
                        formData1.set("customerId", customerId);
                        formData1.set("fileType", fileExt);
                        formData1.set("fileName", "uploadsplit_1of1." + fileExt);
                        formData1.set("finalName", "uploadsplit_1of1." + fileExt);
                        let data1 = await postAxiosService("users/uploadToGCP", formData1);
                        if (data1.data === "success") {
                            updateFileStatus(index, { file: item as File, error: "", uploading: false, uploadPercent: 100 })
                        } else {
                            uploadFailed = true;
                            updateFileStatus(index, { file: item as File, error: "Upload failed", uploading: false, uploadPercent: 0 })
                        }
                    }
                } catch(e: any) {
                    console.error("Failed to upload: ", item, " Error: ", e);
                    uploadFailed = true;
                    updateFileStatus(index, { file: item as File, error: "Upload failed", uploading: false, uploadPercent: 0 })
                }
            };
        } catch(e) {
            console.error("Failed to upload with Error: ", e);
            uploadFailed = true;
        }
        if (!uploadFailed) {
            toastService.info("Upload successful. Meeting will appear on the dashboard in less than an hour.");
        } else {
            toastService.error("Upload failed");
        }
    }

    const uploadMeetingFiles = async (files: File[], meetingId: string, userId: string, customerId: string) => {
        if (isUploading) {
            toastService.error("Cant Upload more files during upload");
            return;
        }
        setIsUploading(true);
        onUploadFiles && onUploadFiles();
        const newUploadingFiles = files.map((file: File) => ({ file: file, error: "", uploading: true, uploadPercent: 0 }));
        setUploadingFiles(newUploadingFiles);
        await uploadFiles(files, meetingId, userId, customerId)

        setIsUploading(false);
    }

    return { isUploading, uploadingFiles, uploadMeetingFiles }
}