import React from "react";
interface LoadingProps {
    loading?: Boolean;
    middle?:Boolean;
}

const Loading: React.FC<LoadingProps> = (props: LoadingProps) => {
    if(props.loading === true) {
        if(props.middle === true || props.middle === null)  {
           return  <div className="loading" style={{ position: "fixed", left: "50vw", top: "50vh", verticalAlign: "middle", textAlign: "center" }}>
                <div className="animated fadeIn pt-1 text-center">
                    <div style={{ display: 'inline-block' }}>
                        <img style={{ margin: "40% 30% 30% 45%", width: "40px", height: "80px"}} src="../../assets/img/logoloading.gif" />
                    </div>
                </div>
            </div>
        }
        else {
            return  (
                <div className="animated fadeIn pt-1 text-center">
                    <div style={{ display: 'inline-block' }}>
                        <img style={{width: "40px", height: "80px"}} src="../../assets/img/logoloading.gif" />
                    </div>
                </div>)
        }
    }
    return <></>
};
Loading.defaultProps = {
    loading : false,
    middle : true
}
export default Loading;
