import React, {useState} from "react";
import {Button, Input, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {useAppContext} from "../../hooks";
import {postJsonAxiosService} from "../../services/callAxios";
import {toastService} from "../../services/toast.service";

const OpenPhoneModal = (props) => {
    const {user} = useAppContext();
    const {provider, toggle} = props;
    const [phone, setPhone] = useState("");
    const [errorPhone, setErrorPhone] = useState(false);
    const [orgurl, setOrgUrl] = useState("");
    const [errorOrgUrl, setErrorOrgUrl] = useState(false);

    const handleSubmit = async (values) => {
        var newPhone = phone;
        if (!phone) {
            setErrorPhone(true);
        } else {
            newPhone = phone.replace(/[()-\s]/g, '')
            setPhone(newPhone);
        }
        let provider :any = {
            providerId: newPhone,
            provider: "openPhone",
            service: "Dialer",
        }
        try {
            let response = await postJsonAxiosService("openPhone/webhook/validate", {provider: provider});
            if (response) {

                if (response.data === "Incorrect Phone") {
                    toastService.error("Incorrect Phone");
                } else {
                    if (!user.providers || user.providers.length == 0) {
                        user.providers = [];
                    }
                    provider.status = "Installed"
                    user.providers.push(provider);
                    toastService.info("OpenPhone Integrated");
                    window.location.reload();
                }
            }
        } catch (e) {
            toastService.error("Error while updating integration");
        }
    };

    return (
        <div style={{padding: 20}}>
            <ModalHeader toggle={props.toggle}
                         style={{fontSize: 24, fontWeight: 600, border: "none"}}>
                <span>Add OpenPhone Dialer Integration</span>
            </ModalHeader>
            <ModalBody>
                <div>
                    <div className="snippet-desc" style={{fontSize: 14, color: "#202124"}}>
                        <label>Enter Phone Number</label>
                        <Input style={{fontSize: 14, color: "#202124"}}
                               placeholder="Phone number in the format +1555555555"
                               value={phone}
                               onChange={(e) => {
                                   let phone = e.target.value;

                                   setPhone(phone);
                                   setErrorPhone(false);
                               }}
                        />
                        {errorPhone ? (
                            <div className="snippet-desc-error" style={{color: "red"}}>
                                Please enter OpenPhone Dialer Number in the format +1555555555
                            </div>
                        ) : null}
                    </div>
                    <div>
                    </div>
                </div>

            </ModalBody>
            <ModalFooter style={{border: "none", padding: "0px 0px 10px 30px"}}>
                <Button outline color="primary" onClick={props.toggle}>
                    Cancel
                </Button>{" "}
                <Button color="primary" onClick={handleSubmit}>
                    Authorize
                </Button>{" "}
            </ModalFooter>
        </div>
    )
};

export default OpenPhoneModal;
