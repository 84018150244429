/* eslint-disable */
import React from "react";
import { toDateMonthYear } from "../utils/DateUtil";
import FilterPanel from "./core/Filters/FilterPanel";
import {
    startOfMonth,
    endOfMonth,
    startOfQuarter,
    endOfQuarter,
    addMonths,
} from "date-fns";

export const getAccountTableData = (accounts, profileColors): any => {
    return accounts?.length > 0
        ? accounts.map((account) => {
            const {
                accountName,
                dealAmount,
                contacts,
                createdAt,
                updatedAt,
                dealCount,
                providerAccountId,
                meetingCount,
                reps,
                id,
            } = account;
            return {
                accountName,
                dealAmount,
                providerAccountId,
                contacts,
                profileColor: profileColors[id],
                accountStarted: toDateMonthYear(createdAt),
                lastUpdatedAt: toDateMonthYear(updatedAt),
                dealCount,
                meetingCount,
                reps,
            };
        })
        : [];
};

export const getContactArray = (contacts: any) => {
    return (
        contacts?.length > 0 &&
        contacts.map((contact, index) => contact.contactName)
    );
};

export const getContact = (contacts): any => {
    return contacts && contacts?.length > 0
        ? `${contacts[0]?.contactName} +${contacts.length - 1 > 0 ? contacts.length - 1 : ""
        }`
        : "";
};

export function getDealTableData(deals, profileColors): any {
    return deals?.length > 0
        ? deals.map((deal) => {
            const {
                dealName,
                accountName,
                contacts,
                meetingCount,
                representative,
                lastMeetingContact,
                amount,
                currencyType,
                lastMeetingDate,
                lastEmailDate,
                stage,
                id,
                closingDate,
                pricingCount,
                isWon,
                opportunityType
            } = deal;
            return {
                dealName,
                profileColor: profileColors[id],
                accountName,
                lastMeetingContact,
                lastMeetingDate,
                lastEmailDate,
                contacts,
                meetingCount,
                representative,
                amount,
                currencyType,
                stage,
                pricingCount,
                isWon,
                closeDate: closingDate!== null ? toDateMonthYear(closingDate) : null,
                id,
                opportunityType
            };
        })
        : [];
}

export function getCoachingTableData(coachingByManager, managerEmail): any {
    const rowsHashMap = new Map();

    const updateHashItem = (hashKey, meetingsKey, lastDateKey, coachingData) => {
        const {
            meetings,
            _id,
        } = coachingData;

        if (rowsHashMap.has(hashKey)) {
            const rowData = rowsHashMap.get(hashKey);
            rowData[meetingsKey] = meetings;
            rowData[`${meetingsKey}Length`] = meetings.length;
            rowData[lastDateKey] = _id[lastDateKey];
        } else {
            const rowData = {
                name: _id.hostName,
                jobTitle: _id.jobTitle,
                callsAsAttendees: null,
                callsAsAttendeesLength: 0,
                lastAttendedDate: null,
                callsWithComments: null,
                callsWithCommentsLength: 0,
                lastCommentedDate: null,
                callsWithScorecards: null,
                callsWithScorecardsLength: 0,
                lastScoredDate: null,
                callsAsListened: null,
                lastVisitedDate: null,
                callsAsListenedLength: 0,
            };
            rowData[meetingsKey] = meetings;
            rowData[`${meetingsKey}Length`] = meetings.length;
            rowData[lastDateKey] = _id[lastDateKey];
            rowsHashMap.set(hashKey, rowData);
        }
    };

    const processColumnCellsArray = (array, meetingsKey, lastDateKey) => {
        array?.forEach((coachingData) => {
            const { _id: { host } } = coachingData;
            if (managerEmail != host){
                updateHashItem(host, meetingsKey, lastDateKey, coachingData);
            }
        });
    };

    processColumnCellsArray(coachingByManager?.callsAsAttendeesByManager, 'callsAsAttendees', 'lastAttendedDate');
    processColumnCellsArray(coachingByManager?.callsWithCommentsByManager, 'callsWithComments', 'lastCommentedDate');
    processColumnCellsArray(coachingByManager?.callsWithScorecardsByManager, 'callsWithScorecards', 'lastScoredDate');
    processColumnCellsArray(coachingByManager?.callsAsListenedByManager, 'callsAsListened', 'lastVisitedDate ');

    return Array.from(rowsHashMap.values());
}

export function getForecastTableData(forecastData): any {
    if(!forecastData) return { header: null, tableData: []};
    const header = {
        name: {
            firstName: forecastData.firstName,
            lastName: forecastData.lastName,
            jobTitle: forecastData.jobTitle,
        },
        targetAttainment: {
            totalActualSales: 0,
            totalTargetSales: 0,
            percentage: 0,

        },
        pipelineCoverage: {
            countForecastOpenDeals: 0,
            totalForecastOpenDeals: 0,
            coverage: 0,
        },
        commit: {
            count: 0,
            sum: 0,
        },
        mostLikely: {
            count: 0,
            sum: 0,
        },
        bestCase: {
            count: 0,
            sum: 0,
        },
    }


    const reps = forecastData.reps;
    const rowData = reps.map((data) => {
        const {
            targetAttainment, pipelineCoverage,
            commit, mostLikely, bestCase
        } = data;

        header.targetAttainment = { 
            totalActualSales: targetAttainment.totalActualSales + header.targetAttainment.totalActualSales,
            totalTargetSales: targetAttainment.totalTargetSales + header.targetAttainment.totalTargetSales,
            percentage: targetAttainment.percentage + header.targetAttainment.percentage,
        }
        header.pipelineCoverage = { 
            countForecastOpenDeals: pipelineCoverage.countForecastOpenDeals + header.pipelineCoverage.countForecastOpenDeals,
            totalForecastOpenDeals: pipelineCoverage.totalForecastOpenDeals + header.pipelineCoverage.totalForecastOpenDeals,
            coverage: pipelineCoverage.coverage + header.pipelineCoverage.coverage,
        }


        header.commit = { 
            count: commit.count + header.commit.count,
            sum: commit.sum + header.commit.sum,
        }
        header.mostLikely = { 
            count: mostLikely.count + header.mostLikely.count,
            sum: mostLikely.sum + header.mostLikely.sum,
        }
        header.bestCase = { 
            count: bestCase.count + header.bestCase.count,
            sum: bestCase.sum + header.bestCase.sum,
        }
        
        return {
            name: {
                firstName: data.firstName,
                lastName: data.lastName,
                jobTitle: data.jobTitle,
            },
            targetAttainment: targetAttainment,
            pipelineCoverage: pipelineCoverage,
            commit: commit,
            mostLikely: mostLikely,
            bestCase: bestCase,
        }
    });

    if (reps.length > 0) {
        header.targetAttainment.percentage = header.targetAttainment.percentage / reps.length;
        header.pipelineCoverage.coverage = header.pipelineCoverage.coverage / reps.length;
    }


    return { header: header, tableData: rowData || []};;
}


export const getMeetingDuration = (selected) => {
    const value = selected?.value;
    let parts;
    let label;
    let numValue;
    if (value === "< 1 Min" || value === "0-1")
        return "0-1";
    else if (value === "1 - 5 Min" || value === "1-5")
        return "1-5";
    else if (value === "5 - 15 Min" || value === "5-15")
        return "5-15";
    else if (value === "15 - 30 Min" || value === "15-30")
        return "15-30";
    else if (value === "30 - 60 Min" || value === "30-60")
        return "30-60";
    else if (value === "> 60 Min" || value === "60-300")
        return "60-300";
    else if (value === 'custom')
        parts = selected?.label.split(' ');
    label = parts[0].trim();
    numValue = parseFloat(parts[1]);
    return handleMeetingDurationCustomValue(label, numValue);
};

export const getDates = (selected) => {
    let minDate: any = new Date();
    if (selected === "Last 7 days") return lastXDays(7);
    else if (selected === "Last 30 days") return changeMonths(1);
    else if (selected === "Last 60 days") return changeMonths(2);
    else if (selected === "Last 90 days") return changeMonths(3);
    else if (selected === "Last 7 days") return changeMonths(1);
    else if (selected === "Last 30 days") return changeMonths(1);
    else if (selected === "Last 60 days") return changeMonths(2);
    else if (selected === "Last 90 days") return changeMonths(3);
    else if (selected === "Last 180 days") return changeMonths(6);
    else if (selected === "Last year")
        return new Date(minDate.setYear(minDate.getFullYear() - 1)).toISOString();
};

export const lastXDays = (days: number) => {
    let minDate: any = new Date();
    minDate.setHours(0, 0, 0, 0);
    return new Date(minDate.setDate(minDate.getDate() - days)).toISOString();
};

export function getToday() {
    const now = new Date();
    const options = { timeZone: 'UTC' }; // Set the desired timezone

    const todayStart = new Date(now.toLocaleDateString('en-us', options)).toISOString();
    const todayEnd = new Date(now.toLocaleDateString('en-us', options) + ' 23:59:59.999').toISOString();

    return { todayStart, todayEnd };
}

export function getYesterday() {
    const now = new Date();
    const options = { timeZone: 'UTC' }; // Set the desired timezone

    const yesterday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() - 1));
    const yesterdayStart = new Date(yesterday.toLocaleDateString('en-us', options)).toISOString();
    const yesterdayEnd = new Date(yesterday.toLocaleDateString('en-us', options) + ' 23:59:59.999').toISOString();

    return { yesterdayStart, yesterdayEnd };
}


export function getThisWeek() {
    const now = new Date();
    const options = { timeZone: 'UTC' }; // Set the desired timezone

    const thisWeek = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() - ((now.getUTCDay() + 6) % 7)));
    const thisWeekStart = new Date(thisWeek.toLocaleDateString('en-us', options)).toISOString();
    const thisWeekEnd = new Date(Date.UTC(thisWeek.getUTCFullYear(), thisWeek.getUTCMonth(), thisWeek.getUTCDate() + 6, 23, 59, 59, 999)).toISOString();

    return { thisWeekStart, thisWeekEnd };
}

export function getLastWeek() {
    const now = new Date();
    const options = { timeZone: 'UTC' }; // Set the desired timezone

    const thisWeekStart = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() - ((now.getUTCDay() + 6) % 7)));
    thisWeekStart.setUTCHours(0, 0, 0, 0);

    const lastWeekStart = new Date(Date.UTC(thisWeekStart.getUTCFullYear(), thisWeekStart.getUTCMonth(), thisWeekStart.getUTCDate() - 7)).toISOString();
    const lastWeekEnd = new Date(Date.UTC(thisWeekStart.getUTCFullYear(), thisWeekStart.getUTCMonth(), thisWeekStart.getUTCDate() - 1, 23, 59, 59, 999)).toISOString();

    return { lastWeekStart, lastWeekEnd };
}

export function getThisMonth() {
    const now = new Date();
    const options = { timeZone: 'UTC' }; // Set the desired timezone

    const thisMonthStart = new Date(now.toLocaleDateString('en-us', options)).toISOString();
    const thisMonthEnd = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59, 999).toISOString();

    return { thisMonthStart, thisMonthEnd };
}

export function getLastMonth() {
    const now = new Date();

    const lastMonthStart = new Date(Date.UTC(now.getFullYear(), now.getMonth() - 1, 1)).toISOString();
    const lastMonthEnd = new Date(Date.UTC(now.getFullYear(), now.getMonth(), 0, 23, 59, 59, 999)).toISOString()

    return { lastMonthStart, lastMonthEnd };
}

export function getLast90Days() {
    const now = new Date();
    const options = { timeZone: 'UTC' }; // Set the desired timezone

    // Calculate the start date for the last 90 days
    const startDate = new Date(now);
    startDate.setDate(startDate.getDate() - 89); // 89 days ago to include today as the 90th day
    startDate.setHours(0, 0, 0, 0); // Set time to the beginning of the day
    const last90DaysStart = new Date(startDate.toLocaleDateString('en-us', options)).toISOString();

    // Set the end date to the current date and time
    const last90DaysEnd = new Date(now.toLocaleString('en-us', options)).toISOString();

    return { last90DaysStart, last90DaysEnd };
}



// Get the start and end dates for this year
export function getThisYear() {
    const now = new Date();
    const options = { timeZone: 'UTC' }; // Set the desired timezone

    const thisYearStart = new Date(now.getFullYear(), 0, 1).toISOString();
    const thisYearEnd = new Date(now.getFullYear() + 1, 0, 0, 23, 59, 59, 999).toISOString();

    return { thisYearStart, thisYearEnd };
}

// Get the start and end dates for last year
export function getLastYear() {
    const now = new Date();
    const options = { timeZone: 'UTC' }; // Set the desired timezone

    const lastYearStart = new Date(now.getFullYear() - 1, 0, 1).toISOString();
    const lastYearEnd = new Date(now.getFullYear(), 0, 0, 23, 59, 59, 999).toISOString();

    return { lastYearStart, lastYearEnd };
}

export const getDateOptions = () => {
    let minDate: any = new Date();
    const lastMonthDate = changeMonths(1);
    const twoMonthsAgo = changeMonths(2);
    const threeMonthsAgo = changeMonths(3);
    const sixMonthsAgo = changeMonths(6);
    const lastYear = new Date(minDate.setYear(minDate.getFullYear() - 1)).toISOString();

    return [
        { label: "Today", value: "today" },
        { label: "Yesterday", value: "yesterday" },
        { label: "This Week", value: "thisweek" },
        { label: "Last Week", value: "lastweek" },
        { label: "This Month", value: "thismonth" },
        { label: "Last Month", value: "lastmonth" },
        { label: "Last 60 days", value: twoMonthsAgo },
        { label: "Last 90 days", value: threeMonthsAgo },
        { label: "Last 180 days", value: sixMonthsAgo },
        { label: "Last year", value: lastYear },
        // { label: "Custom", value: "custom" },
    ];
};

export const getCloseDateOptions = () => {
    let minDate: any = new Date();
    const lastMonthDate = changeMonths(1);
    const twoMonthsAgo = changeMonths(2);
    const threeMonthsAgo = changeMonths(3);
    const sixMonthsAgo = changeMonths(6);
    const lastYear = new Date(
        minDate.setYear(minDate.getFullYear() - 1)
    ).toISOString();
    return [
        { label: "This Month", value: lastMonthDate },
        { label: "This Quarter", value: twoMonthsAgo },
        { label: "Next Month", value: threeMonthsAgo },
        { label: "Next Quarter", value: sixMonthsAgo },
        // {label: "Last year", value: lastYear},
    ];
};

const quarters = {
    1: "Jan 1-March 31",
    2: "Apr 1-June 30",
    3: "Jul 1-Sep 30",
    4: "Oct 1-Dec 31"
}


function getQuarter(date) {
    const month = date.getMonth() + 1;
    return Math.ceil(month / 3)
}

function getFormatedQuarter(dateRange, quarterNumber) {
    const date = new Date();
    const year = date.getFullYear();
    const range = dateRange.split('-');
    let newRange;
    if (quarterNumber < 4) {
        newRange = `${range[0]} ${year}-${range[1]} ${year}`
    } else newRange = `${range[0]} ${year + 1}-${range[1]} ${year + 1}`
    return newRange;
}

const format = (val) => new Date(val).toLocaleString("default", { month: "short" }) + " " + val.getDate();

export const getDealDateOption = () => {
    const date = new Date();
    const quarter = getQuarter(date)
    const firstDay = (month) => new Date(date.getFullYear(), month, 1);
    const lastDay = (month) => new Date(date.getFullYear(), month + 1, 0);
    const thisMonth = `${format(firstDay(date.getMonth()))} ${date.getFullYear()}-${format(lastDay(date.getMonth()))} ${date.getFullYear()}`;
    const nextMonth = date.getMonth() < 12 ? `${format(firstDay(date.getMonth() + 1))} ${date.getFullYear()}-${format(lastDay(date.getMonth() + 1))} ${date.getFullYear()}` : `${format(firstDay(1))} ${date.getFullYear()}-${format(lastDay(1))} ${date.getFullYear() + 1}`;
    const thisQuarter = getFormatedQuarter(quarters[quarter], quarter);
    const nextQuarter = quarter < 4 ? getFormatedQuarter(quarters[quarter + 1], quarter) : getFormatedQuarter(quarters[1], quarter);


    return [
        { label: "This Month", value: thisMonth },
        { label: "This Quarter", value: thisQuarter },
        { label: "Next Month", value: nextMonth },
        { label: "Next Quarter", value: nextQuarter },
        // {label: "Last year", value:date},
    ];
}

export const changeMonths = (months: number) => {
    let minDate: any = new Date();
    return new Date(minDate.setMonth(minDate.getMonth() - months)).toISOString();
};

export const getDealWarningOptions = () => [
    { label: "No activity", value: "noActivity", tooltip: "Warn when there's no activity between you and the prospec" },
    { label: "Ghosted", value: "ghosted", tooltip: "Warn when your prospects show no activity at all" },
    { label: "Overdue", value: "overdue", tooltip: "Warn when the deal's close date is in the past" },
    { label: "Not enough contacts", value: "notEnoughContacts", tooltip: "Warn when there are too few contacts on the prospect side" },
    { label: "BANT mentioned", value: "bant", tooltip: "Warn if BANT discussions were mentioned in the meeting" },
    { label: "Competitors mentioned", value: "competitors", tooltip: "Warn if Competitors were mentioned in the meeting" },
    { label: "Next Steps not set", value: "nextSteps", tooltip: "Warn if Next Steps were not set after the meeting" },
    { label: "Pricing", value: "pricing", tooltip: "Warn if pricing were mentioned in the meeting" },
    { label: "High Stakeholders not involved", value: "stakeHolders", tooltip: "Warn if high stakeholders not involved in the meeting" },
    { label: "Stalled in stage", value: "stalledInStage", tooltip: "Warn if stalled in stages" },
];

export const getDealForecasLineOfBusinessOptions = () => [
    { label: "New business", value: "New" },
    { label: "Renewal", value: "Existing" },
];

export const getDealForecastDateOptions = () => {
    const today = new Date();
    const startThisMonth = startOfMonth(today);
    const endThisMonth = endOfMonth(today);

    const startThisQuarter = startOfQuarter(today);
    const endThisQuarter = endOfQuarter(today);

    const nextMonth = addMonths(today, 1);
    const startNextMonth = startOfMonth(nextMonth);
    const endNextMonth = endOfMonth(nextMonth);

    return [
        { label: "This Month", value: { minDate: startThisMonth.toISOString(), maxDate: endThisMonth.toISOString() } },
        { label: "This Quarter", value: { minDate: startThisQuarter.toISOString(), maxDate: endThisQuarter.toISOString() } },
        { label: "Next Month", value: { minDate: startNextMonth.toISOString(), maxDate: endNextMonth.toISOString() } },
    ];
}

export const getAmountOptions = () => [
    { label: "< $10,000", value: "0-10000" },
    { label: "$10,001 - $20,000", value: "10001-20000" },
    { label: "$20,001 - $30,000", value: "20001-30000" },
    { label: "$30,001 - $40,000", value: "30001-40000" },
    { label: "$40,001 - $ 50000", value: "40001-50000" },
    { label: ">$50,001", value: "50001-2147483646" },
];

export const getCallTypes = () => [
    { label: "Web Conferencing", value: "Web Conferencing" },
    { label: "Dialer", value: "Dialer" },
];

export const getStatusTypes = () => [
    { label: "Won", value: "Closed Won" },
    { label: "Lost", value: "Closed Lost" },
];

export const getCallCategory = () => [
    { label: "Audio", value: "Audio" },
    { label: "Video", value: "Video" },
    { label: "Chat", value: "Chat" },
];

export const getCallScope = () => [
    { label: "Internal", value: "Internal" },
    { label: "External", value: "External" },
];

export const getCallStatus = () => [
    { label: "Completed", value: "Completed" },
    { label: "NotRecorded", value: "NotRecorded" },
    { label: "All", value: "All" },
]

export const getMeetingDurationOption = () => [
    { label: "< 1 Min", value: "0-1" },
    { label: "1 - 5 Min", value: "1-5" },
    { label: "5 - 15 Min", value: "5-15" },
    { label: "15 - 30 Min", value: "15-30" },
    { label: "30 - 60 Min", value: "30-60" },
    { label: "> 60 Min", value: "60-300" },
    { label: "Custom", value: "custom" },
];
export const getMeetingDurationCustomOption = () => [
    { label: ">=", value: ">=" },
    { label: ">", value: ">" },
    { label: "<=", value: "<=" },
    { label: "<", value: "<" },
    { label: "=", value: "=" },
    // { label: "≠", value: "≠" },
];

export const handleMeetingDurationCustomValue = (label: string, value: string) => {
    if (label === "=") {
        return `${parseFloat(value)*60}-${parseFloat(value)*60}`;
    } else if (label === ">=") {
        return `${parseFloat(value)*60}-18000`;
    } else if (label === ">") {
        return `${parseFloat(value)*60 + 1}-18000`;
    } else if (label === "<=") {
        return `0-${parseFloat(value)*60}`;
    } else if (label === "<") {
        return `0-${parseFloat(value)*60 - 1}`;
    }
}

export const handleMeetingDurationCustomLabel = (props: { numInputValue: string, customOptionValue: { label: string, value: string} }) => {
    const {numInputValue, customOptionValue} = props;
    return `${customOptionValue.label} ${numInputValue} Min`;
}


export const pluck = (key: string) => {
    return (obj: any) => {
        return obj[key];
    };
}


export const getMatrixOptions = () => {
    let metricsFilter: any = {};
    if (FilterPanel?.defaultProps?.groups && FilterPanel?.defaultProps?.groups?.length > 0) {
        let tempGroup = [...FilterPanel?.defaultProps?.groups];

        metricsFilter = [tempGroup[1]]
    }
    return metricsFilter;
}

export const getWhenSaidByOptions = () => [
    { label: "People in company", value: "People in company" },
    { label: "People outside company", value: "People outside company" },
];

export const getTrackInOptions = () => [
    { label: "Inbound Dialer", value: "Inbound Dialer" },
    { label: "Outbound Dialer", value: "Outbound Dialer" },
    { label: "Web Conference", value: "Web Conference" },
];

export const getCallScopeOptions = () => [
    { label: "Internal", value: "Internal" },
    { label: "External", value: "External" },
];
