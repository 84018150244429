import {lazy} from 'react';
import {RouteObject} from 'react-router-dom';
import PublicLayout from './layouts/PublicLayout';
import { DefaultLayout } from '../containers';
import UnauthenticatedRoute from '../UnAuthRoute';
import AuthenticatedRoute from '../AuthRoute';
import RequireAuth from '../RequireAuth';
const CreationSuccess = lazy(() => import('../components/CreationSuccess'));
const Login = lazy(() => import('../components/Login'));
const ResetPassword = lazy(() => import('../components/ResetPassword'));
const EmailVerification = lazy(() => import('../components/EmailVerification'));
const AccountCreation = lazy(() => import('../components/AccountCreation'));
const Register = lazy(() => import('../Pages/Register/Register'));
const RegisterWithEmail = lazy(() => import('../Pages/Register/RegisterWithEmail'));
const SharePlay = lazy(() => import('../Pages/SharePlay'));
const Followup = lazy(() => import('../Pages/Followup'));
const Success = lazy(() => import('../Pages/Success'));
const Error = lazy(() => import('../Pages/Error'));
const Page404 = lazy(() => import('../Pages/Page404'));
const Page500 = lazy(() => import('../Pages/Page500'));
const PipedriveIntegration = lazy(() => import('../components/PipedriveIntegration'));
const PipedriveRemoval = lazy(() => import('../components/PipedriveRemoval'));
const Dashboard = lazy(() => import('../components/Dashboard'));
const Deal = lazy(() => import('../components/Deal'));
const DealFullView = lazy(() => import('../components/Deal/DealFullView'));
const Settings = lazy(() => import('../components/Settings/Settings'));
const AccountFullView = lazy(() => import('../components/Account/AccountFullView'));
const Analytics = lazy(() => import('../components/Analytics/Analytics'));
const Playlists = lazy(() => import('../components/Playlists/PaylistThreePaneView'));
const Home = lazy(() => import('../components/Dashboard/Home'));
const Grid = lazy(() => import('../components/Grid'));
const MeetingFull = lazy(() => import('../components/Dashboard/MeetingThreePaneView'));
const Coaching = lazy(() => import('../components/Coaching/Coaching'));
const View = lazy(() => import('../Pages/View'));

export const PublicRoutes = (): RouteObject => {
    return {
        path: '/',
        element: <PublicLayout />,
        children: [
            {
                path: '/accountCreated',
                element: <CreationSuccess />
            },
            {
                path: '/login',
                element: (
                    <UnauthenticatedRoute>
                        <Login />
                    </UnauthenticatedRoute>
                )
            },
            {
                path: '/reset',
                element: <ResetPassword/>
            },
            {
                path: '/verify',
                element: <EmailVerification/>
            },
            {
                path: '/created',
                element: <AccountCreation/>
            },
            {
                path: '/register',
                element: <Register/>
            },
            {
                path: '/registerWithEmail',
                element: <RegisterWithEmail/>
            },
            {
                path: '/play/:id',
                element: <SharePlay/>
            },
            {
                path: '/followup/:id',
                element: <Followup/>
            },
            {
                path: '/success',
                element: (
                    <Success />
                )
            },
            {
                path: '/error',
                element: <Error/>
            },
            {
                path: '/404',
                element: <Page404/>
            },
            {
                path: '/500',
                element: <Page500/>
            },
            {
                path: '/pipedriveintegrate',
                element: (
                    <AuthenticatedRoute>
                        <PipedriveIntegration />
                    </AuthenticatedRoute>
                )
            },
            {
                path: '/pipedriveremove',
                element: (
                    <AuthenticatedRoute>
                        <PipedriveRemoval />
                    </AuthenticatedRoute>
                )
            },
        ]

    }
}

export const PrivateRoutes = (): RouteObject => {
    return {
        path: '/',
        element: (
            <RequireAuth>
                <DefaultLayout />
            </RequireAuth>
        ),
        children: [
            {
                path: '/meetings/:id?',
                element: <Dashboard />
            },
            {
                path: '/deals',
                element: <Deal />
            },
            {
                path: '/meetings-full',
                element: <MeetingFull />
            },
            {
                path: '/home',
                element: <Home />
            },
            {
                path: '/grid',
                element: <Grid />
            },
            {
                path: '/settings',
                element: <Settings />
            },
            {
                path: '/deals-full',
                element: <DealFullView />
            },
            {
                path: '/accounts',
                element: <AccountFullView />
            },
            {
                path: '/analytics',
                element: <Analytics />
            },
            {
                path: '/coaching',
                element: <Coaching />
            },
            {
                path: '/playlists/:id?',
                element: <Playlists />
            },
            {
                path: '/view/:id',
                element: <View />
            },
            {
                path: '/',
                element: <Home />
            },
        ]
    }
}