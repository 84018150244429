import React from "react";
import { FormGroup, Label, Tooltip } from "reactstrap";
import info from "../../../assets/img/vector.svg";
import FilterSelect from "./FilterSelect";
import FilterSwitch from "./FilterSwitch";
import FilterDateRange from "./FilterDateRange";
import FilterInput from "./FilterInput";
import FilterTeaxtArea from "./FilterTeaxtArea";
import FilterGroup from "./FilterGroup";
import FilterLabel from "./FilterLabel";
import FilterSlider from "./FilterSlider";
import FilterCustomDateRange from "./FilterCustomDateRange";

const Components = {
  Input: FilterInput,
  Select: FilterSelect,
  Group: FilterGroup,
  Switch: FilterSwitch,
  // Range: FilterRange,
  DateRange: FilterDateRange,
  Label: FilterLabel,
  TextArea: FilterTeaxtArea,
  Range: FilterSlider,
  DateRangePicker: FilterCustomDateRange,
};

export default (block) => {
  const [tooltipOpen, setTooltipOpen] = React.useState("");
  const toggle = (e) =>
    setTooltipOpen(e?.target?.id === tooltipOpen ? "" : e?.target?.id);

  if (block.hidden) {
    return <></>;
  }
  if (typeof Components[block.component_name] !== "undefined") {
    return (
      <FormGroup
        row
        key={"Key" + block.id}
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "1rem",
        }}
      >
        <Label
          size="lg"
          style={{
            fontSize: block?.showAsDropdown ? 10 : 14,
            fontWeight: 500,
            paddingBottom: 0,
            marginBottom: 0,
            color: block?.showAsDropdown ? "black" : "#6d7487",
          }}
          htmlFor={block.id}
        >
          <span>{block.label}</span>
          {block.toolTipText && (
            <span>
              <img
                src={info}
                style={{
                  cursor: "pointer",
                  marginLeft: "5px",
                  marginBottom: "6px",
                }}
                id={block.id}
                alt=""
              />
              <Tooltip
                placement="bottom"
                isOpen={tooltipOpen === block.id}
                target={block.id}
                toggle={toggle}
              >
                {block.toolTipText}
              </Tooltip>
            </span>
          )}
        </Label>
        {React.createElement(Components[block.component_name], block)}
      </FormGroup>
    );
  }
  return React.createElement(
    () => <div>The component {block.component_name} not registred yet.</div>,
    { key: block.id }
  );
};
