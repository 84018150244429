import React, {useEffect, useRef, useState} from "react";
import {ISelectFilterItemProps} from "./FilterPanel";
import Select, {components} from "react-select";
import {DocumentNode} from "graphql";
import {useListQuery} from "../../../graphql/queries/FilterQueries";
import {callAxiosService} from "../../../services/callAxios";
import {useAppContext} from "../../../hooks";
import {sortBy} from "../../../utils/CollectionUtil";
import {titleCase} from "../../../utils/StringUtil";

export function getProviderByType(userObj: any, providerType: string): any {
    var provider = null;
    if (userObj.providers) {
        provider = userObj.providers.find((p: { service: string; }) => p.service === providerType);
    }
    if (provider == null && userObj.customer && userObj.customer.providers) {
        provider = userObj.customer.providers.find((p: { service: string; }) => p.service === providerType);
    }
    return provider;
}

export function getProviderByName(userObj: any, providerName: string): any {
    var provider = null;
    if (userObj.providers) {
        provider = userObj.providers.find((p: { provider: string; }) => p.provider === providerName);
    }
    if (provider == null && userObj.customer && userObj.customer.providers) {
        provider = userObj.customer.providers.find((p: { provider: string; }) => p.provider === providerName);
    }
    return provider;
}

export const GetTopics = async (user?: any) => {
    let array = user.customer?.topics.map((track: any) => {
        return {name: track.name, code: track.name};
    });

    if (user.customer.trackers) {
        user.customer.trackers.forEach((track: any) => {
            if (!user.customer?.topics.some(x => x.name === track?.topics)) {
                array.push({name: track?.topics, code: track?.topics});
            }
        });
    }
    // Remove duplicates by creating a new array with unique values
    const uniqueArray = Array.from(
        new Set(
            array.map(a => JSON.stringify(a)) // Convert objects to strings using JSON.stringify() and map through the array
        )
    ).map(a => JSON.parse(a as string));

    return sortBy(uniqueArray, "name", "string");
};

export const GetStage = (user?: any, pipelineId?: any): any => {
    return new Promise((resolve, reject) => {
        var provider: any = null;
        if (user.customer && user.customer.providers) {
            provider = user.customer.providers.find((p: { service: string; provider: string }) => (p.service === "CRM"));
        }

        // look for CRM provider under custoemr first as Salesforce has a salesforceUser CRM provider as well.
        if (provider) {
            let stages = provider.stages;
            if (pipelineId) {
                if (provider.pipeline && provider.pipeline.length > 0) {
                    let pipeline = provider.pipeline.find(x => x.pipelineId === pipelineId)
                    if (pipeline) {
                        stages = pipeline.stages;
                    }
                }
            } else {
                if (provider.pipeline && provider.pipeline.length > 0) {
                    stages = provider.pipeline[0].stages;
                }
            }
            resolve(stages ? stages : []);
        } else {
            if (provider == null && user.providers) {
                provider = user.providers.find((p: { service: string; provider: string }) => (p.service === "CRM"));
            }
            if (provider) {
                let stages = provider.stages;
                if (pipelineId) {
                    if (provider.pipeline && provider.pipeline.length > 0) {
                        let pipeline = provider.pipeline.find(x => x.pipelineId === pipelineId)
                        if (pipeline) {
                            stages = pipeline.stages;
                        }
                    }
                } else {
                    if (provider.pipeline && provider.pipeline.length > 0) {
                        stages = provider.pipeline[0].stages;
                    }
                }
                resolve(stages ? stages : []);
            } else
                resolve([]);
        }
    });
}

export const GetLeadSources = (user?: any): any => {
    return new Promise((resolve, reject) => {
        // let provider = getProviderByType(user, "CRM");
        var provider: any = null;
        if (user.customer && user.customer.providers) {
            provider = user.customer.providers.find((p: { service: string; provider: string }) => (p.service === "CRM"));
        }
        if (provider) {
            let leadSources = provider?.leadSources;
            resolve(leadSources ? leadSources : []);
        } else {
            if (provider == null && user.providers) {
                provider = user.providers.find((p: { service: string; provider: string }) => (p.service === "CRM"));
            }
            if (provider) {
                let leadSources = provider?.leadSources;
                resolve(leadSources ? leadSources : []);

            } else
                resolve([]);
        }
    });


}

export const getMeetingProviders = (user?: any): any => {
    return new Promise((resolve, reject) => {
        var provider: any = null;
        var providers: any = [];
        if (user.providers) {
            provider = user.providers.find((p: { service: string; provider: string }) => (p.provider === "google"));
            if (provider)
                providers.push("Google");
            provider = user.providers.find((p: { service: string; provider: string }) => (p.service === "Dialer"));
            if (provider) {
                if (provider.provider.toLowerCase() === "openphone")
                    providers.push("OpenPhone")
                else if (provider.provider.toLowerCase() === "ringcentral")
                    providers.push("RingCentral")
                else
                    providers.push(provider.provider);
            }
        }
        if (provider == null && user.customer && user.customer?.providers) {
            provider = user.customer.providers.find((p: { service: string; provider: string }) => (p.provider === "zoom"));
            if (provider)
                providers.push("Zoom");
            provider = user.customer.providers.find((p: { service: string; provider: string }) => (p.service === "Dialer"));
            if (!provider) {
                providers.push("Chrome Extension");
                providers.push("Uploaded");
                resolve(providers);
            }
            if (provider && provider.provider === "hubspot dialer")
                providers.push("Hubspot");
            else
                providers.push(titleCase(provider.provider));
        }
        providers.push("Chrome Extension");
        providers.push("Uploaded");
        resolve(providers);
    });
}


export const GetCallDispositions = (user?: any): any => {
    return new Promise((resolve, reject) => {
        var provider: any = null;
        if (user.customer && user.customer.providers) {
            provider = user.customer.providers.find((p: { service: string; provider: string }) => (p.service === "Dialer"));
        }
        let callDispositions = [];
        if (provider) {
            callDispositions = provider?.callDisposition;
            if (callDispositions && callDispositions.length > 0)
                resolve(callDispositions ? callDispositions : []);
            else {
                if (provider == null && user.providers) {
                    provider = user.providers.find((p: { service: string; provider: string }) => (p.service === "Dialer"));
                }
                if (provider) {
                    callDispositions = provider?.callDisposition;
                    if (callDispositions && callDispositions.length > 0)
                        resolve(callDispositions ? callDispositions : []);
                    else
                        resolve([]);
                }
                resolve([]);
            }
        } else {
            if (provider == null && user.providers) {
                provider = user.providers.find((p: { service: string; provider: string }) => (p.service === "Dialer"));
            }
            if (provider) {
                callDispositions = provider?.callDisposition;
                if (callDispositions && callDispositions.length > 0)
                    resolve(callDispositions ? callDispositions : []);
                else
                    resolve([]);
            }
            resolve([]);
        }
    });
}

export function GetCRMUsers(user?: any): Promise<any> {
    let provider = getProviderByType(user, "CRM");

    if (!provider)
        return Promise.resolve([]);

    return callAxiosService(provider.provider + "/fetch/users").then((axiosData) => {
        return Promise.resolve(axiosData);
    });
}

export function GetSlackChannels(user?: any): Promise<any> {
    let provider = getProviderByName(user, "slack");

    if (!provider)
        return Promise.resolve([]);

    return callAxiosService(provider.provider + "/webhook/channels").then((axiosData) => {
        return Promise.resolve(axiosData);
    });
}

export function GetSalesForceUsers(): Promise<any> {
    return callAxiosService("salesforce/fetch/users").then((axiosData) => {
        return axiosData;
    });
}

export const defaultSelectProps: ISelectFilterItemProps<any> & {
    items: any[];
} = {
    id: "select",
    optionName: "name",
    optionCode: "id",
    mode: "Multi",
    items: [],
    label: "",
    component_name: "Select",
    itemFieldName: "",
    text: "",
    sortBy: "",
    selectedItems: [],
};
const TopFilter: any = ["TOPICS", "TRACKERS", "STAGE", "AMOUNT", "CLOSE DATE", "OWNER", "ACCOUNT NAME",
  "LAST UPDATED DATE", "REP", "TEAM", "MEETING DATE RANGE", "CRM", "MEETING DURATION", "DEAL STAGE AT TIME OF MEETING",
  "CALL CATEGORY", "CALL TYPE", "CALL SCOPE", "PARTICIPANTS", "CALL DISPOSITIONS", "MANAGER"]

const FilterSelect: React.FC<ISelectFilterItemProps<any>> = (
    props: ISelectFilterItemProps<any>
) => {
    const [data, setData] = useState([{value: "", label: ""}]);
    const called = useRef(false);
    const {user} = useAppContext();

    let items = props.items;
    let itemFieldName = props.itemFieldName || "";
    let {loading, data: queryData, error} = useListQuery(
        props.queryDoc as DocumentNode,
        props.queryFilter
    );
    useEffect(() => {
        if (!props.items) return;
        if (props.items.length === 0) return;
        let options =
            props.items &&
            props.items.map((o: any, i) => {
                return {value: getCode(o), label: getLabel(o)};
            });
        setData(options);
    }, [props.items]);
    useEffect(() => {
        if (!items) return;
        if (items.length === 0) return;
        let options =
            items &&
            items.map((o: any, i) => {
                return {value: getCode(o), label: getLabel(o)};
            });
        setData(options);
    }, [props.queryDoc, queryData]);
    useEffect(() => {
        if (loading) return;
        if (queryData) {
            let items = itemFieldName.split(".");
            let data = queryData[itemFieldName];
            if (items.length > 1) {
                data = queryData[items[0]][items[1]];
            }
            let options = data?.map((o: any, i: any) => {
                return {value: getCode(o), label: getLabel(o)};
            });
            setData(options);
        }
    }, [props.queryDoc, queryData]);

    useEffect(() => {
        if (props.dataProvider && !called.current) {
            called.current = true;
            props
                .dataProvider(user)
                .then((axiosData) => {
                    let data = axiosData;
                    if (props.sortBy) {
                        let sortBy: any = props.sortBy;
                        let keys = Object.keys(data[0]);
                        let acc = props.sortBy.split("|").reduce((acc, cur) => {
                            if (keys.includes(cur)) {
                                acc = cur;
                            }
                            return acc;
                        }, "")
                        if (acc) {
                            data = data.sort((a: any, b: any) => (a[acc] > b[acc] ? 1 : -1));
                        }
                    }
                    let options = data.map((o: any, i: any) => {
                        return {value: getCode(o), label: getLabel(o)};
                    });

                    /*.then((axiosData) => {
                      let data = axiosData.sort((function(a, b){
                        return a.email > b.email ? 1 : -1;
                    }));

                      let options = data.map((o: any, i: any) => {
                        return {value: {FirstName : o.FirstName, LastName : o.LastName, email: o.email}, label: o.email};
                      });*/
                    setData(options);
                })
                .catch((err) => {
                    console.log(err.message);
                });
        }
    }, [props.dataProvider]);

    function getCode(o: { [x: string]: any; }) {
        if (props.optionCode && o instanceof Object) {
            return o[props.optionCode];
        }
        return o;
    }

    function getLabel(o: { [x: string]: any; }) {
        if (props.optionName && o instanceof Object) {
            let names = props.optionName.split(",");
            if (names.length > 1) {
                let newName = "";
                names.forEach(
                    (name) => (newName = newName + (o[name.trim()] ? (newName ? " " : "") + o[name.trim()] : ""))
                );
                return newName;
            }
            return o[props.optionName];
        }
        return o;
    }

    const getDefault = () => {
        if (data && data.length > 1) {
            let selectedValues = props.selectedItems && props.selectedItems.map(x => x.value);
            let option = data.reduce((options: any, o: any, i) => {
                if (selectedValues && selectedValues.includes(o.value)) {
                    options.push(o);
                } else if (
                    props.text &&
                    (props.text === o.label || props.text === o.value)
                ) {
                    options.push(o);
                }
                return options;
            }, []);
            if (props.mode === "Multi") {
                return option;
            } else {
                return option[0];
            }
        }
        return null;
    };

    if (loading)
        return <span>Loading....</span>
    if (error)
        return <span>{error?.message}</span>

    const DropdownIndicator = (props) => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <svg className="down-arrow-1"/>
                </components.DropdownIndicator>
            )
        );
    };

    return (
        <Select
            key={"select" + props.id}
            id={props.id}
            // style={{width: "100%", marginBottom: "20px", fontSize: "14px"}} check this
            onChange={(entry) => {
                props.onChangeValue && props.onChangeValue(props.id, entry, undefined);
            }}
            components={{DropdownIndicator}}
            defaultValue={getDefault()}
            isMulti={props.mode === "Multi" ? true : false}
            name={props.id}
            className="basic-multi-select"
            classNamePrefix="select"
            options={data || []}
            isClearable={true}
            theme={(theme) => ({...theme, borderRadius: 16})}
            value={props.text}
        />
    )
};

FilterSelect.defaultProps = defaultSelectProps;
export default FilterSelect;
