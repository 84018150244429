import { useEffect, useRef } from "react";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import useAppContext from "./hooks/useAppHook";

export default function AuthenticatedRoute({ children }) {
    const location = useLocation();
    const {pathname, search} = location ?? {pathname: "", search: ""}; // Avoid potential null pointer exception
    const [searchParams] = useSearchParams();
    const tokenFromUrl = searchParams?.get("token");
    const { token, restrictedToken, setParkedURL } = useAppContext() ?? {}; // Avoid potential null pointer exception
    const authRef = useRef(restrictedToken ?? token ?? localStorage.getItem("token"));

    useEffect(() => {
        if (tokenFromUrl) {
            localStorage.setItem("token", tokenFromUrl);
            authRef.current = tokenFromUrl; // Update the ref's current value
        }

        if (authRef.current) {
            setParkedURL?.(`${pathname}${search}`);
        }
    }, [tokenFromUrl, restrictedToken, token, pathname, search]);

    // If not authenticated, redirect to the login page with a redirect URL
    if (!authRef.current) {
        return <Navigate to={`/login?redirect=${pathname}${search}`} replace />;
    }

    // If authenticated, render the children components
    return children ? children : null; // Avoid potential null pointer exception
}
