import React from "react";
import { Navigate } from "react-router-dom";
import useAppContext from "./hooks/useAppHook";
import { querystring } from "./utils/Utility";

export default function UnauthenticatedRoute({ children }) {
  const { user } = useAppContext();
  let redirect = querystring("redirect") || "/home";

  // If the user is authenticated, redirect to the specified path or default to "/home"
  if (user && user.id) {
    return <Navigate to={redirect} replace />;
  }

  // If the user is not authenticated, render the children components
  return children;
}
