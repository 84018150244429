import { useMutation } from "@apollo/client";
import { EDIT_USER,UPDATE_PROFILE } from "../ql/schema";
import { editUser, editUserVariables, updateProfile, updateProfileVariables } from "../ql/operation-result-types";

export const UpdateUser = () => {
    const [user] = useMutation<
        editUser,
        editUserVariables
    >(EDIT_USER, {
        onCompleted(data: editUser): void {
            if (data != null) {
                // return data.createMeeting;
            }
        },
        onError(e: any): void {
            console.log(e);
        },
    });
    return user;
}

export const UpdateProfile = () => {
    const [user] = useMutation<
        updateProfile,
        updateProfileVariables
    >(UPDATE_PROFILE);
    return user;
}
