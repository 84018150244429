import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import {
  Button, Modal, ModalBody, ModalFooter, ModalHeader,
  InputGroup, Input,
} from 'reactstrap';

const CustomSelect = (props) => {
  const {
    // CustomSelect Props
    customOptions,
    customTitle,
    handleCustomLabel,
    handleCustomValue,
    // Selected Props
    options, onChange, value, styles, ...restProps } = props;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [customOptionValue, setCustomOptionValue] = useState(customOptions ? customOptions[0] : null);
  const [numInputValue, setNumInputValue] = useState('');

  useEffect(() => {
    setCustomOptionValue(customOptions && customOptions[0]);
    setNumInputValue('');
  }, [menuIsOpen])

  const handleOptionSelect = (selectedOption) => {
    if (selectedOption?.label === 'Custom') {
      setModalIsOpen(true);
    } else {
      onChange(selectedOption);
      setMenuIsOpen(false)
    }
  };

  const handlePopupApply = () => {
    const customInputs = { numInputValue: numInputValue, customOptionValue: customOptionValue }

    const customValue = handleCustomValue ? handleCustomValue(customInputs) : "custom";
    const customLabel = handleCustomLabel ? handleCustomLabel(customInputs) : "Custom";
    onChange({ label: customLabel, value: customValue });
    setModalIsOpen(false);
    setMenuIsOpen(false);
  };

  const handleModalClose = () => {
    setModalIsOpen(false);
  };

  const handleMenuClose = () => {
    if(modalIsOpen) return;
    setMenuIsOpen(false);
  };

  const customStyles = {
    control: styles => ({
      ...styles,
      boxShadow: 'none',
      color: "#2D323C",
      borderRadius: "16px"
    }),
  };

  const customHeader = customTitle ? (customTitle) : ("Custom " + props.placeholder + " Value");

  return (
      <>
        <Select options={options} {...restProps}
                styles={{
                  ...styles,
                  clearIndicator: (base) => ({
                    ...base,
                    ...styles.clearIndicator,
                    padding: 0,
                  }),
                }}
                value={value ? value : null}
                onChange={(event) => handleOptionSelect(event)}
                menuIsOpen={menuIsOpen}
                onMenuOpen={() => setMenuIsOpen(true)}
                onMenuClose={handleMenuClose}
                closeMenuOnSelect={false}
        />
        {modalIsOpen && (
            <Modal isOpen={modalIsOpen} toggle={handleModalClose} >
              <ModalHeader style={{border:'none'}}>{customHeader}</ModalHeader>
              <ModalBody>
                <InputGroup>
                  {customOptions && (
                      <div style={{ width: '30%' }}>
                        <Select
                            options={customOptions}
                            value={customOptionValue}
                            onChange={(value) => setCustomOptionValue(value)}
                            styles={customStyles}
                        />
                      </div>
                  )}
                  <div style={{ width: '68%', marginLeft: '2%', display: 'flex', justifyContent: 'center', alignItems: 'center'  }}>
                    <Input
                        type="number"
                        value={numInputValue}
                        onChange={(e) => setNumInputValue(e.target.value)}
                    />
                  </div>
                </InputGroup>
              </ModalBody>
              <ModalFooter style={{border:'none'}}>
                <Button color="primary" onClick={handlePopupApply}>
                  Apply
                </Button>
                <Button color="secondary" onClick={handleModalClose}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
        )}
      </>
  );
};

export default CustomSelect;