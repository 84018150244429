import { ApolloError } from "apollo-boost";
import React, { useEffect, useState } from "react";
import {
  Comments_comments,
  getFullMeetingById_meeting,
  getFullMeetingById_meeting_sources,
  getMyDeals_getMyDeals,
  login_login_user,
  myPlaylists_myPlaylists,
  Transcript_transcript,
  // Transcript_transcript_transcripts,
  upcomingMeetings_upcomingMeetings_account,
  user_user_customer,
  user_user_customer_trackers,
  user_user_customer_topics,
  filterTranscript_filterTranscript_transcripts,
  searchDealsMeetingIdSearchCounts_searchDeals_searchResult,
  searchDealsMeetingIdSearchCounts_searchDeals_searchCountResult,
  myBookmarks_myBookmarks,
  getFullMeetingById,
  searchDealsMeetingIdSearchCountsWithConfig,
} from "../graphql/ql/operation-result-types";
import { useFilteredMeetingsQuery, useUploadFiles } from "../graphql/queries/meeting";
import { DictionaryItem, DictionaryItems } from "../types";
import DownloadToast from '../components/toastComponent/DownloadToast';

export interface ISources extends getFullMeetingById_meeting_sources { }

export interface TopicCounts {
  topicCount: number;
  mentionCount: number;
  percentageSum: number;
  durationSum: number;
}

export interface IMeeting extends getFullMeetingById_meeting { }
export interface IMeetings
    extends searchDealsMeetingIdSearchCounts_searchDeals_searchResult { }
export interface IMeetingCount
    extends searchDealsMeetingIdSearchCounts_searchDeals_searchCountResult { }

// export interface IPartialMeeting extends completedMeetings_completedMeetings {}

export interface IDeal extends getMyDeals_getMyDeals { }

export interface IPlaylist extends myPlaylists_myPlaylists { }


export interface IBookmark extends myBookmarks_myBookmarks { }

export interface ITranscript extends Transcript_transcript { }

export interface ITranscriptInfo
    extends filterTranscript_filterTranscript_transcripts { }

export interface IAccount extends upcomingMeetings_upcomingMeetings_account { }

export interface IAppContext {
  user: User;
  token: string | null;
  restrictedToken: string | null;
  colors: DictionaryItem<string>;
  getHighestTime(): number;
}

export interface Seeking {
  time: Number;
  status: boolean;
}

export interface User extends login_login_user { }

export interface Customer extends user_user_customer { }

export interface IComment extends Comments_comments { }

export interface ITracker extends user_user_customer_trackers { }

export interface ITopic extends user_user_customer_topics { }

export class AppDefaultContext {
  msalInstance: any;
  constructor() {
    let token = localStorage.getItem("token") || undefined;
    let restrictedToken = localStorage.getItem("restrictedToken") || undefined;
    let userName = localStorage.getItem("userName") || undefined;
    this.token = token ? token : "";
    this.restrictedToken = restrictedToken ? restrictedToken : "";
    this.msalInstance = null;
    this.user.login = {
      userName: null,
      id: null,
      loginAttempts: 0,
      lockUntil: null,
      password: "",
      lastLogin: null
    };
    this.user.login.userName = userName ? userName : null;
    this.photoUrl = undefined;
    this.user.license = "Free";
    this.chargeBee = null;
    this.meetingActiveTab = '1'; // 1807
    this.filterData = {};
    this.navMeetId = null;
    this.setSaved = false;
    this.partkedURL = "";
  }
  chargeBee: any;
  meetingActiveTab: any; // 1807
  token: string | null;
  restrictedToken: string | null;
  photoUrl: string | undefined;
  filterData: Object;
  navMeetId: number | null;
  setSaved: Boolean | string;
  partkedURL: String;
  user: User = {
    license: "Free",
    firstName: "",
    lastName: "",
    email: "",
    id: null,
    customer: null,
    providers: [],
    designation: "",
    phone: "",
    bioMetricUrl: "",
    role: null,
    teams: null,
    photoUrl: null,
    status: "Active",
    syncOn: true,
    states: [],
    login: {
      userName: null,
      id: null,
      loginAttempts: 0,
      lockUntil: null,
      password: "",
      lastLogin: null
    },
    createdAt: null,
    updatedAt: null,
    emailVerificationToken: "",
    trialStarted: null,
    trialEnded: null,
    subscriptionId: null,
    transcriptLanguage: "English",
    allowNotetaker: false,
    showAsk: false,
    licenseConfig : {
      id: null,
      licenseType: "",
      transcriptionMax: 0,
      numberOfUser: 0,
      enterpriseZoom: 0,
      bot : false,
      upload : false,
      dialer: false,
      chromeExtension: false,
      allowedVideoStorage: 0,
      allowedAudioStorage: 0
    }
  };
}
export const appDefaultContext = new AppDefaultContext();
const AppContext: React.Context<any> = React.createContext<any>(
    appDefaultContext
);
const AppContextProvider: any = (props: any) => {
  appDefaultContext.chargeBee = props.chargeBee;
  appDefaultContext.meetingActiveTab = props.meetingActiveTab; // 1807
  const [state, setState] = useState(appDefaultContext);
  useEffect(() => {
    setState((prvState: any) => ({
      ...state,
      chargeBee: appDefaultContext.chargeBee,
      meetingActiveTab: appDefaultContext.meetingActiveTab // 1807
    }));
  }, []);
  return (
      <AppContext.Provider value={[state, setState]}>
        {props.children}
      </AppContext.Provider>
  );
};

const AppContextConsumer=AppContext.Consumer; // 1807

export class SelectedTranscript {
  transcript: ITranscriptInfo | undefined;
  userSelectedTranscript: Boolean = false;
  disable?: Boolean = false;
}

export class ObjectDefaultContext {
  currentModule: String = "Meeting";
  currentTab: String = "General";
  currentLeftPanel: String = "Completed";
  meetings: IMeeting[] = [];
  searchMeetings: IMeetings[] = [];
  meetingCount: IMeetingCount[] = [];
  transcripts: ITranscript[] = [];
  colors: DictionaryItem<string> = {};
  selectedMeeting: IMeeting = {} as IMeeting;
  redirectMeetingId: String | undefined = undefined;
  selectedTranscript: SelectedTranscript | undefined;
  filterByTranscriptUser: String = "";
  searchedTranscripts: any[] = [];
  showSearch: boolean = false;
  loading: boolean = false;
  autoScroll: boolean = false;
  isPlaying: boolean = false;
  transcriptsWithComment: boolean = false;
  notesData: any[] = [];
  seek: Number = 0;
  seeking: Seeking = {time: 0, status: false};
  timeStampsArray = [];

}

const objectDefaultContext = new ObjectDefaultContext();
const ObjectContext: React.Context<any> = React.createContext<any>(
    objectDefaultContext
);

const ObjectContextProvider: any = (props: any) => {
  const [state, setState] = useState(objectDefaultContext);
  return (
      <ObjectContext.Provider value={[state, setState]}>
        {props.children}
      </ObjectContext.Provider>
  );
};

export class TranscriptSearch {
  transcriptUsers: string[] = [];
  transcriptListenToUsers: string[] = [];
  searchTerm: string = "";
  listenType: string = "Questions";
}
export const defaultTranscriptFilter = {
  transcriptUsers: [],
  transcriptListenToUsers: [],
  searchTerm: "",
  listenType: "Questions",
};
export function getDefaultTranscriptFilter() {
  let defaultTransFilter = {...defaultTranscriptFilter};
  defaultTransFilter.transcriptListenToUsers = [];
  defaultTransFilter.transcriptUsers =[];
  return defaultTransFilter;
}

export class DefaultCategoryContext {
  categories: DictionaryItems<any> = {};
  topics: DictionaryItems<any> = {};
  colors: DictionaryItem<string> = {};
  byCategories: boolean = false;
  askOpen: boolean = false;
  fitlerByComments: boolean = false;
  transcriptFilter: TranscriptSearch = defaultTranscriptFilter;
}
const defaultCategoriesContext = new DefaultCategoryContext();
const CategoryContext: React.Context<any> = React.createContext<any>(
    defaultCategoriesContext
);

const CategoryContextProvider: any = (props: any) => {
  const [state, setState] = useState(defaultCategoriesContext);
  return (
      <CategoryContext.Provider value={[state, setState]}>
        {props.children}
      </CategoryContext.Provider>
  );
};

export class DefaultBehaviourContext {
  expandLeftPanel: boolean = false;
  hideMeetingPanel: boolean = false;
  showSearchPanel: boolean = false;
  hideMiddlePanel: boolean = false;
  hideRightPanel: boolean = false;
  hideContentPanel: boolean = false;
  hideContentHeaderPanel: boolean = false;
  meetingHiddenColumns: {} = { hiddenColumns: ["host", "providerMeetingId"] };
  searchText: string = "";
  transSearch: string = "";
  searchFilterOn: boolean = false;
  currentLeftTab: string = "1";
  meetingSearchTerms: any;
  loading: boolean = false;
  showExtraLeftPanel: string = "";
  isOpenTranscriptComment: boolean = false;
  notesData: any = [];
  vidioFullExpanded : boolean = false;
  transcriptRow : boolean = false;
  isFilterTopNav : boolean = true;
  loadingMeetingCount: boolean = false;
}
const defaultBehaviourContext = new DefaultBehaviourContext();
const BehaviourContext: React.Context<any> = React.createContext<any>(
    defaultBehaviourContext
);

const BehaviourContextProvider: any = (props: any) => {
  const [state, setState] = useState(defaultBehaviourContext);
  return (
      <BehaviourContext.Provider value={[state, setState]}>
        {props.children}
      </BehaviourContext.Provider>
  );
};

export class DefaultDealContext {
  deals: IDeal[] = [];
}

const defaultDealContext = new DefaultDealContext();
const DealContext: React.Context<any> = React.createContext<any>(
    defaultDealContext
);

const DealContextObject: any = (props: any) => {
  const [state, setState] = useState(defaultDealContext);
  return (
      <DealContext.Provider value={[state, setState]}>
        {props.children}
      </DealContext.Provider>
  );
};

export interface IFilteredMeetingsQueryContext {
  doRequestForMeetings?: any;
  reload?: any;
  error?: ApolloError | undefined;
  loading?: boolean;
  fetchMoreData?: any;
  loadingMoreData?: boolean;
  loading1?: boolean;
  data1?: getFullMeetingById | undefined;
  data2?: searchDealsMeetingIdSearchCountsWithConfig | undefined;
}

const defaultFilteredMeetingsQueryContext: IFilteredMeetingsQueryContext = {};

export const FilteredMeetingsQueryContext = React.createContext(defaultFilteredMeetingsQueryContext);

const FilteredMeetingQueryContextProvider = ({children}) => {

  let {doRequestForMeetings, reload, error, loading, loading1, data1, data2, fetchMoreData, loadingMoreData} = useFilteredMeetingsQuery();

  useEffect(() => {
    reload.current = true;
  }, []);

  return (
      <FilteredMeetingsQueryContext.Provider value={{
        doRequestForMeetings, reload, error, loading, fetchMoreData, loadingMoreData, loading1, data1, data2
      }}>
        {children}
      </FilteredMeetingsQueryContext.Provider>
  );

}

export interface UploadContextProps {
  isUploading?: boolean;
  uploadMeetingFiles?: any;
}

const defaultUploadContext: UploadContextProps = {};

export const UploadContext = React.createContext(defaultUploadContext);

const UploadContextProvider = ({children}) => {
  const [uploadToastOpen, setUploadToastOpen] = useState(false);  
  let { isUploading, uploadingFiles, uploadMeetingFiles } = useUploadFiles({onUploadFiles: () => setUploadToastOpen(true)}); 

  useEffect(() => {
    const preventDefault = (event: BeforeUnloadEvent) => event.preventDefault();
    if (isUploading) window.addEventListener('beforeunload', preventDefault);
    return () => {
      window.removeEventListener('beforeunload', preventDefault);
    };
  }, [isUploading]);

  return (
      <UploadContext.Provider value={{ isUploading, uploadMeetingFiles }}>
        {children}
        <DownloadToast title={'Uploading Files'}
          open={uploadToastOpen}
          onClose={() => setUploadToastOpen(false)}
          files={uploadingFiles}
        />
      </UploadContext.Provider>
  );

}

export {
  AppContext,
  DealContextObject,
  ObjectContext,
  ObjectContextProvider,
  AppContextProvider,
  CategoryContext,
  CategoryContextProvider,
  BehaviourContext,
  BehaviourContextProvider,
  AppContextConsumer,
  FilteredMeetingQueryContextProvider,
  UploadContextProvider,
};