import React from "react";
import {IFilterItemProps} from "./FilterPanel";
import {Label} from "reactstrap";

export const defaultRangeProps: IFilterItemProps = { id: "", label: "", component_name: "Label", text:""};
const FilterLabel: React.FC<IFilterItemProps> = (props: IFilterItemProps) => {
  return (
    <div>
      <Label style={{fontSize:14, fontWeight:500}} id={"Label" + props.id} className={props.className} >{props.text}</Label>
    </div>
  );
}

FilterLabel.defaultProps = defaultRangeProps;
export default FilterLabel;