import {ErrorMessage, Field, Formik} from "formik";
import React, {useEffect, useRef, useState} from "react";
import {Button, Col, Input, Row} from "reactstrap";
import {AudioRecorder} from "../core/AudioRecorder";
import {callAxiosServiceWithParam, postAxiosService} from "../../services/callAxios";
import {useAppContext} from "../../hooks";

const GeneralSettings = (props) => {
    const recordRef = useRef(null);
    const {user, setUser} = useAppContext();
    const [biometricUrl, setBiometricUrl] = useState(props.bioMetricUrl);
    const [message, setMessage] = useState("");
    const [isError, setIsError] = useState(false);
    const [values, setValues] = useState(props.initialValues);

    const submitForm = async (values) => {
        var formData = new FormData();
        if (recordRef.current) {
            if (!(recordRef.current as any).state.blob) {
                setIsError(true)
                setMessage("Please record the voice biometric.");
                return;
            }
            formData.append("file", (recordRef.current as any).state.blob);
        }
        formData.set("userId", user.id);
        formData.set("customerId", user.customer.id);
        setIsError(true);
        setMessage("Wait for file upload...");
        let data1 = await postAxiosService("users/uploadFile", formData);
        if (data1.data === "success") {
            setIsError(false);
            const updatedValues = { ...values };
            // values.bioMetricUrl = user?.customer?.id + "/biometrics/" + user?.id;
            updatedValues.bioMetricUrl = user?.customer?.id + "/biometrics/" + user?.id;
            setValues(updatedValues);
            formData.set("bioMetricUrl", values.bioMetricUrl);
            setMessage("Successfully updated");
        } else {
            setIsError(true)
            setMessage("upload failed");
        }
        // submitRecording
        props.handleSubmit(values);
    };

    async function fetchData() {
        if (!biometricUrl?.startsWith("http")) {
            let url = user?.customer?.id + "/biometrics/" + user?.id;
            let filePath = await callAxiosServiceWithParam(
                "users/signedurl",
                "filepath=" + encodeURIComponent(url)
            )
            if (!filePath || filePath.data == '') {
                return Promise.resolve("");
            } else {
                // user.bioMetricUrl = url;
                const updatedUser = { ...user, bioMetricUrl: url };
                setUser(updatedUser)
                setBiometricUrl(filePath.data)
                return Promise.resolve("");
            }
        }
        return Promise.resolve("");
    }

    useEffect(() => {

        if (!biometricUrl && isError) {
            setIsError(true);
            setMessage("Please record the voice biometric");
        } else {
            fetchData();
            setMessage('');
        }
    }, [props.toggleError, biometricUrl])

    return (
        <>
            {message && isError && <h3 style={{color: "red"}}> {message} </h3>}
            {message && !isError && <h3 style={{color: "green"}}> {message} </h3>}
            <Row>
                <Col sm="12">
                    <Formik
                        enableReinitialize={true}
                        validationSchema={props.validationSchema}
                        validateOnBlur={true}
                        validateOnMount={true}
                        isInitialValid={false}
                        initialValues={props.initialValues}
                        onSubmit={(values, {setSubmitting}) => {
                            if (props.validationSchema.isValid(values)) {
                                submitForm(values);
                            }
                        }}
                    >
                        {({
                              values,
                              touched,
                              errors,
                              isSubmitting,
                              validateField, validateForm,
                              handleChange,
                              handleBlur,
                              handleSubmit
                          }) => {
                            const getProps = (name) => ({
                                name,
                                id: name,
                                value: values[name],
                                onChange: handleChange,
                                error: touched[name] && errors[name] !== undefined,
                                onBlur: handleBlur
                            });
                            return (
                                <form onSubmit={handleSubmit} onLoad={() => validateForm()}>
                                    <Row>
                                        <>
                                            <Col>
                                                <div className="form-group">
                                                    {(!props.isOnboardingFlow || (props.isOnboardingFlow && (!user?.firstName))) ? <>
                                                            <label className="label">First name *</label>
                                                            <Input autoFocus type="text"
                                                                   style={props.isOnboardingFlow && !user?.firstName ?
                                                                       {
                                                                           fontSize: "14px",
                                                                           color: "#2d323c",
                                                                           border: "2px solid red"
                                                                       } :
                                                                       {fontSize: "14px", color: "#2d323c",}}
                                                                   placeholder="First Name" {...getProps("firstName")}
                                                            /></>
                                                        : (props.isOnboardingFlow && user?.firstName) ? <></> :
                                                            <Input autoFocus type="text"
                                                                   style={{fontSize: "14px", color: "#2d323c",}}
                                                                   placeholder="First Name" {...getProps("firstName")}
                                                            />
                                                    }
                                                </div>
                                                <ErrorMessage name="firstName"/>
                                            </Col>
                                            <Col>
                                                <div className="form-group">
                                                    {(!props.isOnboardingFlow || (props.isOnboardingFlow && (!user?.lastName))) ?
                                                        <>
                                                            <label className="label">Last name *</label>
                                                            <Input autoFocus type="text"
                                                                   style={props.isOnboardingFlow && !user?.firstName ?
                                                                       {
                                                                           fontSize: "14px",
                                                                           color: "#2d323c",
                                                                           border: "2px solid red"
                                                                       } :
                                                                       {fontSize: "14px", color: "#2d323c",}}
                                                                   placeholder="Last Name"  {...getProps("lastName")}
                                                            /></>
                                                        : (props.isOnboardingFlow && user?.lastName) ? <></> :
                                                            <Input autoFocus type="text"
                                                                   style={{fontSize: "14px", color: "#2d323c",}}
                                                                   placeholder="First Name" {...getProps("lastName")}
                                                            />
                                                    }
                                                </div>
                                                <ErrorMessage name="lastName"/>
                                            </Col>
                                        </>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="form-group">
                                                <label className="label">Phone Number *</label>
                                                {(!props.isOnboardingFlow || props.isOnboardingFlow && !user?.phone) ?
                                                    <Input autoFocus type="text"
                                                           style={props.isOnboardingFlow && !user?.phone ?
                                                               {
                                                                   fontSize: "14px",
                                                                   color: "#2d323c",
                                                                   border: "2px solid red"
                                                               } :
                                                               {fontSize: "14px", color: "#2d323c",}}
                                                           placeholder="Phone" {...getProps("phone")}
                                                    />
                                                    : <Input autoFocus type="text"
                                                             style={{fontSize: "14px", color: "#2d323c",}}
                                                             placeholder="Phone" {...getProps("phone")}
                                                    />
                                                }
                                            </div>
                                            <ErrorMessage name="phone"/>
                                        </Col>
                                        <Col>
                                            <div className="form-group">
                                                <label className="label">Title *</label>
                                                {!props.isOnboardingFlow || (props.isOnboardingFlow && !user?.designation) ?
                                                    <Input type="text"
                                                           style={props.isOnboardingFlow && !user?.designation ?
                                                               {
                                                                   fontSize: "14px",
                                                                   color: "#2d323c",
                                                                   border: "2px solid red"
                                                               } :
                                                               {fontSize: "14px", color: "#2d323c",}}
                                                           placeholder="Title"
                                                           className={props.error ? "text-input error" : "text-input"}
                                                           {...getProps("designation")}
                                                    />
                                                    : <Input type="text" style={{fontSize: "14px", color: "#2d323c"}}
                                                             placeholder="Title"
                                                             className={props.error ? "text-input error" : "text-input"}
                                                             {...getProps("designation")}
                                                    />
                                                }
                                            </div>
                                            <ErrorMessage name="designation"/>
                                        </Col>
                                    </Row>
                                    {!props.isOnboardingFlow ? <>
                                        <Row>
                                            <Col>
                                                <div className="form-group">
                                                    <label className="label">Email *</label>
                                                    <Input disabled type="text" placeholder="Email"
                                                           style={{
                                                               fontSize: "14px", color: "#2d323c",
                                                               backgroundColor: "rgb(0, 84, 204, 0.05)"
                                                           }}
                                                           {...getProps("email")}
                                                    />
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="form-group">
                                                    <label className="label">Company *</label>
                                                    <Input disabled type="text" style={{
                                                        fontSize: "14px", color: "#2d323c",
                                                        backgroundColor: "rgb(0, 84, 204, 0.05)"
                                                    }}
                                                           placeholder={user.customer?.name}
                                                    />
                                                </div>
                                                <ErrorMessage name="company"/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="form-group">
                                                    <label className="label">Language *</label>
                                                    <Field
                                                        className="form-control"
                                                        as="select"
                                                        {...getProps("transcriptLanguage")}
                                                    >
                                                        <option key={"English"} value={"English"}>English</option>
                                                        <option key={"French"} value={"French"}>French</option>
                                                        <option key={"Canadian French"} value={"Canadian French"}>Canadian French</option>
                                                        <option key={"Spanish"} value={"Spanish"}>Spanish</option>
                                                        <option key={"Latin American Spanish"} value={"Latin American Spanish"}>Spanish</option>
                                                        <option key={"German"} value={"German"}>German</option>
                                                        <option key={"Dutch"} value={"Dutch"}>Dutch</option>
                                                        <option key={"Portuguese"} value={"Portuguese"}>Portuguese</option>
                                                        <option key={"Hindi"} value={"Hindi"}>Hindi</option>
                                                    </Field>
                                                </div>
                                            </Col>
                                        </Row>
                                    </> : <></>}
                                    <div className="form-group">
                                        <label className="label">Voice Pattern Recording *</label>
                                        <p><strong>
                                            Press the <img src={"../../assets/img/record.svg"}
                                                           style={{width: "18px", height: "18px"}}/> button, read
                                            the {" "}
                                            <span style={{
                                                backgroundColor: "rgb(0, 84, 204, 0.05)",
                                                padding: "2px 5px 2px 5px",
                                                border: "0.5px solid #e4e5ee",
                                                borderRadius: 8,
                                                color: "#5c6873"
                                            }}>text in the box</span>
                                            {" "} below slowly and clearly without any background noise.
                                            When done click on the {" "} <img src={"../../assets/img/stoprecord.svg"}
                                                                              style={{
                                                                                  width: "18px",
                                                                                  height: "18px"
                                                                              }}/> button,
                                            press {" "} <img src={"../../assets/img/play.svg"}
                                                             style={{width: "18px", height: "18px"}}/> button to confirm
                                            recording. Click {"  "}
                                            <span style={{
                                                borderRadius: 8,
                                                backgroundColor: "#0052cc",
                                                color: "#fff",
                                                padding: "2px 5px 2px 5px"
                                            }}>Next</span>{"  "} to continue.
                                        </strong></p>
                                        <textarea autoFocus className="form-control" readOnly style={{
                                            height: "160px", fontSize: "14px", resize: "none",
                                            backgroundColor: "rgb(0, 84, 204, 0.05)"
                                        }}>
                                            Rafiki is your AI-powered sales assistant, designed to streamline your communication
                                            workflow. By organizing, analyzing, and summarizing conversations, it frees you to
                                            concentrate on what truly matters: listening intently and engaging effectively.
                                            Rafiki transforms every interaction into an opportunity.
                                        </textarea>
                                        <div className="form-group d-flex align-items-center"
                                             style={{width: "100%", padding: 10}}>
                                            {<AudioRecorder ref={recordRef}
                                                            bioMetricUrl={biometricUrl}></AudioRecorder>}
                                        </div>
                                    </div>
                                    <Button className="btn-submit"
                                            style={{background: "#0052CC", color: "#FFFFFF", float: "right"}}>
                                        {props.isOnboardingFlow ? "Next" : "Submit"}
                                    </Button>
                                </form>)
                        }}
                    </Formik>
                </Col>
            </Row>
        </>
    );
};

export default GeneralSettings;
