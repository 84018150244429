import {useContext} from "react";
import {AppContext, User} from "./../context/AppStore";

const useAppContext: any = () => {
    const [state, setState] = useContext(AppContext);

    const setMeetingActiveTab: any = (meetingActiveTab: string) => {
        setState((prvState: any) => ({...prvState, meetingActiveTab: meetingActiveTab}));
    };
    const setUser: any = (user: User) => {
        setState((prvState: any) => ({...prvState, user: user}));
    };
    const setToken: any = (token: string) => {
        setState((prvState: any) => ({...prvState, token: token}));
    };
    const setPhotoUrl: any = (photoUrl: string) => {
        setState((prvState: any) => ({...prvState, photoUrl: photoUrl}));
    };
    const setRestrictedToken: any = (token: string) => {
        setState((prvState: any) => ({...prvState, restrictedToken: token}));
    };
    const setFilterData: any = (filterData: Object) => {
        const clearedState = {};
        const isCleared =
            JSON.stringify(filterData) === JSON.stringify(clearedState);
        setState((prvState: any) => ({
            ...prvState,
            filterData: isCleared ? {} : {
                ...prvState.filterData,
                ...filterData
            },
            setSaved: isCleared ? "reset" : false,
        }));
    };
    const navigateToMeeting: any = (meetingId: Object) => {
        setState((prvState: any) => ({...prvState, navMeetId: meetingId}));
    };
    const setSavedFilter: any = (setSaved: Boolean) => {
        setState((prvState: any) => ({...prvState, setSaved}));
    };
    const setPartkedURL: any = (partkedURL: Boolean) => {
        setState((prvState: any) => ({...prvState, partkedURL}));
    };
    const setMSalInstance: any = (msalInstance: Object) => {
        setState((prvState: any) => ({...prvState, msalInstance}));
    };
    return {
        user: state.user,
        token: state.token,
        photoUrl: state.photoUrl,
        restrictedToken: state.restrictedToken,
        setToken,
        setRestrictedToken,
        setPhotoUrl,
        setUser,
        setFilterData,
        filterData: state.filterData,
        navigateToMeeting,
        navMeetId: state.navMeetId,
        setSavedFilter,
        setSaved: state.setSaved,
        partkedURL: state.partkedURL,
        setPartkedURL,
        msalInstance: state.msalInstance,
        setMSalInstance,
        meetingActiveTab: state.meetingActiveTab, // 1807
        setMeetingActiveTab, // 1807,
    };
};

export default useAppContext;
