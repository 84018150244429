import React, {useEffect, useState} from "react";
import {IFilterItemProps} from "./FilterPanel";
import {Input} from "reactstrap";

export const defaultRangeProps: IFilterItemProps = { id: "", label: "", component_name: "FilterRange", min: 0, max: 1000 ,text:0};
const FilterRange: React.FC<IFilterItemProps> = (props: IFilterItemProps) => {
  const [state, setState] = useState( props.text);
  useEffect(() => {
    setState(props.text)
  },[props.text])
  return (
    <div>
      <Input
        id={props.id}
        type="range"
        max={props.max}
        step={1}
        value={state}
        text={state}
        min={props.min}
        onChange={(e) => {
          e.persist();
          setState((prvState) => (e.target.value));
          props.onChangeValue && props.onChangeValue(props.id, e.currentTarget.value, e);
        }} />
      <Input key={"input" + props.id}
        type="number"
          value={state} onChange={(e) => {
          e.persist();
          setState((prvState) => (e.target.value ));
        }}></Input>
    </div>
  );
}

FilterRange.defaultProps = defaultRangeProps;
export default FilterRange;