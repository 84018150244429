import React, {HTMLAttributes, useEffect, useState} from "react";
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import {useAppContext} from "../../hooks";
import AppDetails from "./appdetails";
import TwilioModal from "./TwilioModal";
import FreshworksModal from "./FreshworksModal";
import FreshdeskModal from "./FreshdeskModal";
import ModalComponent from "../modalComponent/ModalComponent";
import CloseModal from "./CloseModal";
import OpenPhoneModal from "./OpenPhoneModal";
import KixieModal from "./KixieModal";
import OpenAIModal from "./OpenAIModal";
import TataTeleModal from "./TataTeleModal";
import DialpadModal from "./DialpadModal";

export enum AppCategories {
    CALENDARS = "calendar",
    MEETINGS = "web conferencing",
    SUPPORT = "service desk",
    MESSAGES = "messaging",
    DIALER = "dialer",
    CRM = "crm",
    BOTH = "both",
    OTHERS = "others",
    ALL = "ALL",
}

export interface RenderIntegrationItemProps
    extends HTMLAttributes<HTMLElement> {
    category: AppCategories;
    isOnboardingFlow: boolean;
}

export enum AppProviderStatus {
    INSTALLED = "Installed",
    UNINSTALLED = "UnInstalled"
}

export enum AppProviderState {
    WORKING = "Working",
    EXPIRED = "Expired",
    FAILED = "Failed",
    DISABLED = "Disabled",
}

export interface AppProvider extends HTMLAttributes<HTMLElement> {
    name: String;
    logo: String;
    category: AppCategories[];
    description: String;
    registeredDate?: Date;
    url?: string;
    uninstallUrl?: string;
    status?: AppProviderStatus;
    state?: AppProviderState;
}

// const externalURL = process.env.REACT_APP_EXTERNAL_URL;
export const registeredApps: AppProvider[] = [
    {
        name: "Google",
        logo: "intergration_icons/google_calendar.png",
        title: "Google Calendar",
        category: [AppCategories.CALENDARS],
        url: `${process.env.REACT_APP_EXTERNAL_URL}/google?user=`,
        description:
            "Google Calendar is a time-management and scheduling calendar service developed by Google",
    },
    {
        name: "Zoom",
        logo: "intergration_icons/zoom.png",
        title: "Zoom Meetings",
        url: `${process.env.REACT_APP_EXTERNAL_URL}/zoom?user=`,
        uninstallUrl: "https://marketplace.zoom.us/user/installed?user=",
        category: [AppCategories.MEETINGS],
        description:
            "Start Zoom Meetings with flawless video and crystal clear audio.",
    },
    {
        name: "Salesforce",
        logo: "intergration_icons/salesforce.png",
        title: "Salesforce",
        category: [AppCategories.CRM],
        url: `${process.env.REACT_APP_EXTERNAL_URL}/salesforce?user=`,
        description:
            "Help sales, service, and marketing teams  work better together with a 360° view of your customers.",
    },
    {
        name: "Hubspot",
        logo: "intergration_icons/hubspot.png",
        title: "Hubspot",
        category: [AppCategories.CRM],
        url: `${process.env.REACT_APP_EXTERNAL_URL}/hubspot?user=`,
        description:
            "Uniting software, education, and community to help businesses grow better every day.",
    },
    {
        name: "Zoho",
        logo: "intergration_icons/zoho.png",
        title: "Zoho",
        category: [AppCategories.CRM],
        url: `${process.env.REACT_APP_EXTERNAL_URL}/zoho?user=`,
        description:
            "Manage your sales, marketing and support in one CRM platform.",
    },
    {
        name: "Pipedrive",
        logo: "intergration_icons/pipedrive.png",
        title: "Pipedrive",
        category: [AppCategories.CRM],
        url: `${process.env.REACT_APP_EXTERNAL_URL}/pipedrive?user=`,
        description:
            "When you need to stay laser-focused on the right deals, Pipedrive is here to support you.",
    },
    {
        name: "Hubspot Dialer",
        logo: "intergration_icons/hubspot.png",
        title: "Hubspot Dialer",
        category: [AppCategories.DIALER],
        url: `${process.env.REACT_APP_EXTERNAL_URL}/hubspot?user=`,
        description:
            "Uniting software, education, and community to help businesses grow better every day.",
    },
    {
        name: "GoogleMeet",
        logo: "intergration_icons/googlemeeting.png",
        title: "Google Meet",
        url: `${process.env.REACT_APP_EXTERNAL_URL}/google?user=`,
        uninstallUrl: "",
        category: [AppCategories.MEETINGS],
        description:
            "Using your browser, share your video,  desktop, and presentations with teammates and customers.",
    },
    {
        name: "Microsoft",
        logo: "intergration_icons/microsoftoutlook.png",
        title: "Microsoft Outlook",
        category: [AppCategories.CALENDARS],
        url: `${process.env.REACT_APP_EXTERNAL_URL}/microsoft?user=`,
        description: "Email, calendar, and contacts all in one place.",
    },
    {
        name: "Twilio",
        logo: "intergration_icons/twilio.png",
        title: "Twilio",
        category: [AppCategories.DIALER],
        url: `${process.env.REACT_APP_EXTERNAL_URL}/twilio?user=`,
        description:
            "Engage customers on any channel, any time.",
    },
    {
        name: "Aircall",
        logo: "intergration_icons/aircall.png",
        title: "Aircall",
        category: [AppCategories.DIALER],
        url: `${process.env.REACT_APP_EXTERNAL_URL}/aircall?user=`,
        description:
            "Simple to set up. Easy to use. Cloud-based call center software  connected to your tools.",
    },
    {
        name: "OpenPhone",
        logo: "intergration_icons/openphone.png",
        title: "OpenPhone",
        category: [AppCategories.DIALER],
        url: `${process.env.REACT_APP_EXTERNAL_URL}/openPhone?user=`,
        description:
            "The all-in-one phone system for teams.",
    },
    {
        name: "Outreach",
        logo: "intergration_icons/outreach.png",
        title: "Outreach",
        category: [AppCategories.DIALER],
        url: `${process.env.REACT_APP_EXTERNAL_URL}/outreach?user=`,
        description: "Drive efficient growth with every interaction.",
    },
    {
        name: "RingCentral",
        logo: "intergration_icons/ringcentral.png",
        title: "Ring Central",
        category: [AppCategories.DIALER],
        url: `${process.env.REACT_APP_EXTERNAL_URL}/ringcentral?user=`,
        description:
            "A complete, global cloud communications and collaboration solution.",
    },
    {
        name: "TataTele",
        logo: "intergration_icons/tatatele-logo.png",
        title: "TataTele",
        category: [AppCategories.DIALER],
        url: `${process.env.REACT_APP_EXTERNAL_URL}/tatatele?user=`,
        description:
            "Enable enterprises with connectivity, collaboration, cloud, security and marketing solutions.",
    },
    {
        name: "Freshworks",
        logo: "intergration_icons/freshworks.png",
        title: "Freshworks",
        category: [AppCategories.CRM],
        url: `${process.env.REACT_APP_EXTERNAL_URL}/freshworks?user=`,
        description:
            "Increase the sales velocity of your business.",
    },
    {
        name: "MicrosoftTeams",
        logo: "intergration_icons/microsoftteams.png",
        title: "Microsoft Teams",
        url: ``,
        uninstallUrl: "",
        category: [AppCategories.MEETINGS],
        description:
            "Host smarter meetings that are more productive, more engaging, and more “wow”.",
    },
    {
        name: "WebEx",
        logo: "intergration_icons/webex.png",
        title: "WebEx",
        url: ``,
        uninstallUrl: "",
        category: [AppCategories.MEETINGS],
        description:
            "Host smarter meetings that are more productive, more engaging, and more  “wow”.",
    },
    {
        name: "ZohoMeeting",
        logo: "intergration_icons/zoho-meeting.png",
        title: "Zoho Meeting",
        url: ``,
        uninstallUrl: "",
        category: [AppCategories.MEETINGS],
        description:
            "Online meeting software for all your video conferencing & webinar needs.",
    },
    {
        name: "Slack",
        logo: "intergration_icons/slack.png",
        title: "Slack",
        url: `${process.env.REACT_APP_EXTERNAL_URL}/slack?user=`,
        uninstallUrl: "",
        category: [AppCategories.MESSAGES],
        description: "Slack is where work happens.",
    },
    {
        name: "Close",
        logo: "intergration_icons/close.png",
        title: "Close",
        category: [AppCategories.CRM],
        url:`${process.env.REACT_APP_EXTERNAL_URL}/close?user=`,
        description:
            "High adoption, high performance. Get the all-in-one CRM built just for sales teams.",
    },
    {
        name: "monday.com",
        logo: "intergration_icons/monday-1.svg",
        title: "monday.com",
        category: [AppCategories.CRM],
        url: `${process.env.REACT_APP_EXTERNAL_URL}/monday?user=`,
        description: "A fully customizable, powerful CRM.",
    },
    {
        name: "SalesLoftDialer",
        logo: "intergration_icons/salesloft.png",
        title: "SalesLoft",
        category: [AppCategories.DIALER],
        url: `${process.env.REACT_APP_EXTERNAL_URL}/salesLoftDialer?user=`,
        description: "Helping the world’s top-performing teams  transform the way they sell.",
    },
    {
        name: "Kixie",
        logo: "intergration_icons/kixie.png",
        title: "Kixie",
        category: [AppCategories.DIALER],
        url: `${process.env.REACT_APP_EXTERNAL_URL}/kixie?user=`,
        description: "The #1 advanced power dialer with full CRM integration",
    },
    {
        name: "OpenAI",
        logo: "ChatGPT.svg",
        title: "OpenAI",
        url: `${process.env.REACT_APP_EXTERNAL_URL}/openai?user=`,
        uninstallUrl: "",
        category: [AppCategories.OTHERS],
        description: "Integrate your AI models with OpenAI API.",
    },
    {
        name: "HubspotServiceDesk",
        logo: "intergration_icons/hubspot.png",
        title: "Hubspot Service Desk",
        category: [AppCategories.SUPPORT],
        url: `${process.env.REACT_APP_EXTERNAL_URL}/hubspotServiceDesk?user=`,
        description: "Keep track of customer requests in one unified help desk that connects to your CRM database",
    },
    {
        name: "ZoomPhone",
        logo: "intergration_icons/zoomphone.png",
        title: "ZoomPhone",
        url: `${process.env.REACT_APP_EXTERNAL_URL}/zoomPhone?user=`,
        uninstallUrl: "https://marketplace.zoom.us/user/installed?user=",
        category: [AppCategories.DIALER],
        description:
            "Supercharge business calling with Zoom Phone.",
    },
    {
        name: "ZenDesk",
        logo: "intergration_icons/Zendesk.jpg",
        title: "ZenDesk",
        category: [AppCategories.SUPPORT],
        // url: `${process.env.REACT_APP_EXTERNAL_URL}/zendesk?user=`,
        url: "",
        description: "The complete customer service solution",
    },
    {
        name: "FreshDesk",
        logo: "intergration_icons/freshdesk.png",
        title: "Freshdesk",
        category: [AppCategories.SUPPORT],
        // url: `${process.env.REACT_APP_EXTERNAL_URL}/freshdesk?user=`,
        url: "",
        description: "Scale support experiences without scaling costs",
    },
    {
        name: "Dialpad",
        logo: "intergration_icons/dialpad.png",
        title: "Dialpad",
        category: [AppCategories.DIALER],
        url: `${process.env.REACT_APP_EXTERNAL_URL}/dialpad?user=`,
        description: "Communicate with customers on every channel.",
    },
    {
        name: "LeadSquared",
        logo: "intergration_icons/leadsquared.svg",
        title: "Lead Squared",
        category: [AppCategories.CRM],
        url: "",
        description: "Shorten your sales cycle. Accelerate revenue.",
    },
    {
        name: "Salesforce Dialer",
        logo: "intergration_icons/salesforce.png",
        title: "Salesforce Dialer",
        category: [AppCategories.DIALER],
        // url: `${process.env.REACT_APP_EXTERNAL_URL}/salesforce?user=`,
        url: ``,
        description: "Help sales, service, and marketing teams  work better together with a 360° view of your customers.",
    },
    // {
    //     name: "MicrosoftExchange",
    //     logo: "intergration_icons/microsoftexchange.png",
    //     title: "Microsoft Exchange",
    //     category: [AppCategories.CALENDARS],
    //     url: ``,
    //     description: "Work smarter with business-class email and calendaring.",
    // },
    {
        name: "MicrosoftDynamics",
        logo: "intergration_icons/microsoftdynamics.png",
        title: "Microsoft Dynamics",
        category: [AppCategories.CRM],
        url: "",
        description: "Unify your data. Get predictive insights. Achieve amazing results.",
    },
    {
        name: "Oracle",
        logo: "intergration_icons/oracle.png",
        title: "Oracle",
        category: [AppCategories.CRM],
        url: "",
        description: "Elastic, highly available, and secure.",
    },
    {
        name: "8x8",
        logo: "intergration_icons/8x8-meet.png",
        title: "8x8",
        category: [AppCategories.DIALER],
        url: ``,
        description: "A cloud platform that delivers reliable, secure, integrated communications.",
    },
    {
        name: "Five9",
        logo: "intergration_icons/five9-logo.png",
        title: "Five 9",
        category: [AppCategories.DIALER],
        url: ``,
        description: "The leading provider of cloud contact  center software.",
    },
    {
        name: "DialSource",
        logo: "intergration_icons/dialsource.png",
        title: "Dial Source",
        category: [AppCategories.DIALER],
        url: ``,
        description: "Helps transform conversations into revenue & accelerate workflows for customer-facing teams.",
    },
    {
        name: "Talkdesk",
        logo: "intergration_icons/Talkdesk-Logo.jpg",
        title: "Talkdesk",
        category: [AppCategories.DIALER],
        url: ``,
        description: "The contact center for innovative enterprises",
    },
    {
        name: "ZendeskTalk",
        logo: "intergration_icons/zendesk_talk.png",
        title: "Zendesk Talk",
        category: [AppCategories.DIALER],
        url: ``,
        description: "Talk it out directly with customers and provide personalized solutions to complex issues.",
    },
    {
        name: "Xant",
        logo: "intergration_icons/xant.png",
        title: "Xant",
        category: [AppCategories.DIALER],
        url: ``,
        description:
            "The #1 modern sales engagement platform for the enterprise.",
    },
    {
        name: "CailRail",
        logo: "intergration_icons/callrail.png",
        title: "CailRail",
        category: [AppCategories.DIALER],
        url: ``,
        description:
            "Get visibility into your calls, conversions, and conversations.",
    },
    // {
    //     name: "Highfive",
    //     logo: "intergration_icons/highfive.png",
    //     title: "Highfive",
    //     url: ``,
    //     uninstallUrl: "",
    //     category: [AppCategories.MESSAGES],
    //     description:
    //         "Transforming every meeting room with modern, beautifully-designed technology.",
    // },
];

function ModalProfile(props) {
    return <Modal size="lg" className="playlist-modal-wrapper" isOpen={props.isOpen} toggle={props.toggle}
                  centered={true} placement="auto-end" target={props.targetId} style={{padding: 30}}>
        <ModalHeader toggle={props.toggle} charCode="x" className="font-24">
            <h2>App Details</h2>
        </ModalHeader>
        <ModalBody>
            <AppDetails targetId="btnAppDetails" toggle={props.toggle} provider={props.provider}></AppDetails>
        </ModalBody>
    </Modal>
}

export const RenderIntegrationItem = (props: RenderIntegrationItemProps) => {
    const {user} = useAppContext();
    const [apps, setApps] = useState([] as any);
    const [provider, setProvider] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [isTwilioOpen, setIsTwilioOpen] = useState(false);
    const [isFreshworksOpen, setIsFreshworksOpen] = useState(false);
    const [isFreshdeskOpen, setIsFreshdeskOpen] = useState(false);
    const [isOpenPhoneOpen, setIsOpenPhoneOpen] = useState(false);
    const [isKixieOpen, setIsKixieOpen] = useState(false);
    const [isCloseOpen, setIsCloseOpen] = useState(false);
    const [isOpenAIOpen, setIsOpenAIOpen] = useState(false);
    const [isTataTeleOpen, setIsTataTeleOpen] = useState(false);
    const [isDialpadOpen, setIsDialpadOpen] = useState(false);
    const [mxProvider, setMxProvider] = useState('');

    const toggle = () => {
        setIsOpen(!isOpen);
    }

    const toggleDetails = (provider) => {
        let app = user.providers.find((x) => provider.category.includes(
            x.service.toLowerCase()) && provider.name.toLowerCase() === x.provider.toLowerCase())
        if (!app) {
            app = user.customer?.providers?.find((x) => provider.category.includes(
                x.service.toLowerCase()) && provider.name.toLowerCase() === x.provider.toLowerCase())
        }
        setProvider(app);
        setIsOpen(!isOpen);
    }

    const toggleTwilioModal = () => {
        setIsTwilioOpen(!isTwilioOpen);
    }

    const toggleFreshworksModal = () => {
        setIsFreshworksOpen(!isFreshworksOpen);
    }

    const toggleFreshdeskModal = () => {
        setIsFreshdeskOpen(!isFreshdeskOpen);
    }

    const toggleKixieModal = () => {
        setIsKixieOpen(!isKixieOpen);
    }

    const toggleOpenPhoneModal = () => {
        setIsOpenPhoneOpen(!isOpenPhoneOpen);
    }
    const toggleCloseModal = () => {
        setIsCloseOpen(!isCloseOpen);
    }

    const toggleOpenAIModal = () => {
        setIsOpenAIOpen(!isOpenAIOpen);
    }

    const toggleTataTeleModal = () => {
        setIsTataTeleOpen(!isTataTeleOpen);
    }

    const toggleDialpadModal = () => {
        setIsDialpadOpen(!isDialpadOpen);
    }

    useEffect(() => {
        if (!mxProvider) {
            var xhr = new XMLHttpRequest();
            var domain = user.email.split("@")[1];
            var genericEmailDomains = ["gmail.com", "yahoo.com", "aol.com", "hotmail.com"];
            if (genericEmailDomains.includes(domain)) {
                setMxProvider('');
            } else {
                var url = "https://dns.google.com/resolve?name=" + domain + "&type=MX"
                xhr.open('GET', url, true);
                xhr.responseType = 'json';
                let provider = mxProvider;
                const getMxProvider = () => {
                    xhr.onload = function () {
                        if (xhr.status === 200) {
                            if (xhr.response.Answer && xhr.response.Answer.length > 0) {
                                provider = (xhr.response?.Answer?.find(x => (x?.data?.toLowerCase().includes("google.com")) ||
                                    x?.data?.toLowerCase().includes("googlemail.com")) ? "Google"
                                    : xhr.response?.Answer?.find(x => x?.data?.toLowerCase().includes("outlook.com"))
                                        ? "Microsoft" : 'Not found');
                                setMxProvider(provider);
                            } else {
                                provider = 'Not found';
                                setMxProvider(provider);
                            }
                        }
                    };
                    xhr.send();
                }
                if (!provider) {
                    getMxProvider()
                }
            }
        }
    }, []);

    function handleNonCalendarProvider(provider: any) {
        let app = findUserProvider(provider);

        if (!app && provider.category.includes(AppCategories.CRM)) {
            handleCRMProvider(provider);
        }

        if (app) {
            provider.status = AppProviderStatus.INSTALLED;
        } else {
            provider.status = AppProviderStatus.UNINSTALLED;
        }
    }

    function handleCalendarProvider(provider: any) {
        let app = findUserProvider(provider);

        if (provider.title === "Google Meet") {
            if (mxProvider === "Google") {
                provider.status = AppProviderStatus.INSTALLED;
            } else {
                provider.state = AppProviderState.DISABLED;
            }
        } else if (provider.title === "Microsoft Outlook") {
            if (mxProvider === "Microsoft") {
                provider.status = AppProviderStatus.INSTALLED;
            } else {
                provider.state = AppProviderState.DISABLED;
            }
        } else if (app) {
            provider.status = AppProviderStatus.INSTALLED;
        } else {
            provider.status = AppProviderStatus.UNINSTALLED;
        }
    }


    function handleCRMProvider(provider: any) {
        if (user?.role?.name !== 'Admin') {
            provider.state = AppProviderState.DISABLED;
        } else if (user.license !== 'Premium' && user.license !== 'Trial') {
            provider.state = AppProviderState.WORKING;
        } else if (user.license === 'Trial') {
            let end = new Date(user.trialEnded);
            let now = new Date();
            if (now > end) {
                provider.state = AppProviderState.DISABLED;
            }
        }
    }

    function disableOtherCRMs(installedCRMCount: number) {
        if (installedCRMCount > 0) {
            registeredApps.forEach(provider => {
                if (provider.category.includes(AppCategories.CRM)) {
                    let app = findUserProvider(provider);
                    if (!app) {
                        provider.state = AppProviderState.DISABLED;
                    }
                }
            });
        }
    }

    useEffect(() => {
        var domain = user.email.split("@")[1];
        var genericEmailDomains = ["gmail.com", "yahoo.com", "aol.com", "hotmail.com"];

        let regApps = [...registeredApps]; // Create a copy of registeredApps
        let installedCRMCount = 0;

        // Handle non-calendar providers
        regApps.forEach((provider: any) => {
            if (!provider.category.includes(AppCategories.CALENDARS)) {
                handleNonCalendarProvider(provider);
                if (provider.status === AppProviderStatus.INSTALLED && provider.category.includes(AppCategories.CRM)) {
                    installedCRMCount++;
                }
            }
        });

        // Disable other CRMs if one is already installed
        disableOtherCRMs(installedCRMCount);

        // Handle calendar providers only if mxProvider is set or it's a generic email domain
        if (mxProvider || genericEmailDomains.includes(domain)) {
            regApps.forEach((provider: any) => {
                if (provider.category.includes(AppCategories.CALENDARS)) {
                    handleCalendarProvider(provider);
                }
            });
        }

        if (props.isOnboardingFlow) {
            regApps = regApps.filter(x => x.url !== "" || x.title === "Google Meet");
        }

        setApps(regApps);
    }, [mxProvider, user.providers, user.customer?.providers, user.license, user.role?.name, user.trialEnded]);

    function findUserProvider(provider: any) {
        let app = user.providers?.find((x) =>
            provider.category.includes(x.service.toLowerCase()) &&
            provider.name.toLowerCase() === x.provider.toLowerCase()
        );

        if (!app) {
            app = user.customer?.providers?.find((x) =>
                provider.category.includes(x.service.toLowerCase()) &&
                provider.name.toLowerCase() === x.provider.toLowerCase()
            );
        }

        return app;
    }

    function raiseClicked(url, name) {
        url = url + user?.login?.userName;
        if (name.indexOf("Hubspot") >= 0)
            url = url + `&state=${name}`;
        window.open(url, "_blank");
    }

    function remove(url) {
        url = url + user?.login?.userName
        window.open(url, "_blank");
    }

    if (!apps) return <></>;
    return (
        <div className={props.isOnboardingFlow ? "integration-tab-onboarding" : "integration-tab"}>
            {apps
                .filter(
                    (x) =>
                        props.category === "ALL" ||
                        x.category.includes(props.category)
                )
                .map((item) => {
                    return (
                        <div key={item.title} className={props.isOnboardingFlow ? "it-card2" : "it-card"}>
                            <div className="d-flex">
                                <div className="it-img">
                                    <img alt={""} src={`../../assets/img/${item.logo}`}/>
                                </div>
                                <div className="it-title">
                                    <div>{item.title}</div>
                                    <span className="font-14" style={{textTransform: "capitalize"}}>
                                        {item.category.join(", ")}
                                    </span>
                                </div>
                            </div>
                            <div className="font-14 it-info">
                                {item.description}
                                {item.title === 'Hubspot' || item.title === 'Salesforce' || item.title === 'Zoho' ||
                                item.title === 'Pipedrive' || item.title === 'Aircall' || item.title === 'Close' ||
                                item.title === 'monday.com'? (
                                    <b> Integration must be done by {item.title} Admin</b>
                                ) : item.title === 'Google Meet' ? <b>Google Business Standard+ license needed.</b> :
                                    item.title === "WebEx" || item.title === "Zoho Meeting" ?
                                        <b> Recording using Chrome Extension. Integration not needed.</b> :
                                        item.title === 'Zoom' ? (
                                                <b> Zoom Pro/Enterprise+ needed. Integration must be done by Zoom
                                                    Admin</b>) :
                                            item.title === 'Microsoft Teams' ? (
                                                <b> Recording using Chrome Extension. Integration not needed.</b>) : ""}
                            </div>
                            <hr
                                style={{
                                    // display: item.url ? "block" : "none",
                                    display: "block",
                                }}
                            />
                            <div className="d-flex justify-content-center it-ctrls">
                                {item.status === AppProviderStatus.INSTALLED ? (
                                    <>
                                        <Button id="btnAppDetails" style={{marginTop: "0px !important"}}
                                                className="settings_integration icon"
                                                disabled={(user?.role.name !== "Admin" && item.category.find(x => x === "crm"))}
                                                onClick={(e) => toggleDetails(item)}
                                                color="secondary"
                                        ></Button>
                                        <Button
                                            className="refresh icon"
                                            disabled={(user?.role.name !== "Admin" && item.category.find(x => x === "crm"))}
                                            onClick={(e) => {
                                                if (item.name === "Freshworks")
                                                    toggleFreshworksModal();
                                                else if (item.name === "Freshdesk")
                                                    toggleFreshdeskModal();
                                                else if (item.name === "Close")
                                                    toggleCloseModal();
                                                else if (item.name === "OpenPhone")
                                                    toggleOpenPhoneModal();
                                                else if (item.name === "Kixie")
                                                    toggleKixieModal();
                                                else if (item.name === "OpenAI")
                                                    toggleOpenAIModal();
                                                else if (item.name === "TataTele")
                                                    toggleTataTeleModal();
                                                else
                                                    raiseClicked(item.url, item.name)
                                            }}
                                            color="secondary"
                                        ></Button>
                                        <Button
                                            className="add-inti"
                                            color="secondary"
                                            onClick={(e) => {
                                                if (item.name === "Outreach")
                                                    remove(`${process.env.REACT_APP_EXTERNAL_URL}/outreach/uninstall?user=`);
                                                else if (item.name === "Pipedrive")
                                                    remove(`${process.env.REACT_APP_EXTERNAL_URL}/pipedrive/uninstall?user=`);
                                            }

                                            }
                                        >
                                            {(item.name === "Outreach" || item.name === "Pipedrive") ? "Remove" : "Integrated"}
                                        </Button>
                                    </>
                                ) : (<>
                                    {(item.name !== "MicrosoftTeams" && item.name !== "ZohoMeeting" && item.name !== "WebEx") ?
                                        <Button
                                            className="add-btn"
                                            color="primary"
                                            id="add_integration"
                                            disabled={
                                                item.url ? (item.state !== AppProviderState.DISABLED ? false : true)
                                                    : true}
                                            style={{
                                                display: item.url ?
                                                    (item.state !== AppProviderState.DISABLED ? "block" : "inline")
                                                    : "inline",
                                                opacity: item.url ? (item.state !== AppProviderState.DISABLED ? "1" : "0.3") : "0.3",
                                            }}
                                            onClick={(e) => {
                                                if (item.name === "Twilio")
                                                    toggleTwilioModal();
                                                else if (item.name === "Freshworks")
                                                    toggleFreshworksModal();
                                                else if (item.name === "Freshdesk")
                                                    toggleFreshdeskModal();
                                                else if (item.name === "OpenPhone")
                                                    toggleOpenPhoneModal();
                                                else if (item.name === "Close")
                                                    toggleCloseModal();
                                                else if (item.name === "Kixie")
                                                    toggleKixieModal();
                                                else if (item.name === "OpenAI")
                                                    toggleOpenAIModal();
                                                else if (item.name === "TataTele")
                                                    toggleTataTeleModal();
                                                else if (item.name === "Dialpad")
                                                    toggleDialpadModal();
                                                else
                                                    raiseClicked(item.url, item.name);
                                            }}
                                        >
                                            {item.url ? "Add Integration" : (item.name === "GoogleMeet" ? "Add Integration" : "Coming Soon")}
                                        </Button>
                                        : <></>}
                                </>)}
                            </div>
                        </div>
                    );
                })}
            <ModalProfile isOpen={isOpen} toggle={toggle} provider={provider}></ModalProfile>
            <ModalComponent
                isModal={isTwilioOpen}
                toggle={toggleTwilioModal}
                contentClassName="snippet-modal"
                className="playlist-modal-wrapper"
            >
                <TwilioModal isOpen={isTwilioOpen} toggle={toggleTwilioModal} provider={provider}></TwilioModal>
            </ModalComponent>

            <ModalComponent
                isModal={isCloseOpen}
                toggle={toggleCloseModal}
                contentClassName="snippet-modal"
                className="playlist-modal-wrapper"
            >
                <CloseModal isOpen={isCloseOpen} toggle={toggleCloseModal} provider={provider}></CloseModal>
            </ModalComponent>
            <ModalComponent
                isModal={isOpenPhoneOpen}
                toggle={toggleOpenPhoneModal}
                contentClassName="snippet-modal"
                className="playlist-modal-wrapper"
            >
                <OpenPhoneModal isOpen={isOpenPhoneOpen} toggle={toggleOpenPhoneModal} provider={provider}></OpenPhoneModal>
            </ModalComponent>
            <ModalComponent
                isModal={isKixieOpen}
                toggle={toggleKixieModal}
                contentClassName="snippet-modal"
                className="playlist-modal-wrapper"
            >
                <KixieModal isOpen={isKixieOpen} toggle={toggleKixieModal} provider={provider}></KixieModal>
            </ModalComponent>
            <ModalComponent
                isModal={isFreshworksOpen}
                toggle={toggleFreshworksModal}
                contentClassName="snippet-modal"
                className="playlist-modal-wrapper"
            >
                <FreshworksModal isOpen={isFreshworksOpen} toggle={toggleFreshworksModal} provider={provider}></FreshworksModal>
            </ModalComponent>
            <ModalComponent
                isModal={isOpenAIOpen}
                toggle={toggleOpenAIModal}
                contentClassName="snippet-modal"
                className="playlist-modal-wrapper"
            >
                <OpenAIModal isOpen={isOpenAIOpen} toggle={toggleOpenAIModal} provider={provider}></OpenAIModal>
            </ModalComponent>
            <ModalComponent
                isModal={isTataTeleOpen}
                toggle={toggleTataTeleModal}
                contentClassName="snippet-modal"
                className="playlist-modal-wrapper"
            >
                <TataTeleModal isOpen={isTataTeleOpen} toggle={toggleTataTeleModal} provider={provider}></TataTeleModal>
            </ModalComponent>
            <ModalComponent
                isModal={isFreshdeskOpen}
                toggle={toggleFreshdeskModal}
                contentClassName="snippet-modal"
                className="playlist-modal-wrapper"
            >
                <FreshdeskModal isOpen={isFreshdeskOpen} toggle={toggleFreshdeskModal} provider={provider}></FreshdeskModal>
            </ModalComponent>
            <ModalComponent
                isModal={isDialpadOpen}
                toggle={toggleDialpadModal}
                contentClassName="snippet-modal"
                className="playlist-modal-wrapper"
            >
                <DialpadModal isOpen={isDialpadOpen} toggle={toggleDialpadModal} provider={provider}></DialpadModal>
            </ModalComponent>
        </div>

    );
};

export default RenderIntegrationItem;
