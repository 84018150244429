import React from "react"
import {ProfileImageBox} from './Styled';

interface AppImgProps {
    photoUrl?: string;
    text?: string;
    color?: string;
    bgcolor?: string;
    className?: string;
}

const ProfileImage: React.FC<AppImgProps> = (props: AppImgProps) => {
    return (
        <ProfileImageBox>
            <div className={props.className ? props.bgcolor + " "+ props.className : "circular-lmg"}>
                {
                    props.photoUrl ? <img src={props.photoUrl} alt={""}/> :
                       <div className={props.className ? "circle": props.bgcolor}
                            style={{backgroundColor: props.bgcolor, color: props.color, fontWeight: 600}}>
                            {props.text}
                       </div>
                }
            </div>
        </ProfileImageBox>
    )
}
ProfileImage.defaultProps = {
    color: "white",
    // bgcolor: "#d39c2b",
    bgcolor: "#fb3254",
    className: ""
}
export default ProfileImage