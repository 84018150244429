import React from "react";
import {IFilterItemProps} from "./FilterPanel";
import {Input} from "reactstrap";

export const defaultRangeProps: IFilterItemProps = { id: "", label: "", component_name: "Input", text:""};
const FilterInput: React.FC<IFilterItemProps> = (props: IFilterItemProps) => {
  return (
    <div>
      <Input style={{fontSize:14}} id={"input" + props.id} name={"input" + props.id}
        type={props.type || 'text'}
        disabled={props.disabled || false}
        readOnly={props.readOnly || false}
        defaultValue={props.text} onChange={(e) => {
          e.persist();
          props.onChangeValue && props.onChangeValue(props.id, e.target.value, e);
        }}></Input>
    </div>
  );
}

FilterInput.defaultProps = defaultRangeProps;
export default FilterInput;