import React, {HTMLAttributes} from 'react'
import {ErrorMessage, Formik} from 'formik'
import {object, ref, string} from 'yup'
import {Button, Container, Input, Label} from 'reactstrap'
import { useMutation } from '@apollo/client';
import {UPDATE_PASSWORD} from '../../graphql/ql/schema'
import {updatePassword, updatePasswordVariables} from "../../graphql/ql/operation-result-types";
import {toastService} from '../../services/toast.service';

export interface FormPasswordResetProps extends HTMLAttributes<HTMLElement> {
    targetId: any;
    toggle: any;
}
  
const  FormPasswordReset = (props: FormPasswordResetProps) => {

    const [callUpdatePassword] = useMutation<updatePassword, updatePasswordVariables>(
        UPDATE_PASSWORD,
        {
            onCompleted(data: any): void {
                if (data && data.updatePassword && data.updatePassword.ok) {
                    toastService.info("Successfully updated");
                } else {
                    toastService.error("Error while updating password : " + data.updatePassword.message);
                }
            },
            onError(e: any): void {
                console.log(e);
                toastService.error("Error while updating password");
            },
        }
    );
     
    function _handlePassword(currentPassword,newPassword) {
        
        return callUpdatePassword({ variables: { userPassword : {currentPassword:currentPassword,newPassword:newPassword}} })
    }

    const _handleSubmit = ({
    currentPass,
    newPass,
    confirmPass,
    setSubmitting,
    resetForm,
  }) => {
    setSubmitting(true);
    _handlePassword(currentPass,newPass).then(data => {
        if(data.data?.updatePassword?.ok) {
            props.toggle();
        }
        resetForm();
    }).catch((e) => {
        toastService.error("failed");
        resetForm();
    });
  }

    return (
      <Formik
        initialValues={{
          currentPass: '',
          newPass: '',
          confirmPass: '',
        }}
        validationSchema={object().shape({
          currentPass: string().required('Current password is required'),
          newPass: string().required('New password is required').min(6, "Seems a bit short").matches(/^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[^\w\s]).{6,}$/,"Minimum 6 characters, Atleast one special character, Atleast one uppercase character."),
          confirmPass: string()
            .oneOf([ref('newPass')], 'Passwords do not match')
            .min(6, "Seems a bit short")
            .required('Password is required'),
        })}
        onSubmit={(
          { currentPass, newPass, confirmPass },
          { setSubmitting, resetForm }
        ) =>
          _handleSubmit({
            currentPass,
            newPass,
            confirmPass,
            setSubmitting,
            resetForm,
          })
        }
        render={props => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
            isSubmitting,
          } = props
          {const getProps = (name) => ({
            name,
            id: name,
            value: values[name],
            onChange : handleChange,
            error: touched[name] && errors[name] !== undefined,
            onBlur :handleBlur
          });             
          return isSubmitting ? (
            <div>Resetting Password</div>
          ) : (
            <Container className="form form--wrapper" elevation={10}>
              <ul style={{textAlign:"left"}}>
              <li>Minimum 6 characters</li>
              <li>Atleast one special character</li>
              <li>Atleast one uppercase character</li>
              </ul>                
              <form className="form" onSubmit={handleSubmit}>
                <div className="form-group">
                    <Label  htmlFor="password-current"   error={Boolean(touched.currentPass && errors.currentPass)} >
                    {'Current Password'}
                    </Label>
                    <Input
                            {...getProps("currentPass")}
                            type="password"
                            placeholder="Current Password"
                    />
                </div>
                <ErrorMessage name="currentPass" />
                <div className="form-group">
                    <Label  htmlFor="password-new"   error={Boolean(touched.newPass && errors.newPass)} >
                    {'New Password'}
                    </Label>
                    <Input
                            {...getProps("newPass")}
                            type="password"
                            placeholder="New Password"
                    />
                </div>
                <ErrorMessage name="newPass" />
                <div className="form-group">
                    <Label  htmlFor="password-new"   error={Boolean(touched.confirmPass && errors.confirmPass)} >
                    {'Re-enter New Password'}
                    </Label>
                    <Input
                            {...getProps("confirmPass")}
                            type="password"
                            placeholder="Re-enter New Password"
                    />
                </div>
                <ErrorMessage name="confirmPass" />
                <div className="form-group" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                <Button
                  type="submit"
                  variant="raised"
                  color="primary"
                  disabled={Boolean(!isValid || isSubmitting)}
                  style={{ margin: '16px' }}
                >
                  {'Reset Password'}
                </Button>
                </div>
              </form>
            </Container>
          )}}
        }
      />
    )
}

export default FormPasswordReset;