import { RouterProvider, createHashRouter } from "react-router-dom";
import { PrivateRoutes, PublicRoutes } from "./configs";


export default function Routes() {

    const routes = [PrivateRoutes(), PublicRoutes()];
    const hashRoutes = createHashRouter(routes);

    return <RouterProvider router={hashRoutes} />
}