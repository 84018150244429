import {DictionaryItem} from "../types";

export function getSum(data:any[],filterKey:DictionaryItem<string>, key: string, key1?: string): any {
    if(data == null) { return ; }
    if(key1 !== undefined) {
      return data.filter(item => item[filterKey.key] === item[filterKey.value])
              .reduce((sum, current) => sum + (current[key1] - current[key]), 0);
    } else {
      return data.filter(item => item[filterKey.keys[0]] === item[filterKey.values[0]]).reduce((sum, current) => sum + current[key], 0);
    }
  }

  export function getHighest(data: any[], key: string): any {
    if(data == null) { return ; }
    return data.reduce(function(acc: any, item: any): any {
      return (acc[key] || 0 <  item[key] ? item : acc);
    }, {});
  }

  export function groupBy(data: any[], key: string): any {
    if(data == null) { return []; }
    return data.reduce(function(acc: any, item: any): any {
      (acc[item[key]] = acc[item[key]] || []).push(item);
      return acc;
    }, {});
  }

  export function groupByKey(data: any[], key: (item:any) => string): any {
    if(data == null) { return []; }
    return data.reduce(function(acc: any, item: any): any {
      (acc[key(item)] = acc[key(item)] || []).push(item);
      return acc;
    }, {});
  }


  export function findUniques(arr: any[], key: string): string[] {
    let uniques: string[] = [];
    arr.forEach((n: any) => {
      if(!uniques.includes(n[key])) {
        uniques.push(n[key]);
      }
    });
    return uniques;
  }

  
  // with dot notation 
  // OpportunityResult.representative,  then use findUniques
  // OpportunityResult.contacts.contactName,  then use findUniquesByKey(OpportunityResult,"contacts","contactName")
  export function findUniquesByKey(arr: any[], objectName:string, key: string): string[] {
    let uniques: string[] = [];
    arr.forEach((n: any) => {
      let objects = n[objectName];
      if(objects instanceof Array) {
        objects.forEach((object) => {
          if(!uniques.includes(object[key])) {
            uniques.push(object[key]);
          }
        });
      }
      else {
        if(!uniques.includes(objects[key])) {
          uniques.push(objects[key]);
        }
      }
    });
    return uniques;
  }

export const sortBy = (collection, fieldName, fieldType) => {
		if(collection && collection.length > 0) {
			if(fieldType === "date") {
				collection.sort((a, b) => {
          if(a[fieldName]) {
            return Date.parse(b[fieldName]) -  Date.parse(a[fieldName])
          }
        });
      }
      else if(fieldType === "float") {
        return collection.sort(function(a, b) {
          return a.amount - b.amount;
      });
			}
			else {
        collection.sort((a, b) => {
            if(a[fieldName]) {
              return a[fieldName].localeCompare(b[fieldName] || "");
            }
				});
      }
      
    }
    return collection;
	}