import styled from 'styled-components';

export const SelectBoxStyled = styled.div`
.left-panel-shrink-nav.fullNav {
    padding: 0 8px 0 0;

    .left-panel-shrink-col1 {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    img {
        cursor: pointer;
        padding: 1rem;
    }
}
.left-panel-shrink-nav.l-shrink.row .dropdown-menu.show {
    top: 0px !important;
}  

.left-panel-shrink-nav.ex-shrink-playlist .dropdown-menu.show{
    top: 40px !important;
}
.left-panel-shrink-nav.ex-shrink .dropdown-menu.show{
    top: 40px !important;
}
   position: relative;
   .dropdown-toggle.btn.btn-secondary, .dropdown-toggle.btn.btn-secondary:focus,
   .dropdown-toggle:focus {
    background: transparent;
    border: none;
    font-size: 24px;
    color: #2d323c;
    // box-shadow: 0 0 0 0 !important;
}
   .dropdown-menu.show {
    display: block;
    left: 35px !important;
    background: #2D323C;
    border-radius: 4px;
    font-size: 14px;
    line-height: 21px;

    button:focus {
        outline: 0;
    }
}
.dropdown-item:hover, .dropdown-item:focus {
    text-decoration: none;
    background-color: #646565ba;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    margin: 8px auto;
    width: 90%;
}

.dropdown-item {
    border: 0;
    margin: 8px auto;
    width: 90%;
    transition: linear 1s;
}
  .left-panel-shrink-col2-deals img {
    margin-top: 0px !important;
    cursor: pointer;
  }

.left-panel-shrink-col2 img {
    margin-top: 10px !important;
}

.left-panel-shrink-nav.ex-shrink > div {
    padding: 10px 15px 12px 0;
}

.left-panel-shrink-nav.ex-shrink, .left-panel-shrink-nav.fullNav {
    align-items: center;
    .dropdown-toggle::after {
        margin-left: 0.355em;
        margin-top: 15px;
    }
}
`

export const MeetingSelectBoxStyled = styled.div`
.left-panel-shrink-nav.fullNav {
    padding: 0 8px 0 0;

    .left-panel-shrink-col1 {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    img {
        cursor: pointer;
        padding: 9px 30px;
    }
}
.left-panel-shrink-nav.l-shrink.row .dropdown-menu.show {
    top: 0px !important;
}   
.left-panel-shrink-nav.ex-shrink .dropdown-menu.show{
    top: 252px !important;
}
   position: relative;
   .dropdown-toggle.btn.btn-secondary, .dropdown-toggle.btn.btn-secondary:focus,
   .dropdown-toggle:focus {
    background: transparent;
    border: none;
    font-size: 18px;
    color: #FFFFFF;
}
   .dropdown-menu.show {
    display: block;
    left: 35px !important;
    background: #2D323C;
    border-radius: 4px;
    font-size: 14px;
    line-height: 21px;

    button:focus {
        outline: 0;
    }
}
.dropdown-item:hover, .dropdown-item:focus {
    text-decoration: none;
    background-color: #646565ba;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    margin: 8px auto;
    width: 90%;
}
.dropdown-item {
    border: 0;
    margin: 8px auto;
    width: 90%;
    transition: linear 1s;
}

.left-panel-shrink-col2 img {
    margin-top: 0px;
}
.left-panel-shrink-nav.ex-shrink > div {
    padding: 10px 15px 12px 0;
}
.left-panel-shrink-nav.ex-shrink, .left-panel-shrink-nav.fullNav {
    align-items: center;
.dropdown-toggle::after {
    margin-left: 0.355em;
    margin-top: 15px;
}
}

`
export const ProfileImageBox = styled.div `
.circular-lmg {
    width: 24px;
    font-size: 11px;
    height: 24px;
}`

export const StyledChatBox = styled.div `
footer.StickyFooter {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: #ffffff;
  border-top: solid 1px #efefef;
}
  
.MessageForm {
  margin-left: 10px;
}
  
.MessageBox {
  float: left;
  width: 98%;
  margin: 5px 0 0 2%;
}
  
.MessageBox .ChatMessage {
  width: 80%;
  min-height: 40px;
}
  
.MessageBox .ChatMessage .RightBubble {
  position: relative;
  background: #dcf8c6;
  border-top-left-radius: .4em;
  border-bottom-left-radius: .4em;
  border-bottom-right-radius: .4em;
  padding: 5px 10px 10px;
  left: 15%;
}
  
.MessageBox .ChatMessage .RightBubble span.MsgName {
  font-size: 12px;
  font-weight: bold;
  color: green;
}
.MessageBox .ChatMessage .RightBubble span.MsgDate {
  font-size: 10px;
}
  
.MessageBox .ChatMessage .RightBubble:after {
  content: '';
  position: absolute;
  right: 0;
  top: 13px;
  width: 0;
  height: 0;
  border: 27px solid transparent;
  border-left-color: #dcf8c6;
  border-right: 0;
  border-top: 0;
  margin-top: -13.5px;
  margin-right: -27px;
}
.MessageBox .ChatMessage .LeftBubble {
  position: relative;
  background: lightblue;
  border-top-right-radius: .4em;
  border-bottom-left-radius: .4em;
  border-bottom-right-radius: .4em;
  padding: 5px 10px 10px;
  left: 5%;
}
.MessageBox .ChatMessage .LeftBubble span.MsgName {
  font-size: 12px;
  font-weight: bold;
  color: blue;
}
.MessageBox .ChatMessage .LeftBubble span.MsgDate {
  font-size: 10px;
}
.MessageBox .ChatMessage .LeftBubble:after {
  content: '';
  position: absolute;
  left: 0;
  top: 13px;
  width: 0;
  height: 0;
  border: 27px solid transparent;
  border-right-color: lightblue;
  border-left: 0;
  border-top: 0;
  margin-top: -13.5px;
  margin-left: -27px;
}
  
.MessageBox .ChatStatus {
  min-height: 49px;
}

.MessageBox .ChatStatus .ChatDate {
  display: block;
  font-size: 10px;
  font-style: italic;
  color: #777;
  height: 15px;
  left: 10%;
  right:10%;
}
  
.MessageBox .ChatStatus .ChatContentCenter {
  padding: 5px 10px;
  background-color: #e1e1f7;
  border-radius: 6px;
  font-size: 12px;
  color: #555;
  height: 34px;
  left: 10%;
  right:10%;
}

.ChatContent {
  overflow-y: scroll;
  height: 600px;
}

`