import axios from 'axios';
const instance = axios.create({
    withCredentials: true
})
export const callAxiosService = (entity, params) => {
    const token = localStorage.getItem('token')
    return new Promise((resolve, reject) => {
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'include',
                authorization: token ? `Bearer ${token}` : ''
            },
            withCredentials: true,
            'credentials': 'include'
        }
        // let sParams = JSON.stringify(params);
        instance.get(`${process.env.REACT_APP_EXTERNAL_URL}/${entity}/${params !== undefined ? params : ''}`, config).then(res => {
            resolve(res.data);
        }).catch(err => {
            console.log(err);
            reject(err)
        });
    });
};

export const callAxiosServiceWithParam =  (entity, param) => {
    const token = localStorage.getItem('token')
    let config = {
        headers: {
            'Content-Type': 'application/text',
            'credentials': 'include',
            'Access-Control-Allow-Origin': '*',
            authorization: token ? `Bearer ${token}` : ''
        },
        withCredentials: true,
        'credentials': 'include'
    }
    return axios.get(`${process.env.REACT_APP_EXTERNAL_URL}/${entity}?${param !== undefined ? param : ''}`, config);
};

export const callAxiosExternalService =  (url) => {
    const token = localStorage.getItem('token')
    let config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept' :'video/webm,video/ogg,video/*',
            'credentials': 'include',
            'origin' : 'http://localhost:3000',
            'Access-Control-Allow-Origin': '*',
            authorization: token ? `Bearer ${token}` : '',
            responseType: 'blob'
        },
        withCredentials: true,
        'credentials': 'include',
        responseType: 'blob'
    }
    return axios.get(url, config);
};
export const postAxiosService =  (entity, formData) => {
    const token = localStorage.getItem('token')
    let config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'credentials': 'include',
            'Access-Control-Allow-Origin': '*',
            'Accept' :'video/mp4,audio/m4a,audio/mp3,audio/wav,video/x-m4v,audio/x-m4a,audio/x-wav',
            authorization: token ? `Bearer ${token}` : ''
        },
        withCredentials: true,
        'credentials': 'include'
    }
    return axios.post(`${process.env.REACT_APP_EXTERNAL_URL}/${entity}`, formData, config);
};

export const postJsonAxiosService =  (entity, json) => {
    const token = localStorage.getItem('token')
    let config = {
        headers: {
            'Content-Type': 'application/json',
            'credentials': 'include',
            authorization: token ? `Bearer ${token}` : ''
        },
        withCredentials: true,
        'credentials': 'include'
    }
    return axios.post(`${process.env.REACT_APP_EXTERNAL_URL}/${entity}`, json, config);
};