import useObjectContext from "../../hooks/useObjectHook";
import useAppContext from "../../hooks/useAppHook";
import React, {useState} from "react";
import useBehaviourContext from "../../hooks/useBehaviourHook";
import {useMutation} from "@apollo/client";
import {
    AlertInput,
    createAlert,
    createAlertVariables,
    createState,
    createStateVariables
} from "../../graphql/ql/operation-result-types";
import {CREATE_ALERT, CREATE_STATE} from "../../graphql/ql/schema";
import {toastService} from "../../services/toast.service";
import {Button, Input, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

export const SaveSearchModal = (props) => {
    const {selectedMeeting} = useObjectContext();
    const {toggle} = props;
    const {user} = useAppContext();
    const [inputText, setSearchInputText] = useState("");
    const [inputCheckbox, setCheckboxInput] = useState("Disabled");
    const [errorText, setError] = useState("");
    const {meetingSearchTerms} = useBehaviourContext();

    const [createstate] = useMutation<createState, createStateVariables>(
        CREATE_STATE,
        {
            onCompleted(data: any): void {
                if (data != null) {
                    toastService.info("Filter successfully saved");
                    let state = "Active";
                    if (inputCheckbox === "Disabled")
                        state = "InActive";
                    const alert: AlertInput = {
                        customerId: user.customer.id,
                        userId: user.id,
                        name: inputText,
                        objectId: data?.createState?.id,
                        objectType: "State",
                        state: state,
                        triggerOn: inputCheckbox,
                        triggerAt: "0 18 * * 1-5 (America/Los_Angeles)",
                    };
                    createalert({variables: {alert: alert}});
                }
            },
            onError(e: any): void {
                console.log(e);
                toastService.error("Error while saving filter settings");
            },
        }
    );

    const [createalert] = useMutation<createAlert, createAlertVariables>(
        CREATE_ALERT,
        {
            onCompleted(data: any): void {
                if (data != null) {
                    toastService.info("Alert successfully saved");
                }
            },
            onError(e: any): void {
                console.log(e);
                toastService.error("Error while saving alert settings");
            },
        }
    );

    const selectCheckbox = (e) => {
        if (e.target.id == 0) setCheckboxInput("Disabled");
        else if (e.target.id == 1) setCheckboxInput("Daily");
        else setCheckboxInput("Weekly");
    };

    const handleSearchSubmit = () => {
        if (inputText) {
            const reconstructedJson: { [key: string]: any } = {};
            // keep only non-null values
            for (const key in meetingSearchTerms) {
                if (meetingSearchTerms[key] !== null && meetingSearchTerms[key] !== undefined) {
                    if (typeof meetingSearchTerms[key] === 'object') {
                        if (Object.keys(meetingSearchTerms[key]).length > 0) {
                            reconstructedJson[key] = meetingSearchTerms[key];
                        }
                    } else if (typeof meetingSearchTerms[key] === 'string' && meetingSearchTerms[key].length > 0) {
                        reconstructedJson[key] = meetingSearchTerms[key];
                    } else if (typeof meetingSearchTerms[key] !== 'string') {
                        reconstructedJson[key] = meetingSearchTerms[key];
                    }
                }
            }

            let stateData = {
                userId: user.id,
                name: inputText,
                type: "FILTER",
                data: JSON.stringify(
                    reconstructedJson
                        ? {...reconstructedJson, name: inputText}
                        : {name: inputText}
                ),
            };
            createstate({variables: {state: stateData}});
            toggle();
        } else {
            setError("Please Enter Name");
        }
    };

    return (
        <>
            <Modal
                className="meeting-modal-wrapper"
                isOpen={props.isOpen}
                centered={true}
                toggle={props.toggle}
                placement="auto-end"
                target={props.targetId}
            >
                <ModalHeader
                    toggle={props.toggle}
                    charCode="x"
                    style={{border: "none", paddingBottom: 10, paddingLeft: 30}}
                >
                        <span style={{fontSize: "20px", paddingLeft: "0px"}}>
                            Save Search & Alert
                        </span>
                </ModalHeader>
                <ModalBody>
                    <div className="saveAlertModal">
                        <label>Name</label>
                        <Input
                            style={{fontSize: "14px"}}
                            placeholder="Enter a name for search filter"
                            value={inputText || undefined}
                            onChange={(e) => {
                                setError("");
                                setSearchInputText(e.target.value);
                            }}
                        />
                        {errorText ? (
                            <div className="snippet-name-error">{errorText}</div>
                        ) : null}
                    </div>
                    <div className="saveAlertModal">
                        <label>Enable alert</label>
                    </div>
                    <div className="saveAlertModal">
                        <label>ALERT SCHEDULE</label>
                    </div>
                    <div className="saveAlertModal">
                        <label className="saveAlertModal">
                            <Input
                                type="radio"
                                name="disabled"
                                id="0"
                                onClick={(e) => selectCheckbox(e)}
                                onChange={(e) => selectCheckbox(e)}
                                checked={inputCheckbox === "Disabled"}
                            />{" "}
                            Disabled
                        </label>
                        <label className="saveAlertModal">
                            <Input
                                type="radio"
                                name="disabled"
                                id="1"
                                onClick={(e) => selectCheckbox(e)}
                                checked={inputCheckbox === "Daily"}
                            />{" "}
                            Daily
                        </label>
                        <label className="saveAlertModal">
                            <Input
                                type="radio"
                                name="disabled"
                                id="2"
                                onClick={(e) => selectCheckbox(e)}
                                checked={inputCheckbox === "Weekly"}
                            />{" "}
                            Weekly
                        </label>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button outline color="primary" onClick={() => {
                        toggle();
                    }}
                            style={{borderColor: "#0052CC", background: "#fff", color: "#0052cc"}}>
                        Cancel
                    </Button>
                    <Button color="primary"
                            style={{background: "#0052cc", color: "#fff"}}
                            onClick={handleSearchSubmit}>
                        Save
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};