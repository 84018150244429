import React, {HTMLAttributes, useEffect, useState} from "react";
import {Button} from "reactstrap";
import * as Yup from "yup";
import {UpdateProfile} from "../../graphql/queries/user";
import {getAggregatesForMe, getAggregatesForMeVariables, ProfileInput} from "../../graphql/ql/operation-result-types";
import useAppContext from "../../hooks/useAppHook";
import GeneralSettings from "./Generalsetting";
import {GET_AGGREGATESFOR_ME} from "../../graphql/ql/schema";
import "./usermodule.scss";
import {useQuery} from "@apollo/client";
import {getInitial} from "../../utils/StringUtil";
import ProfileImage from "../core/ProfileImage";
import Loading from "../core/Loading";

export interface UserProfileProps extends HTMLAttributes<HTMLElement> {
    targetId: any;
    toggle: any;
    toggleError: any;
    bioMetricUrl: any;
    isOnboardingFlow: boolean;
    handleNext?: any;
}

const validationSchema = Yup.object().shape({
    firstName: Yup.string()
        .required(() => <span style={{color:"red"}}>First Name is required</span>),
    lastName: Yup.string()
        .required(() => <span style={{color:"red"}}>Last Name is required</span>),
    designation: Yup.string().required(() => <span style={{color:"red"}}>Title is required</span>)
        .typeError(() => <span style={{color:"red"}}>Title must be a string</span>),
    phone: Yup.number().typeError(() => <span style={{color:"red"}}>Please enter a valid Phone Number</span>)
        .required(() => <span style={{color:"red"}}>Phone Number is required</span>),
    email: Yup.string()
        .required(() => <span style={{color:"red"}}>Email is required</span>)
        .email(() => <span style={{color:"red"}}>Email format is not valid</span>)
        .typeError(() => <span style={{color:"red"}}>Email must be a string</span>),
});


const UserProfile = (props: UserProfileProps) => {
    const [error, setError] = useState("");
    const {user, photoUrl, setUser} = useAppContext();

    const [initialValues, SetinitialValues] = useState({
        firstName: "",
        lastName: "",
        designation: "",
        phone: "",
        email: ""
    });

    const [initialValuesCompany, SetinitialValuesCompany] = useState({
        name: "",
        industry: "",
        phone: "",
        email: "",
        address: "",
        state: "",
        zipCode: ""
    });

    const [activeTab, setActiveTab] = useState("1");
    const updateUser = UpdateProfile();

    const {loading, data, /*refetch*/} = useQuery<getAggregatesForMe,
        getAggregatesForMeVariables>(GET_AGGREGATESFOR_ME,
        {fetchPolicy: "network-only", variables: {userId: user.id}});

    useEffect(() => {
        if (user) {
            SetinitialValues(user);
            SetinitialValuesCompany(user.customer);
        }
    });

    if (loading) return (<Loading/>);
    const handleSubmit = async (formData) => {
        let profileInput: ProfileInput = {
            firstName: formData["firstName"] as string || user.firstName,
            lastName: formData["lastName"] as string || user.lastName,
            email: formData["email"] as string || user.email,
            designation: formData["designation"] as string || user.designation,
            phone: formData["phone"] as string || user.phone,
            bioMetricUrl: formData["bioMetricUrl"] as string || user.bioMetricUrl,
            transcriptLanguage: formData["transcriptLanguage"] as string || "English",
        };

        let doc = await updateUser({
            variables: {
                id: user.id,
                profile: profileInput,
            },
        }).catch(function (error: any): any {
            // handle error
            setError(error.message);
            console.log(error);
        });
        if (doc) {
            setUser(doc.data?.updateProfile);
            if (formData.bioMetricUrl){
                if(props.isOnboardingFlow && props.handleNext) props.handleNext();
                else
                props.toggle(null,formData.bioMetricUrl);
            }
        }
    }

    const onHandleSubmit = () => {
        if(props.isOnboardingFlow && props.handleNext)  {
            props.handleNext();
        }
        else {
            props.toggle(null,null)
        }
    }
    return (
        <>
            {error && <h3 className="error"> {error} </h3>}
            <section className="userprofile-sec">
                <div className="container">
                    <div className="d-flex align-items-stretch">
                        {!props.isOnboardingFlow ? <div className="us-profile">
                            <ProfileImage photoUrl={photoUrl} className="img"
                                          text={getInitial(user.firstName, user.lastName)}/>
                            <h4>
                                {initialValues.firstName} {initialValues.lastName}
                            </h4>
                            <p>{user.designation}</p>
                            <div className="row total-list">
                                <div className="col-6">Total Meetings</div>
                                <div className="col-6">
                                    <span> {data?.getAggregatesForMe?.meetings}</span>
                                </div>
                            </div>
                            <div className="row total-list">
                                <div className="col-6">Total Deals</div>
                                <div className="col-6">
                                    <span>{data?.getAggregatesForMe?.deals}</span>
                                </div>
                            </div>
                            <div className="row total-list">
                                <div className="col-6">Total Accounts</div>
                                <div className="col-6">
                                    <span>{data?.getAggregatesForMe?.accounts}</span>
                                </div>
                            </div>
                            <div className="upgrade-bx">
                                <h5 style={{padding:10}}>You are using </h5>
                                <h4><span>{user.license}</span></h4>
                                <p className="user-text">
                                    Upgrade now to receive all the features to analyze your calls better
                                </p>
                                <Button type="button" className="btn-upgrade"
                                        onClick={(e) => {
                                            onHandleSubmit();
                                            e.preventDefault();
                                            window.location.href = "/#/settings?tab=6";
                                        }}>
                                    {user.license === "Premium" ? "Plan Details" : "Upgrade now"}
                                </Button>{" "}
                            </div>
                        </div> : <></>}
                        <div className="us-right">
                            <GeneralSettings
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                bioMetricUrl={props.bioMetricUrl}
                                toggleError={props.toggleError}
                                handleSubmit={handleSubmit}
                                handleNext ={props.handleNext}
                                isOnboardingFlow={props.isOnboardingFlow || false}
                            />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default UserProfile;
